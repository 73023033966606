import React, { useMemo, useState, useEffect, useContext, useCallback } from 'react'

import useBuyApprove from '../../hooks/multichain/txns/useBuyApprove'
import useTokenBalance from '../../hooks/multichain/useTokenBalance'
import useBuyAllowance from '../../hooks/multichain/useBuyAllowance'
import useBuyQuote from '../../hooks/multichain/useBuyQuote'
import { ThemeContext } from '../../context/ThemeProvider'
import useBuyGas from '../../hooks/multichain/useBuyGas'
import { BuyContext } from '../../context/BuyProvider'
import NetworkFee from '../NetworkFee/NetworkFee'
import ConnectButton from '../ConnectButton'
import BalanceError from '../BalanceError'
import { ETxnStatus } from '../../types'
import Input from '../Input'

const InputAndBuy = () => {
  const [btnLabel, setBtnLabel] = useState<string>('Next')
  const [isApproved, setIsApproved] = useState<boolean>(false)
  const [isAmountInGtMax, setIsAmountGtMax] = useState<boolean>(false)

  const gas = useBuyGas()
  const quote = useBuyQuote()
  const buy = useContext(BuyContext)
  const onApproveCb = useBuyApprove()
  const allowance = useBuyAllowance()
  const theme = useContext(ThemeContext)
  const balance = useTokenBalance(buy.tokenIn)

  const isLoading = useMemo(
    () =>
      quote.isFetching ||
      balance.isFetching ||
      allowance.isFetching ||
      buy.approveTxnStatus === ETxnStatus.PROCESSING ||
      buy.approveTxnStatus === ETxnStatus.TXN_SUBMITTED_ON_CHAIN,
    [gas, quote, balance, allowance, buy.approveTxnStatus],
  )

  const isDisabled = useMemo(
    () => isLoading || isAmountInGtMax || !buy.amountIn || !Number(buy.amountIn),
    [isAmountInGtMax, buy.amountIn, isLoading],
  )

  useEffect(() => {
    if (isApproved) {
      setBtnLabel('Next')
      return
    }

    if (buy.approveTxnStatus === ETxnStatus.IDLE) {
      setBtnLabel('Approve')
      return
    }

    if (buy.approveTxnStatus === ETxnStatus.PROCESSING || buy.approveTxnStatus === ETxnStatus.TXN_SUBMITTED_ON_CHAIN) {
      setBtnLabel('Approving...')
      return
    }

    if (buy.approveTxnStatus === ETxnStatus.TXN_FAILURE) {
      setBtnLabel('Try again')
      return
    }

    if (buy.approveTxnStatus === ETxnStatus.TXN_SUCCESS) {
      setBtnLabel('Next')
      buy.setScreen('confirm')
      return
    }
  }, [isApproved, buy.approveTxnStatus])

  const onApproveOrNextCb = useCallback(() => {
    if (isApproved) {
      buy.setScreen('confirm')
      return
    }
    onApproveCb()
  }, [isApproved, onApproveCb])

  return (
    <div className="w-full max-w-full flex flex-col items-start justify-start gap-6">
      <div className="w-full max-w-full flex flex-col items-start justify-start gap-2">
        <Input
          label={'SEND'}
          token={buy.tokenIn}
          amount={buy.amountIn}
          balance={balance.data}
          setScreen={buy.setScreen}
          setToken={buy.setTokenIn}
          allowance={allowance.data}
          setAmount={buy.setAmountIn}
          setIsApproved={setIsApproved}
          isAmountGtBalance={isAmountInGtMax}
          setIsAmountGtBalance={setIsAmountGtMax}
          tokens={buy?.sellTokens?.length ? buy.sellTokens || [] : []}
        />

        <Input
          isDisabled={true}
          hideBalance={true}
          balance={BigInt(0)}
          setAmount={() => {}}
          allowance={BigInt(0)}
          token={buy.tokenOut}
          amount={buy.amountOut}
          setIsApproved={() => {}}
          setScreen={buy.setScreen}
          isAmountGtBalance={false}
          label={'YOU WILL RECEIVE'}
          setToken={buy.setTokenOut}
          setIsAmountGtBalance={() => {}}
          classes="bg-transparent border-none"
          tokens={buy?.buyTokens?.length ? buy.buyTokens || [] : []}
        />
      </div>

      {isAmountInGtMax && (
        <BalanceError
          header={'Insufficient wallet balance'}
          body={`Add ${buy.tokenIn} to your wallet or use a wallet that already has ${buy.tokenOut}`}
        />
      )}

      <div className="w-full px-4 py-0 m-0 flex flex-col items-start justify-start gap-6">
        <hr className={`w-full block border-[1px] ${theme.isLightTheme ? 'border-stone-200' : 'border-stone-800'}`} />

        <div className="w-full grid grid-rows-1 grid-cols-2 gap-2">
          <NetworkFee type={'data-field'} action={isApproved ? 'swap' : 'approve'} />

          <div className="w-fit max-w-full flex gap-2 items-center justify-end justify-self-end">
            <p
              className={`font-Suisse-Intl font-[450] text-base text-right ${theme.isLightTheme ? 'text-stone-500' : 'text-stone-400'}`}
            >
              Max Slippage
            </p>
            <p
              className={`font-Suisse-Intl rounded-[4px] py-[2px] px-[6px] font-[450] text-base text-center ${theme.isLightTheme ? 'text-stone-500 bg-stone-200' : 'text-stone-400 bg-stone-900'}`}
            >
              1%
            </p>
          </div>
        </div>

        <ConnectButton
          label={btnLabel}
          isLoading={isLoading}
          useFullAvailableWidth
          isDisabled={isDisabled}
          actionIcon={'arrow-right'}
          useResponsiveSizes={false}
          actionType="primary-action"
          onClick={onApproveOrNextCb}
        />
      </div>
    </div>
  )
}

export default InputAndBuy
