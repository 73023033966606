import { useCallback, useMemo } from 'react'

import { useAccount, useChainId, useWriteContract } from 'wagmi'

import { parseUnits } from 'viem'

import { mainnet } from 'viem/chains'

import { onErrorFeedback, onSettledFeedback, onSuccessFeedback } from '../../utils'
import StakingVaultABI from '../../constants/abis/StakingVault.json'
import { rainbowKitConfig } from '../../constants/rainbowKitConfig'
import { ETxnStatus, TScreen } from '../../types'
import { DAPP_CONFIG } from '../../constants'

function useStake(
  amountIn: string,
  setBtnLabel: (label: string) => void,
  setTxnStatus: (status: ETxnStatus) => void,
  setScreen: (screen: TScreen) => void,
  setBlockExplorerTxnUrl: (url: string) => void,
): () => Promise<void> {
  const chainId = useChainId({ config: rainbowKitConfig })
  const account = useAccount({ config: rainbowKitConfig })
  const { writeContract: onWriteWithWagmi } = useWriteContract({ config: rainbowKitConfig })

  const onTxnFail = useMemo(
    () => () => {
      onErrorFeedback('Confirm', setBtnLabel, setTxnStatus)
    },
    [setBtnLabel, setTxnStatus],
  )

  const onTxnSubmit = useMemo(
    () => () => onSuccessFeedback('Confirming...', setBtnLabel, setTxnStatus),
    [setBtnLabel, setTxnStatus],
  )

  const onTxnSuccessOrRevert = useMemo(
    () => async (txnHash: unknown) =>
      onSettledFeedback(txnHash, 'Confirm', 'Confirmed', setBtnLabel, setTxnStatus, 'success', (screen: TScreen) => {
        setScreen(screen)
        setBlockExplorerTxnUrl(`${account.chain?.blockExplorers.default.url}/tx/${txnHash as string}`)
      }),
    [setScreen, setBtnLabel, setTxnStatus],
  )

  return useCallback(async () => {
    setBtnLabel('Confirming...')
    setTxnStatus(ETxnStatus.PROCESSING)

    if (chainId !== mainnet.id || !account.isConnected || !amountIn) {
      onTxnFail()
      return
    }

    onWriteWithWagmi(
      {
        chain: mainnet,
        chainId: mainnet.id,
        abi: StakingVaultABI,
        functionName: 'deposit',
        address: DAPP_CONFIG[mainnet.id].stakingVault,
        args: [parseUnits(amountIn, 18), account.address],
      },
      {
        onError: onTxnFail,
        onSuccess: onTxnSubmit,
        onSettled: onTxnSuccessOrRevert,
      },
    )
  }, [chainId, account, amountIn, onTxnSubmit, onTxnFail, onTxnSuccessOrRevert])
}

export default useStake
