/* eslint-disable react/react-in-jsx-scope */
import {
  Modal,
  ModalOverlay,
  ModalContent,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Slider,
  SliderMark,
  SliderTrack,
  SliderFilledTrack,
  Tooltip,
  SliderThumb,
} from '@chakra-ui/react'

import { useContext, useMemo, useState } from 'react'

import { ReactComponent as InfoCircleFilledIcon } from '../../assets/info/info-circle-filled.svg'
import pointsEstimateMaskGroupIcon from '../../assets/dashboard/points-estimate-mask-group.png'
import elipsis39Rotated from '../../assets/backgrounds/ellipse-39-rotated.png'
import { ThemeContext } from '../../context/ThemeProvider'

import { ReactComponent as ChevronBigDownIcon } from '../../assets/navigation/chevron-big-down.svg'
import { ReactComponent as ChevronBigUpIcon } from '../../assets/navigation/chevron-big-up.svg'
import { formatBigNumber, formatStringToNumber } from '../../utils'

interface IProps {
  points?: string
  isOpen: boolean
  onClose: () => void
}

const PointsEstimationModal = (props: IProps) => {
  const theme = useContext(ThemeContext)
  const { isOpen, onClose } = props

  const [period, setPeriod] = useState<number>(4)
  const [showTooltip, setShowTooltip] = useState(false)
  const [sliderValue, setSliderValue] = useState<number>(0.25)

  const c25 = useMemo(() => {
    return (sliderValue * 4 * 1e6) / 100
  }, [sliderValue])

  const c26 = useMemo(() => {
    const _points = Math.floor(Number(formatBigNumber(BigInt(props?.points || '0'), 18, false) || '0'))
    return (_points * 100) / 48600000000
  }, [props])

  const estimatedValue = useMemo(() => {
    return (c25 * c26 * (period + 1)) / 5
  }, [c25, c26, period])

  return (
    <Modal isOpen={isOpen} onClose={onClose} autoFocus={false}>
      <ModalOverlay />
      <ModalContent
        borderRadius={'16px'}
        background={theme.isLightTheme ? 'bg-white' : 'bg-stone-950'}
        borderColor={theme.isLightTheme ? 'border-stone-300' : 'border-stone-700 border-b-stone-800'}
        className={`relative top-36 p-6 gap-6 min-h-[329px] min-w-[60%] w-fit max-w-full rounded-2xl ${theme.isLightTheme ? 'bg-white border-stone-300' : 'bg-stone-900 border-stone-700 border-b-stone-800'} dashboard-points-estimate-modal`}
      >
        <div className="absolute left-0 ml-auto mr-auto p-0 h-[50%] w-full md:h-[329px] md:w-[735px] max-h-full max-w-full top-0 z-1 rounded-2xl">
          <img src={elipsis39Rotated} className="m-0 p-0 w-full h-full rounded-2xl" />
        </div>

        <div className="absolute p-0 h-[50%] md:h-[329px] w-full md:w-[60%] max-h-full max-w-full z-2 rounded-2xl right-0 bottom-0">
          <img src={pointsEstimateMaskGroupIcon} className="m-0 p-0 w-full h-full rounded-2xl" />
        </div>

        <div className="w-full flex flex-col-reverse md:flex-row items-stretch justify-between gap-6 justify-self-end z-[1000]">
          <div className="grow w-auto">
            <div className="w-full max-w-full flex flex-col items-start justify-between gap-6">
              <p
                className={`font-Louize font-normal text-2xl ${theme.isLightTheme ? 'text-stone-600' : 'text-stone-100'} text-wrap`}
              >
                Points Calculator: <br />
                Estimate the value of your points
              </p>

              <hr
                className={`w-[32%] max-w-[35%] border-t-[1px] ${theme.isLightTheme ? 'border-stone-200' : 'border-stone-700'}`}
              />

              <div className="w-full flex flex-col items-start justify-center gap-1 z-[1000]">
                <div className="w-fit flex items-center justify-between gap-1">
                  <p
                    className={`w-fit font-Suisse-Intl font-[450] text-base ${theme.isLightTheme ? 'text-stone-500' : 'text-stone-400'} text-nowrap`}
                  >
                    Staking period, upon claim
                  </p>
                  <Tooltip
                    hasArrow={true}
                    label={`This is the period of time you agree to stake your tokens, at the time you claim them. You'll be able to unstake at any point, but will lose the bonus if you claim early`}
                    bg={theme.isLightTheme ? 'bg-stone-50' : 'bg-black'}
                    color={theme.isLightTheme ? 'text-stone-900' : 'text-stone-50'}
                    placement="top"
                    borderRadius={'rounded-lg'}
                    paddingX={'px-3'}
                    paddingY={'py-[6px]'}
                    className={`${theme.isLightTheme ? 'bg-stone-50 text-stone-900' : 'bg-black text-stone-50'} rounded-lg py-[6px] px-3 w-fit h-fit`}
                  >
                    <div className="w-4 h-4">
                      <InfoCircleFilledIcon
                        className={`w-full h-full ${theme.isLightTheme ? 'text-stone-500' : 'text-stone-400'}`}
                      />
                    </div>
                  </Tooltip>
                </div>

                <Menu placement="bottom-start" preventOverflow>
                  {({ isOpen }) => (
                    <div className="min-w-full w-full max-w-full p-0 m-0 cursor-pointer z-[1000]">
                      <MenuButton padding={0} margin={0} className="w-full max-w-full m-0 p-0">
                        <div
                          id="points-estimation-menu-option-button"
                          className={`min-w-full w-full cursor-pointer p-4 gap-6 border-[1px] rounded-xl flex items-center justify-between ${theme.isLightTheme ? 'bg-white border-stone-300' : 'bg-stone-950 border-stone-700'} dashboard-points-estimate-modal-period-selection`}
                        >
                          <p
                            className={`font-Suisse-Intl font-[450] text-base ${theme.isLightTheme ? 'text-stone-700' : 'text-stone-700'}`}
                          >
                            {period * 3} Months
                          </p>
                          {isOpen ? (
                            <div className={`w-5 h-5 ${theme.isLightTheme ? 'text-stone-500' : 'text-stone-400'}`}>
                              <ChevronBigUpIcon className="w-full h-full" />
                            </div>
                          ) : (
                            <div className={`w-5 h-5 ${theme.isLightTheme ? 'text-stone-500' : 'text-stone-400'}`}>
                              <ChevronBigDownIcon className="w-full h-full leading-6" />
                            </div>
                          )}
                        </div>
                      </MenuButton>

                      <MenuList width={'100%'} borderWidth={0} padding={0} margin={0} borderRadius={'12px'}>
                        <div
                          className={`w-full rounded-xl border-[1px] p-2 ${theme.isLightTheme ? 'bg-white border-stone-300' : 'bg-stone-950 border-stone-800'} w-700`}
                        >
                          {Array.from({ length: 5 }).map((_, i) => (
                            <MenuItem
                              onClick={() => setPeriod(i)}
                              key={i}
                              padding={0}
                              margin={0}
                              width={'100%'}
                              background={'bg-transparent'}
                              borderWidth={0}
                            >
                              <div
                                id="points-estimation-menu-option-button-item"
                                className={`w-full cursor-pointer flex gap-2 p-2 items-center justify-start rounded-[4px] ${theme.isLightTheme ? 'hover:bg-stone-100' : 'hover:bg-stone-900'}`}
                              >
                                <p
                                  className={`grow font-Suisse-Intl font-medium text-base leading-6 ${theme.isLightTheme ? 'text-stone-600' : 'text-stone-50'} text-left`}
                                >
                                  {i * 3} Months
                                </p>
                              </div>
                            </MenuItem>
                          ))}
                        </div>
                      </MenuList>
                    </div>
                  )}
                </Menu>
              </div>

              <div className="w-full grid grid-rows-2 grid-cols-1 items-start justify-center gap-4">
                <div className="w-full grid grid-rows-1 grid-cols-2 items-center justify-between gap-4">
                  <div className="w-fit flex items-center justify-start gap-1">
                    <p
                      className={`w-fit font-Suisse-Intl font-[450] text-base ${theme.isLightTheme ? 'text-stone-500' : 'text-stone-400'} text-nowrap`}
                    >
                      $NOON FDV
                    </p>
                    <Tooltip
                      hasArrow={true}
                      label={`This is the Fully Diluted Valuation of the $NOON token`}
                      bg={theme.isLightTheme ? 'bg-stone-50' : 'bg-black'}
                      color={theme.isLightTheme ? 'text-stone-900' : 'text-stone-50'}
                      placement="top"
                      borderRadius={'rounded-lg'}
                      paddingX={'px-3'}
                      paddingY={'py-[6px]'}
                      className={`${theme.isLightTheme ? 'bg-stone-50 text-stone-900' : 'bg-black text-stone-50'} rounded-lg py-[6px] px-3 w-fit h-fit`}
                    >
                      <div className="w-4 h-4">
                        <InfoCircleFilledIcon
                          className={`w-full h-full ${theme.isLightTheme ? 'text-stone-500' : 'text-stone-400'}`}
                        />
                      </div>
                    </Tooltip>
                  </div>

                  <div className="w-fit flex items-center justify-end gap-2 justify-self-end">
                    <p
                      className={`font-Suisse-Intl font-[450] text-base ${theme.isLightTheme ? 'text-stone-600' : 'text-stone-300'}`}
                    >
                      {sliderValue} Billion USD
                    </p>
                  </div>
                </div>

                <Slider
                  min={0}
                  max={10}
                  gap={16}
                  border={2}
                  step={0.25}
                  width={'100%'}
                  id="slider-value"
                  maxWidth={'100%'}
                  position={'relative'}
                  height={'fit-content'}
                  defaultValue={sliderValue}
                  onChange={(v) => setSliderValue(v)}
                  onMouseEnter={() => setShowTooltip(true)}
                  onMouseLeave={() => setShowTooltip(false)}
                  zIndex={100}
                >
                  <SliderTrack width={'100%'} maxWidth={'100%'} bg={theme.isLightTheme ? '#D1FAE5' : '#0C0A09'}>
                    <SliderFilledTrack bg={'#6C77E9'} />
                  </SliderTrack>
                  <Tooltip
                    hasArrow={true}
                    isOpen={showTooltip}
                    label={`${sliderValue}B`}
                    bg={theme.isLightTheme ? 'bg-stone-50' : 'bg-black'}
                    color={theme.isLightTheme ? 'text-stone-900' : 'text-stone-50'}
                    placement="top"
                    borderRadius={'rounded-lg'}
                    paddingX={'px-3'}
                    paddingY={'py-[6px]'}
                    className={`${theme.isLightTheme ? 'bg-stone-50 text-stone-900' : 'bg-black text-stone-50'} rounded-lg py-[6px] px-3 w-fit h-fit`}
                  >
                    <SliderThumb />
                  </Tooltip>
                  <SliderMark value={0} mt={'8px'} textAlign="center">
                    <p
                      className={`font-Suisse-Intl font-[450] text-base ${theme.isLightTheme ? 'text-stone-500' : 'text-stone-400'}`}
                    >
                      $0B
                    </p>
                  </SliderMark>
                  <SliderMark value={2.5} mt={'8px'}>
                    <p
                      className={`font-Suisse-Intl font-[450] text-base ${theme.isLightTheme ? 'text-stone-500' : 'text-stone-400'}`}
                    >
                      $2.5B
                    </p>
                  </SliderMark>
                  <SliderMark value={5} mt={'8px'}>
                    <p
                      className={`font-Suisse-Intl font-[450] text-base ${theme.isLightTheme ? 'text-stone-500' : 'text-stone-400'}`}
                    >
                      $5B
                    </p>
                  </SliderMark>
                  <SliderMark value={7.5} mt={'8px'}>
                    <p
                      className={`hidden md:block font-Suisse-Intl font-[450] text-base ${theme.isLightTheme ? 'text-stone-500' : 'text-stone-400'}`}
                    >
                      $7.5B
                    </p>
                  </SliderMark>
                  <SliderMark value={10} mt={'8px'}>
                    <p
                      className={`absolute top-0 right-0 text-left font-Suisse-Intl font-[450] text-base ${theme.isLightTheme ? 'text-stone-500' : 'text-stone-400'}`}
                    >
                      $10B
                    </p>
                  </SliderMark>
                </Slider>
              </div>

              <p
                className={`font-Suisse-Intl font-[450] italic text-xs ${theme.isLightTheme ? 'text-stone-500' : 'text-stone-400'} text-wrap`}
              >
                {`Assumption: We have assumed that the rest of the season will see 2x daily points distributed (vs. current
                season to date). The figures here may vary significantly if this assumption turns out not to be accurace.`}
              </p>
            </div>
          </div>

          <div className="w-fit max-w-full flex flex-col items-start md:items-end justify-between gap-6">
            <div className="w-fit flex flex-col items-start md:items-end justify-center gap-3">
              <div className="w-fit flex items-center justify-between gap-1">
                <p
                  className={`w-fit font-Suisse-Intl font-[450] text-base ${theme.isLightTheme ? 'text-stone-500' : 'text-stone-400'} text-wrap text-right`}
                >
                  Estimated value of points accrued to date based on user-selected inputs{' '}
                  <Tooltip
                    hasArrow={true}
                    label={`Test`}
                    bg={theme.isLightTheme ? 'bg-stone-50' : 'bg-black'}
                    color={theme.isLightTheme ? 'text-stone-900' : 'text-stone-50'}
                    placement="top"
                    borderRadius={'rounded-lg'}
                    paddingX={'px-3'}
                    paddingY={'py-[6px]'}
                    className={`${theme.isLightTheme ? 'bg-stone-50 text-stone-900' : 'bg-black text-stone-50'} rounded-lg py-[6px] px-3 w-fit h-fit`}
                  >
                    <InfoCircleFilledIcon
                      className={`w-4 h-4 inline ${theme.isLightTheme ? 'text-stone-500' : 'text-stone-400'}`}
                    />
                  </Tooltip>
                </p>
              </div>
              <p
                className={`font-Suisse-Intl font-[450] text-5xl text-left md:text-right ${theme.isLightTheme ? 'text-stone-900' : 'text-stone-50'}`}
              >
                ${formatStringToNumber(estimatedValue?.toString() || '0', true)}
              </p>
            </div>
            <p
              className={`font-Suisse-Intl font-[450] text-[8px] text-right  ${theme.isLightTheme ? 'text-stone-500' : 'text-stone-400'}`}
            >
              {`This value is based on a series of assumptions and estimates, detailed on this website. These assumptions and estimates may change over time and may not reflect current market conditions. The value is intended for informational purposes only and should not be relied upon as accurate or definitive. It is in no way intended as financial advice and should not be relied upon for financial planning or decision-making purposes. In particular, no warranty whatsoever is given that it is or will be accurate. Any reliance you place on it is strictly at your own risk. In no event shall Noon be liable for any loss or damage, including without limitation, indirect or consequential loss or damage, or any loss or damage whatsoever arising from the use of, or reliance on, the information or estimated values provided on this website. Users must independently verify any information before relying on it.`}
            </p>
          </div>
        </div>
      </ModalContent>
    </Modal>
  )
}

export default PointsEstimationModal
