/* eslint-disable react/react-in-jsx-scope */
import RedeemRequestsCarouselContent from './RedeemRequestsCarouselContent'
import { Carousel } from '../ui/Carousel'
import { TMintReq } from '../../types'

interface IProps {
  pendingRedeemRequests: TMintReq[]
  openRedeemRequstInfoModal: (redeemRequestInfo: TMintReq) => void
  setRedeemRequestInfo: (redeemRequestInfo: TMintReq) => void
}

export const RedeemRequestsCarousel = (props: IProps) => {
  return (
    <Carousel className={'w-full max-w-full'}>
      <RedeemRequestsCarouselContent {...props} />
    </Carousel>
  )
}

export default RedeemRequestsCarousel
