import React, { useContext, useMemo } from 'react'

import { Modal, ModalContent, ModalOverlay } from '@chakra-ui/react'

import { mainnet } from 'viem/chains'

import { formatUnits } from 'viem'

import { ReactComponent as VolumeMeterChartLightIcon } from '../../assets/info/volume-meter-chart-light.svg'
import { ReactComponent as VolumeMeterChartDarkIcon } from '../../assets/info/volume-meter-chart.svg'
import { ReactComponent as MultipleCrossIcon } from '../../assets/navigation/multiple-cross.svg'
import { ReactComponent as InfoIcon } from '../../assets/info/info-circle.svg'
import { ReactComponent as USN } from '../../assets/tokens/USN.svg'
import { ThemeContext } from '../../context/ThemeProvider'
import { TMintReq, TScreen, TUsnTab } from '../../types'
import { formatStringToNumber } from '../../utils'
import { DAPP_CONFIG } from '../../constants'

interface IProps {
  isOpen: boolean
  onClose: () => void
  setTab: (tab: TUsnTab) => void
  setScreen: (tab: TScreen) => void
  mintRequestInfo: TMintReq | undefined
}

const MintRequestInfoModal = (props: IProps) => {
  const theme = useContext(ThemeContext)

  const formattedUSNAmount = useMemo(
    () => formatStringToNumber(formatUnits(BigInt(props.mintRequestInfo?.order?.usnAmount || '0'), 18), true),
    [props.mintRequestInfo],
  )

  const formattedCurrencyAmount = useMemo(
    () => formatStringToNumber(formatUnits(BigInt(props.mintRequestInfo?.order?.collateralAmount || '0'), 6), true),
    [props.mintRequestInfo],
  )

  const currencySymbol = useMemo(
    () =>
      DAPP_CONFIG[mainnet.id].tokens[
        DAPP_CONFIG[mainnet.id].tokensForMint.find(
          (collateral) =>
            DAPP_CONFIG[mainnet.id].tokens[collateral].contractAddress ===
            props.mintRequestInfo?.order?.collateralAddress,
        ) || 'USDC'
      ].symbol,
    [props.mintRequestInfo],
  )

  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose} autoFocus={false}>
      <ModalOverlay />
      <ModalContent
        width={'40%'}
        minWidth={'35%'}
        maxWidth={'45%%'}
        borderWidth={'1px'}
        borderRadius={'12px'}
        background={theme.isLightTheme ? '#FFFFFF' : '#000000'}
        borderColor={theme.isLightTheme ? '#D6D3D1' : '#312D2C'}
        className={`relative top-4 w-full max-w-full flex flex-col rounded-2xl p-6 gap-6 border-[1px] items-center justify-start overflow-hidden ${theme.isLightTheme ? 'bg-white border-stone-300' : 'bg-black border-stone-800'} usn-page-main-container-shadow`}
      >
        <div
          onClick={props.onClose}
          className={`absolute h-5 w-5 cursor-pointer ${theme.isLightTheme ? 'text-stone-400' : 'text-stone-600'} right-5 top-5 outline-none`}
        >
          <MultipleCrossIcon className="w-full h-full" />
        </div>

        {theme.isLightTheme ? <VolumeMeterChartLightIcon /> : <VolumeMeterChartDarkIcon />}

        <p
          className={`w-full max-w-full font-Louize font-normal text-4xl ${theme.isLightTheme ? 'text-stone-900' : 'text-stone-200'} text-center text-wrap break-words`}
        >
          Your request to mint{' '}
          <img src={`${process.env.PUBLIC_URL}/assets/tokens/${'USN'}.svg`} className="w-10 h-10 inline" alt="USN" />{' '}
          {formattedUSNAmount} USN is currently in the queue
        </p>

        <div className={`w-full rounded-xl p-2 ${theme.isLightTheme ? 'bg-stone-100' : 'bg-stone-900'}`}>
          <div className="w-full h-full flex flex-col md:flex-row items-center justify-between gap-2 md:items-stretch">
            <div
              className={`grow w-full md:max-w-[49%] rounded-md ${theme.isLightTheme ? 'bg-white' : 'bg-stone-800'} gap-2 py-5 px-[51px] flex flex-col items-center justify-center flex-wrap`}
            >
              <p className="font-Suisse-Intl font-[450] text-base text-stone-500 text-wrap text-center">Minting</p>
              <div className="w-full flex items-center justify-center rounded gap-1 flex-wrap">
                <div className="w-6 h-6 shrink-0 max-w-[48%]">
                  <USN className="w-full h-full" />
                </div>
                <p
                  className={`shrink font-Suisse-Intl font-semibold text-2xl ${theme.isLightTheme ? 'text-stone-900' : 'text-stone-100'} text-wrap text-center max-w-full`}
                >
                  {formattedUSNAmount} USN
                </p>
              </div>
            </div>

            <div
              className={`grow w-full md:max-w-[49%] rounded-md ${theme.isLightTheme ? 'bg-white' : 'bg-stone-800'} gap-2 py-5 px-[51px] flex flex-col items-center justify-center flex-wrap`}
            >
              <p className="font-Suisse-Intl font-[450] text-base text-stone-500 text-wrap text-center">Using</p>
              <div className="w-full flex items-center justify-center rounded gap-1 flex-wrap">
                <div className="w-6 h-6 shrink-0">
                  <img
                    alt={currencySymbol}
                    className="w-full h-full"
                    src={`${process.env.PUBLIC_URL}/assets/tokens/${currencySymbol}.svg`}
                  />
                </div>
                <p
                  className={`shrink font-Suisse-Intl font-semibold text-2xl ${theme.isLightTheme ? 'text-stone-900' : 'text-stone-100'} text-wrap text-center max-w-full`}
                >
                  {formattedCurrencyAmount} {currencySymbol}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div
          className={`w-full flex items-start justify-between rounded-[10px] p-3 gap-2 ${theme.isLightTheme ? 'bg-stone-100' : 'bg-stone-900'}`}
        >
          <div className={`w-6 h-6 shrink-0 ${theme.isLightTheme ? 'text-stone-900' : 'text-stone-200'}`}>
            <InfoIcon className="w-full h-full" />
          </div>
          <div className="flex flex-col items-start justify-center gap-[2px] shrink">
            <p
              className={`font-Suisse-Intl font-[450] text-base ${theme.isLightTheme ? 'text-stone-900' : 'text-stone-200'} text-wrap`}
            >
              Your USN is in the minting queue
            </p>
            <p className="font-Suisse-Intl font-[450] text-sm text-stone-500 text-wrap text-left">
              We&apos;re currently reviewing your minting request, including conducting AML / KYC checks, etc. Once our
              review is completed successfully, your USN will be minted and deposited into your wallet. This process
              should be completed within 24 hours.
            </p>
          </div>
        </div>

        <div className="w-full flex flex-col md:flex-row gap-4 items-center justify-center">
          <button
            onClick={() => {
              props.setTab('mint')
              props.setScreen('input')
              props.onClose()
            }}
            className="grow w-full md:w-auto rounded-[184px] py-3 px-[18px] gap-[6px] bg-indigo-600 font-Suisse-Intl font-medium text-base text-stone-50"
          >
            Mint More USN
          </button>
        </div>
      </ModalContent>
    </Modal>
  )
}

export default MintRequestInfoModal
