import { mainnet } from '@wagmi/core/chains'

import { useAccount, useChainId, useReadContract } from 'wagmi'

import MintHandlerAbi from '../../constants/abis/MintHandler.json'

import { rainbowKitConfig } from '../../constants/rainbowKitConfig'
import { DAPP_CONFIG } from '../../constants'

const useIsWhitelisted = () => {
  const chainId = useChainId({ config: rainbowKitConfig })
  const account = useAccount({ config: rainbowKitConfig })

  return useReadContract({
    abi: MintHandlerAbi,
    chainId: mainnet.id,
    config: rainbowKitConfig,
    args: [account.address!],
    functionName: 'whitelistedUsers',
    address: DAPP_CONFIG[mainnet.id].mintHandler,
    query: {
      refetchOnMount: true,
      initialData: () => false,
      refetchOnReconnect: true,
      refetchOnWindowFocus: true,
      placeholderData: () => false,
      refetchIntervalInBackground: false,
      refetchInterval: DAPP_CONFIG[mainnet.id].dataRefershInterval,
      select: (data) => {
        return (data || false) as boolean
      },
      enabled: chainId === mainnet.id && account.isConnected && !!DAPP_CONFIG[mainnet.id].mintHandler,
    },
  })
}

export default useIsWhitelisted
