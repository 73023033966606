import React, { useContext } from 'react'

import { ThemeContext } from '../../context/ThemeProvider'

const WhitelistStatusTooltipLabel = () => {
  const theme = useContext(ThemeContext)

  return (
    <>
      <p
        className={`w-fit max-w-full font-Suisse-Intl font-[450] text-base text-wrap ${theme.isLightTheme ? 'text-stone-500' : 'text-stone-400'} mb-2`}
      >
        This wallet has not been whitelisted yet!
      </p>
      <p
        className={`outline-none font-Suisse-Intl font-[450] text-base ${theme.isLightTheme ? 'text-stone-950' : 'text-stone-50'} mb-2`}
      >
        Contact us at <u>whitelist@noon.capital</u> to request to add your wallet to the whitelist.
      </p>
      <p
        className={`w-fit max-w-full font-Suisse-Intl font-[450] text-sm text-wrap ${theme.isLightTheme ? 'text-stone-500' : 'text-stone-400'}`}
      >
        If your wallet have recently been whitelisted, please allow up to an hour for the changes to be reflected.
      </p>
    </>
  )
}

export default WhitelistStatusTooltipLabel
