import React, { useState } from 'react'

import { mainnet } from 'viem/chains'

import { useDisclosure } from '@chakra-ui/react'

import { InputAndApproveRedeem, RedeemConfirm, RedeemRequestInfoModal, RedeemSuccessful } from '../../components/Redeem'
import RedeemRequestsCarousel from '../../components/Redeem/RedeemRequestsCarousel'
import { TMintReq, TUsnScreen, TUsnTab } from '../../types'
import ActionCard from '../../components/ActionCard'
import { DAPP_CONFIG } from '../../constants'
import './index.css'

interface IProps {
  screen: TUsnScreen
  setTab: (tab: TUsnTab) => void
  pendingRedeemRequests: TMintReq[]
  setScreen: (screen: TUsnScreen) => void
}

const Redeem = (props: IProps) => {
  const { screen, setScreen, setTab } = props

  const [usnAmount, setUsnAmount] = useState<string>('')
  const [redeemRequestInfo, setRedeemRequestInfo] = useState<TMintReq>()
  const [selectedCollateral, setSelectedCollateral] = useState<string>(DAPP_CONFIG[mainnet.id].tokensForMint[0])

  const { isOpen, onOpen, onClose } = useDisclosure()

  const openModal = (_mintRequestInfo: TMintReq) => {
    setRedeemRequestInfo(_mintRequestInfo)
    onOpen()
  }

  return (
    <div className="w-full max-w-full flex flex-col items-start justify-start gap-4">
      <RedeemRequestInfoModal
        isOpen={isOpen}
        setTab={setTab}
        onClose={onClose}
        setScreen={setScreen}
        redeemRequestInfo={redeemRequestInfo || ({} as TMintReq)}
      />

      <ActionCard screen={screen} setScreen={props.setScreen}>
        {screen === 'input' ? (
          <InputAndApproveRedeem
            setScreen={setScreen}
            usnAmount={usnAmount}
            selectedCollateral={selectedCollateral}
            selectCollateral={setSelectedCollateral}
            setUsnAmount={setUsnAmount}
          />
        ) : screen === 'confirm' ? (
          <RedeemConfirm setScreen={setScreen} usnAmount={usnAmount} selectedCollateral={selectedCollateral} />
        ) : (
          <RedeemSuccessful setScreen={setScreen} usnAmount={usnAmount} />
        )}
      </ActionCard>

      <RedeemRequestsCarousel
        openRedeemRequstInfoModal={openModal}
        setRedeemRequestInfo={setRedeemRequestInfo}
        pendingRedeemRequests={props.pendingRedeemRequests?.length ? props.pendingRedeemRequests : []}
      />
    </div>
  )
}

export default Redeem
