import React, { useContext, useEffect, useState } from 'react'
import { Tooltip } from '@chakra-ui/react'

import { useAccount } from 'wagmi'

import { mainnet } from 'viem/chains'

import WrongNetworkToolTip from '../../components/WrongNetworkToolTip/WrongNetworkToolTip'
import ProtocolInfo from '../../components/ProtocolInfo/ProtocolInfo'
import usePendingRequests from '../../hooks/read/usePendingRequests'
import WhitelistStatusTooltipLabel from '../../components/Whitelist'
import { rainbowKitConfig } from '../../constants/rainbowKitConfig'
import useIsWhitelisted from '../../hooks/read/useIsWhitelisted'
import { ThemeContext } from '../../context/ThemeProvider'
import { TMintScreen, TUsnTab } from '../../types'
import Redeem from '../Redeem'
import Mint from '../Mint'
import Buy from '../Buy'

const Usn = () => {
  const [tab, setTab] = useState<TUsnTab>('buy')
  const [screen, setScreen] = useState<TMintScreen>('input')

  const theme = useContext(ThemeContext)
  const isWhitelisted = useIsWhitelisted()
  const pendingRequests = usePendingRequests()
  const account = useAccount({ config: rainbowKitConfig })

  useEffect(() => {
    if (account?.chainId !== mainnet.id || !(isWhitelisted.data || false)) {
      setTab('buy')
      setScreen('input')
    }
  }, [account, isWhitelisted.data])

  return (
    <div className="h-fit w-full max-w-full flex flex-col md:grid md:grid-cols-2 gap-6 py-10 px-4 md:px-20 md:py-32 items-center justify-start md:items-start md:justify-between md:justify-items-center">
      <ProtocolInfo page="USN" />

      <div className={`w-full md:w-[90%] max-w-full flex flex-col items-start justify-center gap-2`}>
        {screen === 'input' && (
          <div className={`w-full max-w-full flex items-center justify-between gap-1`}>
            <button
              onClick={() => setTab('buy')}
              className={`w-fit rounded-[10px] py-2 px-4 ${theme.isLightTheme ? (tab === 'buy' ? 'bg-white' : 'bg-stone-200') : tab === 'buy' ? 'bg-stone-800' : 'bg-stone-900'} ${tab === 'buy' ? 'buy-tab-design' : ''} font-Suisse-Intl font-medium text-base ${theme.isLightTheme ? (tab === 'buy' ? 'text-stone-900' : 'text-unselected-tab-text') : tab === 'buy' ? 'text-stone-50' : 'text-stone-500'} ${tab === 'buy' ? 'usn-page-selected-tab-shadow' : ''}`}
            >
              Buy
            </button>
            <Tooltip
              padding={'12px'}
              color={'transparent'}
              borderRadius={'12px'}
              placement="top-start"
              bg={theme.isLightTheme ? '#FFFFFF' : '#000000'}
              borderColor={theme.isLightTheme ? '#E7E5E4' : '#312D2C'}
              isDisabled={
                !account ||
                !account?.address ||
                !account?.isConnected ||
                (!!(isWhitelisted.data || false) && account?.chainId === mainnet.id)
              }
              label={account?.chainId !== mainnet.id ? <WrongNetworkToolTip /> : <WhitelistStatusTooltipLabel />}
              className={`rounded-xl border-[1px] p-3 gap-2 ${theme.isLightTheme ? 'bg-white border-stone-200' : 'bg-black border-stone-800'} usn-page-whitelist-status-tooltip-shadow`}
            >
              <div
                className={`w-fit flex gap-[2px] p-[2px] rounded-xl ${theme.isLightTheme ? 'bg-stone-200' : 'bg-stone-900'} items-center justify-between`}
              >
                <button
                  onClick={() => setTab('mint')}
                  disabled={!(isWhitelisted.data || false) || account?.chainId !== mainnet.id}
                  className={`w-fit rounded-[10px] py-2 px-4 ${theme.isLightTheme ? (tab === 'mint' ? 'bg-white' : 'bg-stone-200') : tab === 'mint' ? 'bg-stone-800' : 'bg-stone-900'} ${tab === 'mint' ? 'usn-page-selected-tab-shadow' : ''} font-Suisse-Intl font-medium text-base ${theme.isLightTheme ? (tab === 'mint' ? 'text-stone-900' : 'text-unselected-tab-text') : tab === 'mint' ? 'text-stone-50' : 'text-stone-500'}`}
                >
                  Mint
                </button>
                <button
                  onClick={() => setTab('redeem')}
                  disabled={!(isWhitelisted.data || false) || account?.chainId !== mainnet.id}
                  className={`w-fit rounded-[10px] py-2 px-4 ${theme.isLightTheme ? (tab === 'redeem' ? 'bg-white' : 'bg-stone-200') : tab === 'redeem' ? 'bg-stone-800' : 'bg-stone-900'} ${tab === 'redeem' ? 'usn-page-selected-tab-shadow' : ''} font-Suisse-Intl font-medium text-base ${theme.isLightTheme ? (tab === 'redeem' ? 'text-stone-900' : 'text-unselected-tab-text') : tab === 'redeem' ? 'text-stone-50' : 'text-stone-500'}`}
                >
                  Redeem
                </button>
              </div>
            </Tooltip>
          </div>
        )}

        {tab === 'buy' ? (
          <Buy />
        ) : tab === 'mint' ? (
          <Mint
            screen={screen}
            setScreen={setScreen}
            setTab={setTab}
            pendingMintRequests={
              pendingRequests.data?.pendingMinting?.length ? pendingRequests.data?.pendingMinting : []
            }
          />
        ) : (
          <Redeem
            screen={screen}
            setScreen={setScreen}
            setTab={setTab}
            pendingRedeemRequests={
              pendingRequests.data?.pendingRedeeming?.length ? pendingRequests.data?.pendingRedeeming : []
            }
          />
        )}
      </div>
    </div>
  )
}

export default Usn
