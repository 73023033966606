import React, { useContext, useMemo, useState } from 'react'

import { ThemeContext } from '../../context/ThemeProvider'
import ActionCardBackButton from '../ActionCardBackButton'
import ActionInAndOutInfo from '../ActionInAndOutInfo'
import useUnstake from '../../hooks/write/useUnstake'
import { ETxnStatus, TMintScreen } from '../../types'
import { formatStringToNumber } from '../../utils'
import ConnectButton from '../ConnectButton'
import ActionStats from '../ActionStats'

interface IProps {
  usnAmount: string
  sUsnAmount: string
  setBlockExplorerTxnUrl: (url: string) => void
  setScreen: (screen: TMintScreen) => void
}

const UnStakeConfirm = (props: IProps) => {
  const [btnLabel, setBtnLabel] = useState<string>('Confirm')
  const [txnStatus, setTxnStatus] = useState<ETxnStatus>(ETxnStatus.IDLE)

  const theme = useContext(ThemeContext)

  const isDisabled = useMemo(() => {
    return !props.usnAmount || !props.sUsnAmount || !Number(props.usnAmount) || !Number(props.sUsnAmount)
  }, [props])

  const isLoading = useMemo(
    () => txnStatus === ETxnStatus.PROCESSING || txnStatus === ETxnStatus.TXN_SUBMITTED_ON_CHAIN,
    [txnStatus],
  )

  const formattedsUsnAmount = useMemo(
    () => formatStringToNumber(props.sUsnAmount || '', true),
    [props, formatStringToNumber],
  )
  const formattedUsnAmount = useMemo(
    () => formatStringToNumber(props.usnAmount || '', true),
    [props, formatStringToNumber],
  )

  const onUnstakesUsnCb = useUnstake(
    props.sUsnAmount,
    setBtnLabel,
    setTxnStatus,
    props.setScreen,
    props.setBlockExplorerTxnUrl,
  )

  return (
    <React.Fragment>
      <ActionCardBackButton setScreen={props.setScreen} />

      <p
        className={`font-Louize font-normal text-4xl tracking-tighter ${theme.isLightTheme ? 'text-stone-900' : 'text-stone-50'}`}
      >
        Confirm your unstaking request
      </p>

      <ActionInAndOutInfo
        tokenIn="sUSN"
        tokenOut="USN"
        labelIn="Unstaking"
        labelOut="To get back"
        amountIn={formattedsUsnAmount}
        amountOut={formattedUsnAmount}
      />
      <ActionStats page="sUSN" quoteToken={'USDC'} networkFeeAction={'unstake'} />
      <ConnectButton
        label={btnLabel}
        isLoading={isLoading}
        useFullAvailableWidth
        isDisabled={isDisabled}
        onClick={onUnstakesUsnCb}
        useResponsiveSizes={false}
        actionType="primary-action"
        actionIcon={'success-tick'}
      />
    </React.Fragment>
  )
}

export default UnStakeConfirm
