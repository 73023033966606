import React, { useContext, useMemo, useState } from 'react'
import { useChainId } from 'wagmi'

import { mainnet } from 'viem/chains'

import { ReactComponent as ChevronBigDownIcon } from '../../assets/navigation/chevron-big-down.svg'
import { ReactComponent as ChevronBigUpIcon } from '../../assets/navigation/chevron-big-up.svg'
import { rainbowKitConfig } from '../../constants/rainbowKitConfig'
import { ThemeContext } from '../../context/ThemeProvider'
import { DAPP_CONFIG } from '../../constants'

import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '../ui/DropdownMenu'

interface IProps {
  token: string
  tokens: string[]
  chainIcon?: number
  setToken: (token: string) => void
}

const InputCurrencySelector = (props: IProps) => {
  const [isOpen, setIsOpen] = useState(false)

  const onToggleOpen = (open: boolean) => setIsOpen(open)

  const theme = useContext(ThemeContext)
  const chainId = useChainId({ config: rainbowKitConfig })

  const token = useMemo(() => {
    const config = DAPP_CONFIG[chainId || mainnet.id]
    if (!config) {
      return DAPP_CONFIG[mainnet.id].tokens['USN']
    }
    const _token = config.tokens[props.token]
    if (!_token) return config.tokens['USN']
    return _token
  }, [chainId, props.token])

  return (
    <div className="w-fit max-w-full">
      <DropdownMenu open={isOpen} onOpenChange={onToggleOpen} defaultOpen={false}>
        <DropdownMenuTrigger
          className={`w-full max-w-full flex outline-none items-center justify-between justify-self-end gap-1 rounded-[56px] border-[1px] py-[6px] px-[8px] ${theme.isLightTheme ? 'bg-white border-stone-300' : 'bg-stone-950 border-stone-800'} token-selector-dropdown-button-shadow`}
        >
          <div className="relative w-6 h-6">
            <img
              className="w-full h-full"
              alt={token.symbol}
              src={`${process.env.PUBLIC_URL}/assets/tokens/${token.symbol}.svg`}
              onError={() => import(`../../assets/tokens/${token.assetsIconName}`)}
            />
            {props.chainIcon && (
              <div
                className={`absolute h-3 w-3 ${theme.isLightTheme ? 'border-[1px] border-white' : ''} rounded-[100px] bottom-0 right-0`}
              >
                <img
                  className="w-full h-full"
                  alt={props.chainIcon.toString()}
                  src={`${process.env.PUBLIC_URL}/assets/chains/${props.chainIcon}.svg`}
                />
              </div>
            )}
          </div>
          <p
            className={`font-Suisse-Intl font-medium text-base ${theme.isLightTheme ? 'text-stone-700' : 'text-stone-100'}`}
          >
            {token.name}
          </p>
          {isOpen ? (
            <div className={`w-5 h-5 ${theme.isLightTheme ? 'text-stone-500' : 'text-stone-400'}`}>
              <ChevronBigUpIcon className="w-full h-full" />
            </div>
          ) : (
            <div className={`w-5 h-5 ${theme.isLightTheme ? 'text-stone-500' : 'text-stone-400'} cursor-pointer`}>
              <ChevronBigDownIcon className="w-full h-full" />
            </div>
          )}
        </DropdownMenuTrigger>
        <DropdownMenuContent
          className={`w-full max-w-full rounded-xl border-[1px] p-1 ${theme.isLightTheme ? 'bg-white border-stone-200' : 'bg-black border-stone-800'} token-selector-dropdown-container-shadow`}
        >
          {(props.tokens.length ? props.tokens : Object.keys(DAPP_CONFIG[chainId || mainnet.id].tokens)).map(
            (collateral: string) => (
              <DropdownMenuItem
                onClick={() => props.setToken(collateral)}
                key={DAPP_CONFIG[chainId || mainnet.id].tokens[collateral].contractAddress}
                className={`w-full max-w-full rounded-lg flex gap-2 p-2 items-center justify-start bg-transparent ${theme.isLightTheme ? 'text-stone-400 hover:text-stone-900 hover:bg-stone-100' : 'text-stone-500 hover:text-stone-50 hover:bg-stone-900'} cursor-pointer`}
              >
                <div className="relative w-6 h-6">
                  <img
                    className="w-full h-full"
                    alt={DAPP_CONFIG[chainId || mainnet.id].tokens[collateral].symbol}
                    src={`${process.env.PUBLIC_URL}/assets/tokens/${DAPP_CONFIG[chainId || mainnet.id].tokens[collateral].symbol}.svg`}
                    onError={() =>
                      import(`../../assets/${DAPP_CONFIG[chainId || mainnet.id].tokens[collateral]}.assetsIconName}`)
                    }
                  />
                  {/* {props.chainIcon && (
                    <div
                      className={`absolute h-3 w-3 ${theme.isLightTheme ? 'border-[1px] border-white' : ''} rounded-[100px] -bottom-2 -right-1`}
                    >
                      <img
                        alt={props.chainIcon}
                        className="w-full h-full"
                        src={`${process.env.PUBLIC_URL}/assets/chains/${props.chainIcon}.svg`}
                      />
                    </div>
                  )} */}
                </div>
                <p className={`grow font-Suisse-Intl font-medium text-base text-left`}>
                  {DAPP_CONFIG[chainId || mainnet.id].tokens[collateral].name}
                </p>
              </DropdownMenuItem>
            ),
          )}
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  )
}

export default InputCurrencySelector
