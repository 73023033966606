import React, { useMemo, useState, useEffect, useCallback } from 'react'

import { mainnet } from 'viem/chains'

import useTokenAllowance from '../../hooks/read/useTokenAllowance'
import useTokenBalance from '../../hooks/read/useTokenBalance'
import ReceiveInfoAndStats from '../ReceiveInfoAndStats'
import useApprove from '../../hooks/write/useApprove'
import { ETxnStatus, TMintScreen } from '../../types'
import { formatStringToNumber } from '../../utils'
import { DAPP_CONFIG } from '../../constants'
import ConnectButton from '../ConnectButton'
import BalanceError from '../BalanceError/'
import Input from '../Input'

interface IProps {
  collateralAmount: string
  selectedCollateral: string
  setScreen: (screen: TMintScreen) => void
  setCollateralAmount: (value: string) => void
  selectCollateral: (colateral: string) => void
}

const InputAndApproveMint = (props: IProps) => {
  const [btnLabel, setBtnLabel] = useState<string>('Approve')
  const [isApproved, setIsApproved] = useState<boolean>(false)
  const [isAmountInGtMax, setIsAmountGtMax] = useState<boolean>(false)
  const [txnStatus, setTxnStatus] = useState<ETxnStatus>(ETxnStatus.IDLE)

  const collateralBalance = useTokenBalance(props.selectedCollateral)
  const collateralAllowance = useTokenAllowance(props.selectedCollateral, DAPP_CONFIG[mainnet.id].mintHandler)

  const formattedUsnAmount = useMemo(() => {
    return formatStringToNumber(props.collateralAmount, true)
  }, [props.collateralAmount])

  const isLoading = useMemo(
    () =>
      collateralBalance.isFetching ||
      collateralAllowance.isFetching ||
      txnStatus === ETxnStatus.PROCESSING ||
      txnStatus === ETxnStatus.TXN_SUBMITTED_ON_CHAIN,
    [collateralBalance, collateralAllowance, txnStatus],
  )

  const isDisabled = useMemo(
    () => isLoading || isAmountInGtMax || !props.collateralAmount || !Number(props.collateralAmount),
    [props, isLoading, isAmountInGtMax],
  )

  const onApproveCb = useApprove(
    props.selectedCollateral,
    DAPP_CONFIG[mainnet.id].mintHandler,
    setBtnLabel,
    setTxnStatus,
    props.setScreen,
  )

  const onClickCb = useCallback(() => {
    if (isApproved) {
      props.setScreen('confirm')
      return
    }
    onApproveCb()
  }, [isApproved, onApproveCb, props.setScreen])

  useEffect(() => {
    if (isApproved) {
      setBtnLabel('Next')
      return
    }
    setBtnLabel('Approve')
  }, [isApproved])

  return (
    <React.Fragment>
      <Input
        label={'MINT AMOUNT'}
        setScreen={props.setScreen}
        setIsApproved={setIsApproved}
        amount={props.collateralAmount}
        token={props.selectedCollateral}
        setToken={props.selectCollateral}
        isAmountGtBalance={isAmountInGtMax}
        setAmount={props.setCollateralAmount}
        setIsAmountGtBalance={setIsAmountGtMax}
        balance={collateralBalance.data || BigInt(0)}
        tokens={DAPP_CONFIG[mainnet.id].tokensForMint}
        allowance={collateralAllowance.data || BigInt(0)}
      />

      {isAmountInGtMax && (
        <BalanceError
          header={'Insufficient wallet balance'}
          body={`Add ${props.selectedCollateral} to your wallet or use a wallet that already has ${props.selectedCollateral}`}
        />
      )}

      <ReceiveInfoAndStats
        page="USN"
        tokenOut={'USN'}
        quoteToken={props.selectedCollateral}
        amountOut={formattedUsnAmount}
        networkFeeAction="mint"
      >
        <ConnectButton
          label={btnLabel}
          onClick={onClickCb}
          isLoading={isLoading}
          useFullAvailableWidth
          isDisabled={isDisabled}
          actionIcon="arrow-right"
          useResponsiveSizes={false}
          actionType="primary-action"
        />
      </ReceiveInfoAndStats>
    </React.Fragment>
  )
}

export default InputAndApproveMint
