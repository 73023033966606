import { mainnet } from '@wagmi/core/chains'

import { useAccount, useChainId, useReadContract } from 'wagmi'

import WithdrawalHandlerABI from '../../constants/abis/WithdrawalHandler.json'
import { rainbowKitConfig } from '../../constants/rainbowKitConfig'
import { DAPP_CONFIG } from '../../constants'

const useLastWithdrawReqId = () => {
  const chainId = useChainId({ config: rainbowKitConfig })
  const account = useAccount({ config: rainbowKitConfig })

  return useReadContract({
    chainId: mainnet.id,
    args: [account.address],
    config: rainbowKitConfig,
    abi: WithdrawalHandlerABI,
    functionName: 'nextRequestId',
    address: DAPP_CONFIG[mainnet.id].stakeWithdrawHandler,
    query: {
      refetchOnMount: true,
      initialData: () => -1,
      refetchOnReconnect: true,
      placeholderData: () => -1,
      refetchOnWindowFocus: true,
      refetchIntervalInBackground: true,
      enabled: chainId === mainnet.id && account.isConnected,
      refetchInterval: DAPP_CONFIG[mainnet.id].dataRefershInterval,
      select: (data) => {
        const nextReqId = (data || BigInt(0)) as bigint
        return nextReqId <= BigInt(0) ? -1 : Number(nextReqId.toString())
      },
    },
  })
}

export default useLastWithdrawReqId
