import { gql, GraphQLClient } from 'graphql-request'

import { useAccount } from 'wagmi'

import { formatUnits } from 'viem'

import { useQuery } from '@tanstack/react-query'

import { rainbowKitConfig } from '../constants/rainbowKitConfig'

export type ChainUsnHoldings = { [chainLabel: string]: string }
export type UsnHoldings = { usnBalance: string }

const querySubgraph = async (client: GraphQLClient, queryDocument: string) => {
  return await client
    .request(queryDocument)
    .then(
      (data: unknown) => {
        const wallet = data as { wallet: UsnHoldings }
        return wallet?.wallet as UsnHoldings
      },
      () => {
        throw Error('Failed to fetch usn balance')
      },
    )
    .then(
      (data: UsnHoldings) => {
        const balance = data?.usnBalance || '0'
        return formatUnits(BigInt(balance || '0'), 18)
      },
      () => {
        throw Error('Failed to fetch usn balance')
      },
    )
    .catch(() => {
      return '0'
    })
}

const useUsnHoldings = () => {
  const account = useAccount({ config: rainbowKitConfig })

  const fetcher = async () => {
    if (!account.isConnected || !account?.address) {
      return { Ethereum: '0', Sophon: '0' } as ChainUsnHoldings
    }

    const queryDocument = gql`
      {
        wallet(id: "${account.address.toLowerCase()}") {
          usnBalance
        }
      }
    `

    const zksyncClient = new GraphQLClient(process.env.REACT_APP_GOLDSKY_USN_ZKSYNC_URL || '')
    const sophonClient = new GraphQLClient(process.env.REACT_APP_GOLDSKY_USN_SOPHON_URL || '')
    const mainnetClient = new GraphQLClient(process.env.REACT_APP_GOLDSKY_USN_MAINNET_URL || '')
    const [sophon, mainnet, zksync] = await Promise.all([
      querySubgraph(sophonClient, queryDocument),
      querySubgraph(mainnetClient, queryDocument),
      querySubgraph(zksyncClient, queryDocument),
    ])

    return {
      Sophon: sophon,
      Ethereum: mainnet,
      Zksync: zksync,
    }
  }

  return useQuery({
    queryFn: fetcher,
    refetchOnMount: true,
    refetchOnReconnect: false,
    refetchOnWindowFocus: true,
    refetchIntervalInBackground: false,
    enabled: account.isConnected && !!account?.address,
    queryKey: ['usn', 'holdings', account?.address, account.isConnected],
    placeholderData: { Ethereum: '0', Sophon: '0', Zksync: '0' } as ChainUsnHoldings,
    initialData: () => ({ Ethereum: '0', Sophon: '0', Zksync: '0' }) as ChainUsnHoldings,
    select: (data) => {
      return {
        Ethereum: data?.Ethereum || '0',
        Sophon: data?.Sophon || '0',
        Zksync: data?.Zksync || '0',
      } as ChainUsnHoldings
    },
  })
}

export default useUsnHoldings
