/* eslint-disable react/react-in-jsx-scope */
import { useContext, useMemo } from 'react'
import { NavLink } from 'react-router-dom'

import { ReactComponent as ArrowRightIcon } from '../../assets/navigation/arrow-right.svg'
import { ReactComponent as CheckIcon } from '../../assets/success/check-tick.svg'
import { ReactComponent as InfoIcon } from '../../assets/info/info-circle.svg'
import redeemSuccessIconGroup from '../../assets/success/success-group.svg'
import { ThemeContext } from '../../context/ThemeProvider'
import { formatStringToNumber } from '../../utils'
import USN from '../../assets/tokens/USN.svg'
import { TRedeemScreen } from '../../types'

interface IProps {
  usnAmount: string
  setScreen: (screen: TRedeemScreen) => void
}

const RedeemSuccessful = (props: IProps) => {
  const theme = useContext(ThemeContext)

  const formattedUSNInputAmount = useMemo(
    () => formatStringToNumber(props.usnAmount || '0', true),
    [props.usnAmount, formatStringToNumber],
  )

  return (
    <div className="w-full max-w-full m-0 py-[14px] px-[10px] md:p-4 flex flex-col items-start justify-start gap-6 md:gap-4">
      <div
        className={`w-[72px] h-[72px] rounded-[64px] ${theme.isLightTheme ? 'bg-green-200 text-green-800' : 'bg-green-1000 text-green-200'} p-4`}
      >
        <CheckIcon className="w-full h-full" />
      </div>

      <p
        className={`font-Louize font-normal text-4xl tracking-tighter ${theme.isLightTheme ? 'text-stone-900' : 'text-stone-50'}`}
      >
        <span>You have successfully requested to redeem</span>&nbsp;
        <img src={USN} className="w-10 h-10 inline" alt="USN" />
        &nbsp;
        <span>{formattedUSNInputAmount} USN</span>
      </p>

      <div
        className={`w-full flex items-start justify-between rounded-[10px] p-3 gap-2 ${theme.isLightTheme ? 'bg-stone-100' : 'bg-stone-900'}`}
      >
        <div className={`w-6 h-6 shrink-0 ${theme.isLightTheme ? 'text-stone-900' : 'text-stone-200'}`}>
          <InfoIcon className="w-full h-full" />
        </div>
        <div className="flex flex-col items-start justify-center gap-[2px] shrink">
          <p
            className={`font-Suisse-Intl font-[450] text-base ${theme.isLightTheme ? 'text-stone-900' : 'text-stone-200'} text-wrap`}
          >
            Your USN is in the redeeming queue
          </p>
          <p className="font-Suisse-Intl font-[450] text-sm text-stone-500 text-wrap text-left">
            We&apos;re currently reviewing your redeeming request, including conducting AML / KYC checks, etc. Once our
            review is completed successfully, your USN will be redeemed. This process should be completed within 24
            hours.
          </p>
        </div>
      </div>

      <div
        className={`relative w-full rounded-lg ${theme.isLightTheme ? 'bg-stone-100' : 'bg-stone-900'} h-44 w-ful flex flex-col gap-2 py-[14px] px-4 items-start justify-between overflow-hidden`}
      >
        <div className="absolute -right-5 -bottom-12 md:-right-2 md:-bottom-10 p-0 m-0 max-w-[46%] h-full max-h-full">
          <img src={redeemSuccessIconGroup} alt="Success" className="w-full h-full p-0 m-0" />
        </div>
        <p className="min-h-fit font-Suisse-Intl font-medium text-2xl text-stone-500 text-wrap">
          Stake your USN to <br /> earn extra rewards
        </p>
        <div className="w-fit flex items-center justify-center p-2">
          <NavLink
            to="/sUsn"
            className={`font-Suisse-Intl font-medium text-base ${theme.isLightTheme ? 'text-stone-900' : 'text-stone-50'}`}
          >
            Stake Now
          </NavLink>
          <div className={`w-6 h-6 ${theme.isLightTheme ? 'text-stone-400' : 'text-stone-300'}`}>
            <ArrowRightIcon className="w-full h-full" />
          </div>
        </div>
      </div>

      <div className="w-full flex flex-col md:flex-row gap-4 items-center justify-center">
        <button
          onClick={() => props.setScreen('input')}
          className="grow w-full md:w-auto rounded-[184px] py-3 px-[18px] gap-[6px] bg-indigo-600 font-Suisse-Intl font-medium text-base text-stone-50"
        >
          Redeem More USN
        </button>
      </div>
    </div>
  )
}

export default RedeemSuccessful
