import React, { useContext } from 'react'

import useLastWithdrawReqId from '../../hooks/read/useLastWithdrawReqId'
import useWithdrawPeriod from '../../hooks/read/useWithdrawPeriod'
import { ThemeContext } from '../../context/ThemeProvider'
import WithdrawReqInfo from './WithdrawReqInfo'
import { ScrollArea } from '../ui/ScrollArea'
import { TsUsnScreen } from '../../types'
import StakeInfo from '../StakeInfo'

interface IProps {
  screen: TsUsnScreen
  refetchStakedBalance: boolean
  setScreen: (screen: TsUsnScreen) => void
}

const Withdraw = (props: IProps) => {
  const theme = useContext(ThemeContext)
  const withdrawPeriod = useWithdrawPeriod()
  const lastWithdrawReqId = useLastWithdrawReqId()

  return (
    <div
      className={`w-full max-w-full flex flex-col items-start justify-start rounded-xl border-[1px] ${theme.isLightTheme ? 'border-stone-300' : 'border-stone-800'} overflow-hidden`}
    >
      <div className={`w-full max-w-full p-6 !pb-0 gap-6 ${theme.isLightTheme ? 'bg-white' : 'bg-stone-950'}`}>
        <ScrollArea className="relative w-full max-w-full gap-2 h-96 p-0 flex flex-col-reverse items-start justify-start">
          {Array.from({ length: lastWithdrawReqId.data || -1 })
            .map((_, i) => <WithdrawReqInfo key={i} reqId={i} withdrawPeriod={withdrawPeriod.data || 0} />)
            .reverse()}
        </ScrollArea>
      </div>
      <StakeInfo refetch={props.refetchStakedBalance} />
    </div>
  )
}

export default Withdraw
