import React, { useContext, useMemo } from 'react'

import { ReactComponent as ArrowRightUpIcon } from '../../assets/navigation/arrow-right-up.svg'
import { ReactComponent as CheckIcon } from '../../assets/success/check-tick.svg'
import { ThemeContext } from '../../context/ThemeProvider'
import ActionInAndOutInfo from '../ActionInAndOutInfo'
import { formatStringToNumber } from '../../utils'
import { TMintScreen } from '../../types'

interface IProps {
  usnAmount: string
  sUsnAmount: string
  blockExplorerTxnUrl: string
  setScreen: (screen: TMintScreen) => void
}

const StakeSuccessful = (props: IProps) => {
  const theme = useContext(ThemeContext)

  const isActionDisabled = useMemo(
    () => !props.usnAmount || !Number(props.usnAmount) || !props.sUsnAmount || !Number(props.sUsnAmount),
    [props],
  )

  const formattedUSNInputAmount = useMemo(
    () => formatStringToNumber(Number(props.usnAmount) ? props.usnAmount : '', true),
    [props, formatStringToNumber],
  )

  const formattedUSNsOutputAmount = useMemo(
    () => formatStringToNumber(Number(props.sUsnAmount) ? props.sUsnAmount : '', true),
    [props, formatStringToNumber],
  )

  return (
    <React.Fragment>
      <div
        className={`w-[72px] h-[72px] rounded-[64px] ${theme.isLightTheme ? 'bg-green-200 text-green-800' : 'bg-green-1000 text-green-200'} p-4`}
      >
        <CheckIcon className="w-full h-full" />
      </div>

      <p
        className={`font-Louize font-normal text-4xl tracking-tighter ${theme.isLightTheme ? 'text-stone-900' : 'text-stone-50'}`}
      >
        You have successfully staked <br /> USN
      </p>

      <ActionInAndOutInfo
        tokenIn="USN"
        tokenOut="sUSN"
        labelIn="Staking"
        labelOut="To get back"
        amountIn={formattedUSNInputAmount}
        amountOut={formattedUSNsOutputAmount}
      />

      <div className="w-full flex flex-col md:flex-row gap-4 items-center justify-center">
        <a
          href={isActionDisabled ? '' : props.blockExplorerTxnUrl || ''}
          className={`grow w-full md:w-auto flex rounded-[184px] border-[1px] py-3 px-[18px] gap-[6px] ${theme.isLightTheme ? 'bg-stone-100 border-stone-300 ' : 'bg-stone-900 border-stone-800'} items-center justify-center ${isActionDisabled ? 'cursor-not-allowed' : 'cursor-pointer'}`}
        >
          <p
            className={`font-Suisse-Intl font-medium text-base ${theme.isLightTheme ? 'text-stone-900' : 'text-stone-50'}`}
          >
            View Explorer
          </p>
          <div className={`w-6 h-6 text-stone-400`}>
            <ArrowRightUpIcon className="w-full h-full" />
          </div>
        </a>

        <button
          onClick={() => props.setScreen('input')}
          className="grow w-full md:w-auto rounded-[184px] py-3 px-[18px] gap-[6px] bg-indigo-600 font-Suisse-Intl font-medium text-base text-stone-50"
        >
          Stake More
        </button>
      </div>
    </React.Fragment>
  )
}

export default StakeSuccessful
