import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useAccount, useChains } from 'wagmi'

import { mainnet } from 'wagmi/chains'

import { ReactComponent as VolumeMeterChartLightIcon } from '../../assets/info/volume-meter-chart-light.svg'
import { ReactComponent as DoubleChevroRightIcon } from '../../assets/navigation/double-right-chevron.svg'
import { ReactComponent as VolumeMeterChartDarkIcon } from '../../assets/info/volume-meter-chart.svg'
import { ReactComponent as ArrowRightUpIcon } from '../../assets/navigation/arrow-right-up.svg'
import { ReactComponent as ArrowLeftIcon } from '../../assets/navigation/arrow-left.svg'
import elipsis38 from '../../assets/backgrounds/ellipse-38-with-blur-layer.png'
import { ReactComponent as InfoIcon } from '../../assets/info/info-circle.svg'
import { rainbowKitConfig } from '../../constants/rainbowKitConfig'
import useBridgeGas from '../../hooks/multichain/useBridgeGas'
import useBridge from '../../hooks/multichain/txns/useBridge'
import { BridgeContext } from '../../context/BridgeProvider'
import { ThemeContext } from '../../context/ThemeProvider'
import { ETxnStatus, EApiCallStatus } from '../../types'
import usesUsnPrice from '../../hooks/usesUsnPrice'
import { formatStringToNumber } from '../../utils'
import NetworkFee from '../NetworkFee/NetworkFee'
import ConnectButton from '../ConnectButton'
import { sophon } from '../../constants'
import './index.css'

interface IProps {
  setTxnUrl: (txnHash: string) => void
}

const BridgeConfirm = (props: IProps) => {
  const [btnLabel, setBtnLabel] = useState<string>('Confirm')

  const gas = useBridgeGas()
  const sUsnPrice = usesUsnPrice()
  const theme = useContext(ThemeContext)
  const bridge = useContext(BridgeContext)
  const onBridge = useBridge(props.setTxnUrl)
  const chains = useChains({ config: rainbowKitConfig })
  const account = useAccount({ config: rainbowKitConfig })

  const sourceChain = useMemo(() => {
    return chains.find((c) => c.id === bridge.source) || mainnet
  }, [bridge.source])

  const destinationChain = useMemo(() => {
    return chains.find((c) => c.id === bridge.destination) || sophon
  }, [bridge.source])

  const tokenPrice = useMemo(() => {
    if (bridge.token === 'USN') {
      return 1
    }

    return Number(sUsnPrice.data.sUsnPrice)
  }, [sUsnPrice, bridge.token])

  const isLoading = useMemo(() => {
    return (
      bridge.bridgeTxnStatus === ETxnStatus.PROCESSING ||
      bridge.chainChangeStatus === EApiCallStatus.PROCESSING ||
      bridge.bridgeTxnStatus === ETxnStatus.TXN_SUBMITTED_ON_CHAIN
    )
  }, [bridge.chainChangeStatus, bridge.bridgeTxnStatus])

  const isDisabled = useMemo(() => {
    return isLoading || !bridge.amount || !Number(bridge.amount)
  }, [isLoading, bridge.amount])

  const formattedTokenAmount = useMemo(() => {
    return formatStringToNumber(bridge.amount || '0.00', true)
  }, [bridge.amount])

  const formattedDollarAmount = useMemo(() => {
    const amount = Number(bridge.amount || '0.00')
    return formatStringToNumber(Number(amount * tokenPrice).toString() || '0', true)
  }, [tokenPrice, bridge.amount])

  useEffect(() => {
    if (bridge.bridgeTxnStatus === ETxnStatus.IDLE) {
      setBtnLabel('Confirm')
      return
    }

    if (
      bridge.bridgeTxnStatus === ETxnStatus.PROCESSING ||
      bridge.bridgeTxnStatus === ETxnStatus.TXN_SUBMITTED_ON_CHAIN
    ) {
      setBtnLabel('Confirming...')
      return
    }

    if (bridge.bridgeTxnStatus === ETxnStatus.TXN_FAILURE) {
      setBtnLabel('Try again')
      return
    }

    setBtnLabel('Confirmed')
    bridge.setScreen('success')
  }, [bridge.bridgeTxnStatus])

  return (
    <React.Fragment>
      <div
        className={`relative w-full h-fit rounded-lg ${theme.isLightTheme ? 'bg-white' : 'bg-stone-900'} gap-6 flex flex-col items-center justify-center grow py-8 px-4`}
      >
        {theme.isLightTheme ? <VolumeMeterChartLightIcon /> : <VolumeMeterChartDarkIcon />}

        <p
          className={`w-[60%] font-Louize font-normal text-4xl text-center ${theme.isLightTheme ? 'text-stone-900' : 'text-stone-50'} text-wrap`}
        >
          Confirm your Bridge Transaction
        </p>

        <div className="absolute left-0 right-0 ml-auto mr-auto p-0 h-[224px] w-[520px] max-h-full max-w-full -top-1 z-50">
          <img src={elipsis38} className="m-0 p-0 w-full h-full" />
        </div>
      </div>

      <div
        className={`w-full max-w-full flex items-start justify-center rounded-[10px] p-3 gap-2 ${theme.isLightTheme ? 'bg-stone-100' : 'bg-stone-800'}`}
      >
        <div className={`w-6 h-6 shrink-0 ${theme.isLightTheme ? 'text-stone-900' : 'text-stone-200'}`}>
          <InfoIcon className="w-full h-full" />
        </div>
        <div className="flex flex-col items-start justify-start gap-[2px] grow">
          <p
            className={`font-Suisse-Intl font-[450] text-base ${theme.isLightTheme ? 'text-stone-900' : 'text-stone-200'} text-wrap text-left`}
          >
            {`Before proceeding, double-check that you can access the wallet address on the receiving chain.`}
          </p>
          <p className="font-Suisse-Intl font-[450] text-sm text-stone-500 text-wrap text-left shrink-0">
            {`This is especially important if you use wallets like Gnosis Safe. If you cannot access this address, please change the receiving wallet address to avoid any issues.`}
          </p>
        </div>
      </div>

      <div
        className={`relative w-full h-fit gap-1 grid grid-rows-2 md:grid-cols-2 md:grid-rows-1 md:items-center md:justify-center grow`}
      >
        <div
          className={`w-full h-fit flex flex-col m-0 p-0 rounded-lg ${theme.isLightTheme ? '' : 'border-[1px] border-stone-800'} ${theme.isLightTheme ? 'bg-white' : 'bg-stone-950'} overflow-hidden`}
        >
          <div className={`gap-3 p-4 flex items-center justify-center`}>
            <div className={`relative h-6 w-6`}>
              <img
                alt={bridge.token}
                className="w-full h-full"
                onError={() => import(`../../assets/tokens/${bridge.token}.svg`)}
                src={`${process.env.PUBLIC_URL}/assets/tokens/${bridge.token}.svg`}
              />
              <div
                className={`absolute h-3 w-3 ${theme.isLightTheme ? 'border-[1px] border-white' : ''} rounded-[100px] bottom-0 right-0`}
              >
                <img className="w-full h-full" src={`${process.env.PUBLIC_URL}/assets/chains/${bridge.source}.svg`} />
              </div>
            </div>
            <div className="flex flex-col items-start justify-center">
              <p
                className={`font-Suisse-Intl font-[450] text-base ${theme.isLightTheme ? 'text-stone-900' : 'text-stone-50'}`}
              >
                {formattedTokenAmount} {bridge.token}
              </p>
              <p
                className={`font-Suisse-Intl font-[450] text-sm ${theme.isLightTheme ? 'text-stone-500' : 'text-stone-500'}`}
              >
                ${formattedDollarAmount}
              </p>
            </div>
          </div>
          {account.address && (
            <a
              href={`${sourceChain.blockExplorers?.default.url}/address/${account.address}`}
              className={`flex items-center justify-center ${theme.isLightTheme ? 'bg-stone-100 text-stone-500 border-t-[1px] border-t-stone-200' : 'bg-stone-800 text-stone-400'} p-[6px] gap-[6px] font-Suisse-Intl font-[450] text-sm cursor-pointer`}
            >
              {account.address.slice(0, 4)}...{account.address.slice(account.address.length - 5)}
              <div className={`w-5 h-5 text-stone-400`}>
                <ArrowRightUpIcon className="w-full h-full" />
              </div>
            </a>
          )}
        </div>

        <div
          className={`w-full h-fit flex flex-col m-0 p-0 rounded-lg ${theme.isLightTheme ? '' : 'border-[1px] border-stone-800'} ${theme.isLightTheme ? 'bg-white' : 'bg-stone-950'} overflow-hidden`}
        >
          <div className={`gap-3 p-4 flex items-center justify-center`}>
            <div className={`relative h-6 w-6`}>
              <img
                alt={bridge.token}
                className="w-full h-full"
                onError={() => import(`../../assets/tokens/${bridge.token}.svg`)}
                src={`${process.env.PUBLIC_URL}/assets/tokens/${bridge.token}.svg`}
              />
              <div
                className={`absolute h-3 w-3 ${theme.isLightTheme ? 'border-[1px] border-white' : ''} rounded-[100px] bottom-0 right-0`}
              >
                <img
                  className="w-full h-full"
                  src={`${process.env.PUBLIC_URL}/assets/chains/${bridge.destination}.svg`}
                />
              </div>
            </div>
            <div className="flex flex-col items-start justify-center">
              <p
                className={`font-Suisse-Intl font-[450] text-base ${theme.isLightTheme ? 'text-stone-900' : 'text-stone-50'}`}
              >
                {formattedTokenAmount} {bridge.token}
              </p>
              <p
                className={`font-Suisse-Intl font-[450] text-sm ${theme.isLightTheme ? 'text-stone-500' : 'text-stone-500'}`}
              >
                ${formattedDollarAmount}
              </p>
            </div>
          </div>
          {bridge.receiverAddress && (
            <a
              href={`${destinationChain.blockExplorers?.default.url}/address/${bridge.receiverAddress}`}
              className={`flex items-center justify-center ${theme.isLightTheme ? 'bg-stone-100 text-stone-500 border-t-[1px] border-t-stone-200' : 'bg-stone-800 text-stone-400'} p-[6px] gap-[6px] font-Suisse-Intl font-[450] text-sm cursor-pointer`}
            >
              {bridge.receiverAddress.slice(0, 4)}...{bridge.receiverAddress.slice(bridge.receiverAddress.length - 5)}
              <div className={`w-5 h-5 text-stone-400`}>
                <ArrowRightUpIcon className="w-full h-full" />
              </div>
            </a>
          )}
        </div>

        <div
          className={`h-6 w-6 ${theme.isLightTheme ? 'bg-stone-200 text-stone-700' : 'bg-stone-800 text-stone-50'} rounded-[72px] absolute top-[50%] rotate-90 md:rotate-0 left-[50%] z-50 -translate-x-[50%] -translate-y-[50%] p-[3px] bridge-main-network-double-arrow-icon`}
        >
          <DoubleChevroRightIcon className="w-full h-full" />
        </div>
      </div>

      <div
        className={`w-full h-fit flex flex-col items-center justify-center grow rounded-lg gap-4 p-4 ${theme.isLightTheme ? 'bg-stone-50 border-[1px] border-stone-200' : 'bg-stone-900'}`}
      >
        <div className="w-full p-0 m-0 flex flex-col items-start justify-start gap-6">
          <div className="w-full grid grid-rows-1 grid-cols-2 gap-2">
            <NetworkFee type={'data-field'} action={'bridge'} chainId={bridge.source} gasLimit={gas.data} />

            <div className="w-fit max-w-full flex gap-2 items-center justify-end justify-self-end">
              <p
                className={`font-Suisse-Intl font-[450] text-base text-right ${theme.isLightTheme ? 'text-stone-500' : 'text-stone-400'}`}
              >
                Max Slippage
              </p>
              <p
                className={`font-Suisse-Intl rounded-[4px] py-[2px] px-[6px] font-[450] text-base text-center ${theme.isLightTheme ? 'text-stone-500 bg-stone-200' : 'text-stone-400 bg-stone-900'}`}
              >
                1%
              </p>
            </div>
          </div>
        </div>

        <div className="w-full grid grid-rows-2 grid-cols-1 md:grid-rows-1 md:grid-cols-2 gap-4 items-center justify-center">
          <button
            onClick={() => bridge.setScreen('input')}
            className={`hidden md:flex w-full max-w-full rounded-[184px] border-[1px] py-3 px-[18px] gap-[6px] items-center justify-center cursor-pointer} ${theme.isLightTheme ? 'bg-stone-100 border-stone-300 ' : 'bg-stone-900 border-stone-800'}`}
          >
            <div className={`w-6 h-6 text-stone-400`}>
              <ArrowLeftIcon className="w-full h-full" />
            </div>
            <p
              className={`font-Suisse-Intl font-medium text-base text-center ${theme.isLightTheme ? 'text-stone-900' : 'text-stone-50'}`}
            >
              Back
            </p>
          </button>

          <ConnectButton
            label={btnLabel}
            onClick={onBridge}
            isLoading={isLoading}
            isDisabled={isDisabled}
            actionIcon="success-tick"
            useResponsiveSizes={false}
            actionType="primary-action"
            useFullAvailableWidth={true}
          />

          <button
            onClick={() => bridge.setScreen('input')}
            className={`w-full max-w-full flex md:hidden rounded-[184px] border-[1px] py-3 px-[18px] gap-[6px] items-center justify-center cursor-pointer} ${theme.isLightTheme ? 'bg-stone-100 border-stone-300 ' : 'bg-stone-900 border-stone-800'}`}
          >
            <div className={`w-6 h-6 text-stone-400`}>
              <ArrowLeftIcon className="w-full h-full" />
            </div>
            <p
              className={`font-Suisse-Intl font-medium text-base text-center ${theme.isLightTheme ? 'text-stone-900' : 'text-stone-50'}`}
            >
              Back
            </p>
          </button>
        </div>
      </div>
    </React.Fragment>
  )
}

export default BridgeConfirm
