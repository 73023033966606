/* eslint-disable react/react-in-jsx-scope */

interface IProps {
  classes?: string
}

const EmptyContainer = (props: IProps) => {
  return <div className={`rounded-lg ${props.classes}`}></div>
}

export default EmptyContainer
