import { useAccount } from 'wagmi'

import OftAbi from '../../constants/abis/OFT.json'

import { Contract } from 'zksync-ethers'

import { JsonRpcProvider } from 'ethers'

import { useQuery } from '@tanstack/react-query'

import { fromBytes, isAddress, parseUnits } from 'viem'

import { useContext } from 'react'

import { addressToBytes32, Options } from '@layerzerolabs/lz-v2-utilities'

import { getAmountPostSlippage, REFETCH_INTERVAL } from '../../utils'
import { rainbowKitConfig } from '../../constants/rainbowKitConfig'
import { BridgeContext } from '../../context/BridgeProvider'
import { DAPP_CONFIG } from '../../constants'

const useBridgeGas = () => {
  const bridge = useContext(BridgeContext)
  const account = useAccount({ config: rainbowKitConfig })

  const fetch = async () => {
    if (
      !bridge ||
      !account ||
      !bridge.quote ||
      !bridge.token ||
      !bridge.amount ||
      !account.chain ||
      !account.chainId ||
      !account.address ||
      !bridge.destination ||
      !account.isConnected ||
      !bridge.receiverAddress ||
      !isAddress(bridge.receiverAddress) ||
      bridge.source !== account.chainId
    ) {
      return BigInt(0)
    }
    const _source = DAPP_CONFIG[bridge.source]
    if (!_source) {
      return BigInt(0)
    }
    if (_source.layerZeroOftHelper) {
      return BigInt(0)
    }

    const _token = _source.tokens[bridge.token]
    if (!_token) {
      return BigInt(0)
    }
    const amountBn = parseUnits(bridge.amount, _token.decimals)
    if (!amountBn || !_token.oftAddress) {
      return BigInt(0)
    }
    const _destination = DAPP_CONFIG[bridge.destination]
    if (!_destination) {
      return BigInt(0)
    }

    const contract = new Contract(
      _token.oftAddress,
      OftAbi,
      new JsonRpcProvider(account.chain.rpcUrls.default.http.at(0)),
    )
    const executorLzReceiveOptionMaxGas = 65000
    const options = Options.newOptions().addExecutorLzReceiveOption(BigInt(executorLzReceiveOptionMaxGas), 0).toBytes()
    return await contract.send.estimateGas(
      [
        BigInt(_destination.oftEid),
        fromBytes(addressToBytes32(bridge.receiverAddress), 'hex'),
        amountBn,
        getAmountPostSlippage(amountBn),
        options,
        '0x',
        '0x',
      ],
      [bridge.quote.nativeFee, bridge.quote.lzTokenFee],
      account.address,
      {
        value: bridge.quote.nativeFee,
      },
    )
  }

  return useQuery({
    queryFn: fetch,
    refetchOnMount: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: true,
    initialData: () => BigInt(0),
    placeholderData: () => BigInt(0),
    refetchInterval: REFETCH_INTERVAL,
    refetchIntervalInBackground: false,
    select: (data) => (data || BigInt(0)) as bigint,
    queryKey: [
      'bridge',
      'gas',
      bridge.token,
      bridge.amount,
      account.chainId,
      bridge.destination,
      bridge.receiverAddress,
      account.address,
      bridge.quote.nativeFee?.toString(),
      bridge.quote.lzTokenFee?.toString(),
    ],
    enabled: Boolean(bridge) && Boolean(bridge.token) && account.isConnected,
  })
}

export default useBridgeGas
