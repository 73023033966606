import { useAccount } from 'wagmi'

import Router from '@uniswap/v3-periphery/artifacts/contracts/SwapRouter.sol/SwapRouter.json'

import { Contract } from 'zksync-ethers'

import { JsonRpcProvider } from 'ethers'

import { useQuery } from '@tanstack/react-query'

import { parseUnits } from 'viem'

import { useContext } from 'react'

import { getAmountPostSlippage, REFETCH_INTERVAL } from '../../utils'
import { rainbowKitConfig } from '../../constants/rainbowKitConfig'
import { BuyContext } from '../../context/BuyProvider'
import { DAPP_CONFIG } from '../../constants'

const useBuyGas = () => {
  const buy = useContext(BuyContext)
  const account = useAccount({ config: rainbowKitConfig })

  const fetch = async () => {
    if (
      !buy ||
      !account ||
      !buy.tokenIn ||
      !buy.tokenOut ||
      !buy.amountIn ||
      !buy.amountOut ||
      !account.chain ||
      !account.chainId ||
      !account.address ||
      !account.isConnected
    ) {
      return BigInt(0)
    }
    const _source = DAPP_CONFIG[account.chainId]
    if (!_source) {
      return BigInt(0)
    }
    if (!_source.uniV3Pools || !_source.uniV3Quoter || !_source.uniV3SwapRouter) {
      return BigInt(0)
    }

    const _tokenIn = _source.tokens[buy.tokenIn]
    if (!_tokenIn || !_source.tokensForSell?.includes(buy.tokenIn)) {
      return BigInt(0)
    }
    const amountInBn = parseUnits(buy.amountIn, _tokenIn.decimals)
    if (!amountInBn) {
      return BigInt(0)
    }

    const _tokenOut = _source.tokens[buy.tokenOut]
    if (!_tokenOut || !_source.tokensForBuy?.includes(buy.tokenOut)) {
      return BigInt(0)
    }

    const amountOutBn = parseUnits(buy.amountOut, _tokenOut.decimals)
    if (!amountOutBn) {
      return BigInt(0)
    }

    const _pool =
      _source.uniV3Pools[`${buy.tokenIn}-${buy.tokenOut}`] || _source.uniV3Pools[`${buy.tokenOut}-${buy.tokenIn}`]
    if (!_pool) {
      return BigInt(0)
    }

    const contract = new Contract(
      _source.uniV3SwapRouter,
      Router.abi,
      new JsonRpcProvider(account.chain.rpcUrls.default.http.at(0)),
    )

    return await contract.exactInputSingle.estimateGas({
      fee: _pool.poolFee,
      recipient: account.address,
      sqrtPriceLimitX96: BigInt(0),
      tokenIn: _tokenIn.contractAddress,
      tokenOut: _tokenOut.contractAddress,
      deadline: BigInt(Date.now() + 5 * 60 * 1000),
      amountIn: amountInBn,
      amountOutMinimum: getAmountPostSlippage(amountOutBn),
    })
  }

  return useQuery({
    queryFn: fetch,
    refetchOnMount: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: true,
    initialData: () => BigInt(0),
    placeholderData: () => BigInt(0),
    refetchInterval: REFETCH_INTERVAL,
    refetchIntervalInBackground: false,
    select: (data) => (data || BigInt(0)) as bigint,
    queryKey: ['buy', 'gas', buy.tokenIn, buy.tokenOut, buy.amountIn, buy.amountOut, account.chainId],
    enabled:
      Boolean(buy) &&
      account.isConnected &&
      Boolean(buy.tokenIn) &&
      Boolean(buy.tokenOut) &&
      Boolean(buy.amountIn) &&
      Boolean(buy.amountOut),
  })
}

export default useBuyGas
