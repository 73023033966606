import React, { useContext } from 'react'

import { ThemeContext } from '../../context/ThemeProvider'
import ActionCardBackButton from '../ActionCardBackButton'
import { TScreen } from '../../types'

interface IProps {
  screen?: TScreen
  classes?: string
  hideBack?: boolean
  hideShadow?: boolean
  setScreen?: (screen: TScreen) => void
}

const ActionCard = (props: IProps & React.PropsWithChildren) => {
  const theme = useContext(ThemeContext)

  if (!props.screen) {
    return (
      <div
        className={`w-full max-w-full h-fit flex flex-col items-start justify-start rounded-xl border-[1px] py-3 px-4 md:p-6 gap-6 ${theme.isLightTheme ? 'bg-white border-stone-300' : 'bg-stone-900 border-stone-800'} ${props.classes || ''} ${props.hideShadow ? '' : 'action-card-shadow'}`}
      >
        {props.children}
      </div>
    )
  }

  if (props.screen && props.screen === 'input') {
    return (
      <div
        className={`w-full max-w-full h-fit flex flex-col items-start justify-start rounded-xl border-[1px] p-2 pb-6 gap-6 ${theme.isLightTheme ? 'bg-white border-stone-300' : 'bg-stone-900 border-stone-800'} ${props.classes || ''} ${props.hideShadow ? '' : 'action-card-shadow'}`}
      >
        {props.children}
      </div>
    )
  }

  return (
    <div
      className={`w-full max-w-full h-fit flex flex-col items-start justify-start rounded-xl border-[1px] py-3 px-4 md:p-6 gap-6 ${theme.isLightTheme ? 'bg-white border-stone-300' : 'bg-stone-950 border-stone-800'} ${props.classes || ''} ${props.hideShadow ? '' : 'action-card-shadow'}`}
    >
      {props.screen === 'confirm' && props.setScreen && !props.hideBack && (
        <ActionCardBackButton setScreen={props.setScreen!} />
      )}
      {props.children}
    </div>
  )
}

export default ActionCard
