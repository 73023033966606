import { useAccount, useChainId } from 'wagmi'
import moment from 'moment'

import {
  UserProfile,
  RecentTransactions,
  ChartInfoComponent,
  Holdings,
  PortfolioChartInfoComponent,
  PointsEstimateModal,
} from '../../components/Dashboard/'

import React, { useContext, useEffect, useMemo, useState } from 'react'

import { useDisclosure } from '@chakra-ui/react'

import UserProfileEditModal from '../../components/Dashboard/UserProfileEditModal'
import { rainbowKitConfig } from '../../constants/rainbowKitConfig'
import { TProtocolMetrics, TUserSummary } from '../../types'
import { ThemeContext } from '../../context/ThemeProvider'
import useUsername from '../../hooks/useUsername'
import './index.css'

const DashboardPage = () => {
  const [userDashboardData, setUserDashboardData] = useState<TUserSummary>()
  const [aprTimeSeriesData, setAprTimeSeriesData] = useState<TProtocolMetrics['apyTimeSeries']>({})

  const username = useUsername()
  const theme = useContext(ThemeContext)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const account = useAccount({ config: rainbowKitConfig })
  const chainId = useChainId({ config: rainbowKitConfig })
  const {
    isOpen: isUserProfileEditModalOpen,
    onOpen: onUserProfileEditModalOpen,
    onClose: onUserProfileEditModalClose,
  } = useDisclosure()

  const fetchUserDashboardData = async () => {
    await fetch(`${process.env.REACT_APP_BASE_API_URL ?? ''}/api/v1/dashboard/${account.address}`)
      .then(
        (res) => {
          if (res.ok) {
            return res.json()
          }

          throw new Error('Failed to fetch user dashboard data')
        },
        (err) => {
          throw new Error(err)
        },
      )
      .then((json) => json)
      .then((userDashboardData) => {
        setUserDashboardData(userDashboardData ?? {})
      })
      .catch(() => {
        return
      })
  }

  const fetchProtocolMetrics = async () => {
    await fetch(`${process.env.REACT_APP_BASE_API_URL ?? ''}/api/v1/protocol-metrics`)
      .then(
        (res) => {
          if (res.ok) {
            return res.json()
          }

          throw new Error('Failed to fetch protocol metrics')
        },
        (err) => {
          throw new Error(err)
        },
      )
      .then((json) => json as TProtocolMetrics)
      .then((metrics) => {
        setAprTimeSeriesData(metrics?.apyTimeSeries ?? {})
      })
      .catch(() => {
        return
      })
  }

  useEffect(() => {
    fetchProtocolMetrics()
    fetchUserDashboardData()
  }, [account.address, chainId])

  const lastUpdatedAt = useMemo(() => {
    const dateInstance = new Date(userDashboardData?.cacheTimestamp ?? Date.now())
    return moment(dateInstance).utc().format('Do MMM YYYY • hh:mm A UTC')
  }, [userDashboardData])

  return (
    <div className="h-fit w-full max-w-full flex flex-col gap-12 py-8 px-4 md:px-20 md:py-32 items-start justify-center">
      <PointsEstimateModal isOpen={isOpen} onClose={onClose} points={userDashboardData?.userPoints ?? '0'} />
      <UserProfileEditModal
        walletAddress={account.address}
        isOpen={isUserProfileEditModalOpen}
        onClose={onUserProfileEditModalClose}
        profileUsername={username?.data || 'fren'}
        usernameRefetch={username.refetch}
      />

      <div className="w-full h-fit flex p-0 m-0 gap-4 justify-center items-end flex-col-reverse md:flex-col">
        <div
          className={`${theme.isLightTheme ? 'bg-stone-100 border-[1px] border-stone-200 text-stone-600' : 'bg-stone-900 border-[1px] border-stone-800 text-stone-300'} rounded-lg py-[6px] px-2 gap-[2px] font-Suisse-Intl font-450 text-sm`}
        >
          Last Update: {lastUpdatedAt}
        </div>

        <div
          className={`grid grid-cols-1 md:grid-rows-2 md:grid-cols-2 w-full h-fit p-0 m-0 rounded-2xl ${theme.isLightTheme ? 'bg-white' : 'bg-stone-900'} dashboard-personalized-info-section`}
        >
          <UserProfile username={username?.data || 'fren'} onOpen={onUserProfileEditModalOpen} />
          <Holdings onOpen={onOpen} isEmpty={!userDashboardData} points={userDashboardData?.userPoints ?? '0'} />
          <ChartInfoComponent aprTimeSeriesData={aprTimeSeriesData} isEmpty={!aprTimeSeriesData} />
        </div>
      </div>

      <RecentTransactions connected={!!account.address} />
    </div>
  )
}

export default DashboardPage
