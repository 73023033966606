/* eslint-disable react/react-in-jsx-scope */
import { AreaChart, Area, Tooltip, ResponsiveContainer, TooltipProps } from 'recharts'
import { useContext } from 'react'

import { ThemeContext } from '../../context/ThemeProvider'
import './ProtocolInfoChart.css'

interface IProps {
  data: {
    value: number
    label: string
  }[]
}

export const ChartTooltip = (props?: TooltipProps<string | number, string | number>) => {
  const theme = useContext(ThemeContext)

  if (props?.active && props?.payload) {
    const _payload = props?.payload?.at(0)
    return (
      <div className={`rounded-lg p-[6px] gap-[4px] ${theme.isLightTheme ? 'bg-white' : 'bg-stone-800'} chart-tooltip`}>
        <p className={`font-Inter font-semibold text-sm ${theme.isLightTheme ? 'text-stone-900' : 'text-stone-50'}`}>
          {_payload?.payload.value + '%'}
        </p>
        <p className="font-Inter font-normal text-xs text-stone-500">{_payload?.payload.label.replaceAll('-', '/')}</p>
      </div>
    )
  }

  return null
}

function InfoChart(props: IProps) {
  const theme = useContext(ThemeContext)
  const data: IProps['data'] = props.data.length ? props.data : []

  return (
    <ResponsiveContainer width="100%" height="100%">
      <AreaChart
        data={data}
        margin={{
          top: 0,
          right: 0,
          left: 0,
          bottom: 0,
        }}
      >
        <Tooltip content={<ChartTooltip />} />
        <defs>
          <linearGradient id="colorValue" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor={theme.isLightTheme ? '#C5CAFF' : '#3C4285'} stopOpacity={1} />
            <stop offset="95%" stopColor={theme.isLightTheme ? '#C5CAFF' : '#1A1D4000'} stopOpacity={0} />
          </linearGradient>
        </defs>
        <Area type="monotone" dataKey="value" stroke="#6C77E9" fillOpacity={1} fill="url(#colorValue)" />
      </AreaChart>
    </ResponsiveContainer>
  )
}

export default InfoChart
