/* eslint-disable react/react-in-jsx-scope */
import { useContext } from 'react'

import { ThemeContext } from '../../context/ThemeProvider'
import USN from '../../assets/tokens/USN.svg'

interface IProps {
  currency: string
}

const USNPrice = (props: IProps) => {
  const theme = useContext(ThemeContext)

  return (
    <div className="w-full flex gap-6 items-center justify-between">
      <div className="w-fit flex rounded gap-1 items-center justify-between">
        <div className="w-[18px] h-[18px] rounded-[100px]">
          <img src={USN} className="w-full h-full" alt="USN" />
        </div>
        <p className="font-Suisse-Intl font-[450] text-base text-stone-500">1 USN equal to</p>
      </div>
      <p
        className={`font-Suisse-Intl font-semibold text-base ${theme.isLightTheme ? 'text-stone-900' : 'text-stone-50'}`}
      >
        1.00 {props.currency}
      </p>
    </div>
  )
}

export default USNPrice
