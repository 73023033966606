import { ConnectButton as RainbowkitConnectButton } from '@rainbow-me/rainbowkit'
import React, { useContext } from 'react'

import { SpinnerIcon } from '@chakra-ui/icons'

import { ReactComponent as ArrowRightIcon } from '../../assets/navigation/arrow-right.svg'
import { ReactComponent as ArrowLeftIcon } from '../../assets/navigation/arrow-left.svg'
import { ReactComponent as CheckTickIcon } from '../../assets/success/check-tick.svg'
import { ReactComponent as LogoutIcon } from '../../assets/wallet/log-out.svg'
import { ThemeContext } from '../../context/ThemeProvider'
import walletLogo from '../../assets/wallet/wallet.svg'

interface IProps {
  label?: string
  isSmall?: boolean
  isLoading?: boolean
  showArrow?: boolean
  isDisabled?: boolean
  showWalletLogo?: boolean
  useResponsiveSizes?: boolean
  useFullAvailableWidth?: boolean
  switchNetworkSuggestion?: string
  onClick?: () => Promise<void> | void
  actionIcon?: 'arrow-right' | 'success-tick' | ''
  actionType?: 'connect' | 'primary-action' | 'back'
}

export const ConnectButton = (props: IProps) => {
  const {
    label = '',
    isLoading = false,
    showArrow = false,
    onClick = () => {},
    isDisabled = false,
    showWalletLogo = true,
    actionType = 'connect',
    useResponsiveSizes = true,
    actionIcon = 'arrow-right',
    switchNetworkSuggestion = '',
    useFullAvailableWidth = false,
  } = props

  const theme = useContext(ThemeContext)

  return (
    <RainbowkitConnectButton.Custom>
      {({ account, chain, openChainModal, openAccountModal, openConnectModal, mounted }) => {
        const connected = mounted && account && chain

        return (
          <div
            className={`${useFullAvailableWidth ? 'grow w-full max-w-full flex m-0 p-0 items-center justify-center' : 'w-fit'}`}
            {...(!mounted && {
              'aria-hidden': true,
              style: {
                opacity: 0,
                pointerEvents: 'none',
                userSelect: 'none',
              },
            })}
          >
            {(() => {
              if (!connected) {
                return (
                  <button
                    onClick={openConnectModal}
                    className={`flex items-center justify-center ${useResponsiveSizes ? 'gap-1 md:gap-1.5 py-1.5 px-3 md:py-3 md:px-[18px]' : 'gap-1.5 py-3 px-[18px]'} ${useFullAvailableWidth ? 'grow max-w-full' : 'w-fit'} rounded-[184px] bg-indigo-600 ${props.isSmall ? '!py-1.5 !px-3 !gap-1' : ''}`}
                  >
                    {showWalletLogo && (
                      <div
                        className={`${useResponsiveSizes ? 'h-5 w-5 md:h-6 md:w-6]' : 'h-6 w-6'} ${props.isSmall ? '!h-5 w-5' : ''}`}
                      >
                        <img src={walletLogo} className="h-full w-full object-cover" alt="Dark Mode Logo" />
                      </div>
                    )}
                    <p
                      className={`text-stone-50 w-fit ${useResponsiveSizes ? 'text-sm md:text-base leading-5 md:leading-6' : 'text-base leading-6'} font-bold font-Suisse-Intl cursor-pointer ${props.isSmall ? 'text-sm' : ''}`}
                    >
                      Connect wallet
                    </p>
                    {showArrow && (
                      <div
                        className={`${useResponsiveSizes ? 'h-5 w-5 md:h-6 md:w-6' : 'h-6 w-6'} text-stone-50 ${props.isSmall ? '!h-5 w-5' : ''}`}
                      >
                        <ArrowRightIcon className="h-full w-full" />
                      </div>
                    )}
                  </button>
                )
              }

              if (chain.unsupported) {
                return (
                  <button
                    onClick={openChainModal}
                    className={`flex items-center justify-center ${useResponsiveSizes ? 'gap-1 md:gap-1.5 py-1.5 px-3 md:py-3 md:px-[18px]' : 'gap-1.5 py-3 px-[18px]'} ${useFullAvailableWidth ? 'grow max-w-full' : 'w-fit'} rounded-[184px] bg-indigo-600 ${props.isSmall ? '!py-1.5 !px-3 !gap-1' : ''}`}
                  >
                    {showWalletLogo && (
                      <div
                        className={`${useResponsiveSizes ? 'h-5 w-5 md:h-6 md:w-6]' : 'h-6 w-6'} ${props.isSmall ? '!h-5 w-5' : ''}`}
                      >
                        <img src={walletLogo} className="h-full w-full object-cover" alt="Dark Mode Logo" />
                      </div>
                    )}
                    <p
                      className={`text-stone-50 w-fit ${useResponsiveSizes ? 'text-sm md:text-base leading-5 md:leading-6' : 'text-base leading-6'} font-bold font-Suisse-Intl cursor-pointer ${props.isSmall ? 'text-sm' : ''}`}
                    >
                      Switch Network {switchNetworkSuggestion}
                    </p>
                    {showArrow && (
                      <div
                        className={`${useResponsiveSizes ? 'h-5 w-5 md:h-6 md:w-6' : 'h-6 w-6'} text-stone-50 ${props.isSmall ? '!h-5 w-5' : ''}`}
                      >
                        <ArrowRightIcon className="h-full w-full" />
                      </div>
                    )}
                  </button>
                )
              }

              if (actionType === 'connect') {
                return (
                  <button
                    onClick={openAccountModal}
                    className={`flex items-center justify-center ${useResponsiveSizes ? 'gap-1 md:gap-1.5 py-1.5 px-3 md:py-3 md:px-[18px]' : 'gap-1.5 py-3 px-[18px]'} ${useFullAvailableWidth ? 'grow max-w-full' : 'w-fit'} rounded-[184px] ${theme.isLightTheme ? 'bg-stone-100' : 'bg-stone-900'} border-[1px] ${theme.isLightTheme ? 'border-stone-300' : 'border-stone-800'} ${props.isSmall ? '!py-1.5 !px-3 !gap-1' : ''}`}
                  >
                    <div
                      className={`${useResponsiveSizes ? 'h-5 w-5 md:h-6 md:w-6' : 'h-6 w-6'} ${theme.isLightTheme ? 'text-stone-500' : 'text-stone-400'} ${props.isSmall ? '!h-5 w-5' : ''}`}
                    >
                      <LogoutIcon className="w-full h-full" />
                    </div>
                    <p
                      className={`${theme.isLightTheme ? 'text-stone-900' : 'text-stone-50'} w-fit ${useResponsiveSizes ? 'text-sm md:text-base leading-5 md:leading-6' : 'text-base leading-6'} font-medium font-Suisse-Intl cursor-pointer ${props.isSmall ? 'text-sm' : ''}`}
                    >
                      {`${account.address.slice(0, 4)}...${account.address.slice(account.address.length - 5)}`}
                    </p>
                  </button>
                )
              }

              if (actionType === 'primary-action') {
                return (
                  <button
                    onClick={onClick}
                    disabled={isDisabled || isLoading}
                    className={`w-full rounded-[184px] py-3 px-[18px] ${isDisabled ? (theme.isLightTheme ? 'bg-indigo-400' : 'bg-indigo-900') : 'bg-indigo-600'} flex gap-[6px] items-center justify-center ${isLoading ? 'animate-pulse' : ''} ${props.isSmall ? '!py-1.5 !px-3 !gap-1' : ''}`}
                  >
                    {isLoading && (
                      <SpinnerIcon
                        className={`w-6 h-6 ${isDisabled ? (theme.isLightTheme ? 'text-indigo-200' : 'text-indigo-600') : 'text-stone-50'} animate-spin ${props.isSmall ? '!h-5 w-5' : ''}`}
                        height={props.isSmall ? 'h-5' : 'h-6'}
                        width={props.isSmall ? 'w-5' : 'w-6'}
                        stroke={
                          isDisabled ? (theme.isLightTheme ? 'text-indigo-200' : 'text-indigo-600') : 'text-stone-50'
                        }
                        color={
                          isDisabled ? (theme.isLightTheme ? 'text-indigo-200' : 'text-indigo-600') : 'text-stone-50'
                        }
                      />
                    )}
                    <p
                      className={`font-Suisse-Intl font-medium text-base ${isDisabled ? (theme.isLightTheme ? 'text-indigo-200' : 'text-indigo-600') : 'text-stone-50'} ${props.isSmall ? 'text-sm' : ''}`}
                    >
                      {label}
                    </p>
                    {!isLoading && actionIcon !== '' && (
                      <div
                        className={`w-6 h-6 ${isDisabled ? (theme.isLightTheme ? 'text-indigo-200' : 'text-indigo-600') : 'text-stone-50'} ${props.isSmall ? '!h-5 w-5' : ''}`}
                      >
                        {actionIcon === 'arrow-right' ? (
                          <ArrowRightIcon className="w-full h-full" />
                        ) : (
                          <CheckTickIcon className="w-full h-full" />
                        )}
                      </div>
                    )}
                  </button>
                )
              }

              return (
                <button
                  onClick={onClick}
                  className={`grow w-full md:w-auto flex rounded-[184px] border-[1px] py-3 px-[18px] gap-[6px] ${theme.isLightTheme ? 'bg-stone-100 border-stone-300 ' : 'bg-stone-900 border-stone-800'} items-center justify-center cursor-pointer} ${props.isSmall ? '!py-1.5 !px-3 !gap-1' : ''}`}
                >
                  <div className={`w-6 h-6 text-stone-400 ${props.isSmall ? '!h-5 w-5' : ''}`}>
                    <ArrowLeftIcon className="w-full h-full" />
                  </div>
                  <p
                    className={`font-Suisse-Intl font-medium text-base ${theme.isLightTheme ? 'text-stone-900' : 'text-stone-50'} ${props.isSmall ? 'text-sm' : ''}`}
                  >
                    Back
                  </p>
                </button>
              )
            })()}
          </div>
        )
      }}
    </RainbowkitConnectButton.Custom>
  )
}

export default ConnectButton
