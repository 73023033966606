import { useAccount } from 'wagmi'

import { useQuery } from '@tanstack/react-query'

import { rainbowKitConfig } from '../../constants/rainbowKitConfig'
import { TMintReq, TPendingReq } from '../../types'
import { REFETCH_INTERVAL } from '../../utils'

const usePendingRequests = () => {
  const account = useAccount({ config: rainbowKitConfig })

  const fetcher = async () => {
    if (!account || !account.address || !account.isConnected) {
      return {
        pendingMinting: [] as TMintReq[],
        pendingRedeeming: [] as TMintReq[],
      } as TPendingReq
    }

    return await fetch(`${process.env.REACT_APP_BASE_API_URL ?? ''}/api/v1/pending-requests/${account.address}`)
      .then(
        (res) => {
          if (res.ok) {
            return res.json()
          }

          throw new Error('Failed to fetch protocol metrics')
        },
        (err) => {
          throw new Error(err)
        },
      )
      .then((json) => json as TPendingReq)
      .then((requests) => {
        return {
          pendingMinting: (requests?.pendingMinting || []) as TMintReq[],
          pendingRedeeming: (requests?.pendingRedeeming || []) as TMintReq[],
        } as TPendingReq
      })
      .catch((err) => {
        console.error('Failed to fetch redeeming requests', err)
        return {
          pendingMinting: [] as TMintReq[],
          pendingRedeeming: [] as TMintReq[],
        } as TPendingReq
      })
  }

  return useQuery({
    queryFn: fetcher,
    refetchOnMount: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: true,
    placeholderData: {
      pendingMinting: [] as TMintReq[],
      pendingRedeeming: [] as TMintReq[],
    } as TPendingReq,
    refetchInterval: REFETCH_INTERVAL,
    refetchIntervalInBackground: true,
    initialData: () =>
      ({
        pendingMinting: [] as TMintReq[],
        pendingRedeeming: [] as TMintReq[],
      }) as TPendingReq,
    enabled: Boolean(account.isConnected),
    queryKey: ['api', 'pending', 'requests', account.isConnected, account.address],
    select: (data) => {
      return {
        pendingMinting: (data?.pendingMinting || []) as TMintReq[],
        pendingRedeeming: (data?.pendingRedeeming || []) as TMintReq[],
      } as TPendingReq
    },
  })
}

export default usePendingRequests
