/* eslint-disable react/react-in-jsx-scope */
import { AreaChart, Area, Tooltip, ResponsiveContainer, TooltipProps } from 'recharts'
import { useContext, useMemo } from 'react'

import moment from 'moment'

import { ThemeContext } from '../../context/ThemeProvider'
import './ProtocolInfoChart.css'

interface IProps {
  data: {
    value: number
    label: string
  }[]
}

export const ChartTooltip = (props?: TooltipProps<string | number, string | number>) => {
  const theme = useContext(ThemeContext)

  if (props?.active && props?.payload) {
    const _payload = props?.payload?.at(0)
    return (
      <div className={`rounded-lg p-[6px] gap-[4px] ${theme.isLightTheme ? 'bg-white' : 'bg-stone-800'} chart-tooltip`}>
        <p className={`font-Inter font-semibold text-sm ${theme.isLightTheme ? 'text-stone-900' : 'text-stone-50'}`}>
          {_payload?.payload.value + '%'}
        </p>
        <p className="font-Inter font-normal text-xs text-stone-500">
          {moment(new Date(_payload?.payload.label)).format('DD MMM YYYY')}
        </p>
      </div>
    )
  }

  return null
}

function InfoChart(props: IProps) {
  const theme = useContext(ThemeContext)
  const data: IProps['data'] = props.data && props.data.length ? props.data : []

  const startDate = useMemo(() => {
    const dateString = data.at(0)?.label
    if (!dateString) return ''
    return moment(new Date(dateString)).format('DD MMM YYYY')
  }, [data])

  const endDate = useMemo(() => {
    const dateString = data.at(-1)?.label
    if (!dateString) return ''
    return moment(new Date(dateString)).format('DD MMM YYYY')
  }, [data])

  const { minValue, maxValue } = useMemo(() => {
    if (!data || !data.length) {
      return { minValue: 0, maxValue: 10 }
    }

    const values = data.map((d) => Number(d.value || 0))

    return {
      minValue: 0,
      maxValue: Math.ceil(Math.max(...values)),
    }
  }, [data])

  return (
    <div className="w-full h-fit flex flex-row items-stretch justify-center gap-2">
      <div className="w-fit min-h-40 h-40 max-h-40 flex flex-col items-end justify-between gap-1">
        <p
          className={`w-fit font-Suisse-Intl font-[450] pt-2 text-sm leading-5 text-right ${theme.isLightTheme ? 'text-stone-500' : 'text-stone-400'}`}
        >
          {maxValue + '%'}
        </p>
        <p
          className={`w-fit font-Suisse-Intl font-[450] pb-1 text-sm leading-5 text-right ${theme.isLightTheme ? 'text-stone-500' : 'text-stone-400'}`}
        >
          {minValue + '%'}
        </p>
      </div>
      <div className="w-full h-fit">
        <ResponsiveContainer className={'w-full min-h-40 h-40 max-h-40'} id={`staking-info-chart-${false}`}>
          <AreaChart
            data={data}
            margin={{
              top: 0,
              right: 0,
              left: 0,
              bottom: 0,
            }}
          >
            <Tooltip content={<ChartTooltip />} />
            <defs>
              <linearGradient id="colorValue1" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor={theme.isLightTheme ? '#B9E8EC' : '#286267'} stopOpacity={1} />
                <stop offset="95%" stopColor={theme.isLightTheme ? '#B9E8EC' : '#286267'} stopOpacity={0} />
              </linearGradient>
            </defs>
            <Area
              type="monotone"
              dataKey="value"
              stroke={theme.isLightTheme ? `#1D919C` : `#6AABB1`}
              fillOpacity={1}
              fill="url(#colorValue1)"
            />
          </AreaChart>
        </ResponsiveContainer>
        <div className="w-full p-2 gap-2 flex items-center justify-between ">
          <p
            className={`font-Suisse-Intl font-[450] text-sm ${theme.isLightTheme ? 'text-stone-500' : 'text-stone-400'}`}
          >
            {startDate}
          </p>
          <p
            className={`font-Suisse-Intl font-[450] text-sm ${theme.isLightTheme ? 'text-stone-500' : 'text-stone-400'}`}
          >
            {endDate}
          </p>
        </div>
      </div>
    </div>
  )
}

export default InfoChart
