import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { mainnet } from 'viem/chains'

import usePreviewUsnDeposit from '../../hooks/read/usePreviewUsnDeposit'
import useStakingAllowance from '../../hooks/read/useStakingAllowance'
import { formatBigNumber, formatStringToNumber } from '../../utils'
import useTokenBalance from '../../hooks/read/useTokenBalance'
import ReceiveInfoAndStats from '../ReceiveInfoAndStats'
import useApprove from '../../hooks/write/useApprove'
import { ETxnStatus, TsUsnScreen } from '../../types'
import { DAPP_CONFIG } from '../../constants'
import ConnectButton from '../ConnectButton'
import BalanceError from '../BalanceError'
import Input from '../Input'

interface IProps {
  usnAmount: string
  sUsnAmount: string
  setUsnAmount: (amount: string) => void
  setsUsnAmount: (amount: string) => void
  setScreen: (screen: TsUsnScreen) => void
}

const InputAndApproveStake = (props: IProps) => {
  const [btnLabel, setBtnLabel] = useState<string>('Next')
  const [isApproved, setIsApproved] = useState<boolean>(false)
  const [isAmountInGtMax, setIsAmountGtMax] = useState<boolean>(false)
  const [txnStatus, setTxnStatus] = useState<ETxnStatus>(ETxnStatus.IDLE)

  const usnBalance = useTokenBalance('USN')
  const usnAllowance = useStakingAllowance()
  const sUsnOutputAmount = usePreviewUsnDeposit(props.usnAmount)

  const isLoading = useMemo(
    () =>
      usnBalance.isFetching ||
      usnAllowance.isFetching ||
      sUsnOutputAmount.isFetching ||
      txnStatus === ETxnStatus.PROCESSING ||
      txnStatus === ETxnStatus.TXN_SUBMITTED_ON_CHAIN,
    [usnBalance, usnAllowance, sUsnOutputAmount, txnStatus],
  )

  const isDisabled = useMemo(
    () => isLoading || isAmountInGtMax || !props.usnAmount || !Number(props.usnAmount),
    [props, isLoading, isAmountInGtMax],
  )

  const formattedsUsnAmount = useMemo(() => formatStringToNumber(props.sUsnAmount, true), [props.sUsnAmount])

  const onApproveCb = useApprove(
    'USN',
    DAPP_CONFIG[mainnet.id].stakingVault,
    setBtnLabel,
    setTxnStatus,
    props.setScreen,
  )

  const onClickCb = useCallback(() => {
    if (isApproved) {
      props.setScreen('confirm')
      return
    }
    onApproveCb()
  }, [isApproved, onApproveCb, props.setScreen])

  useEffect(() => {
    props.setsUsnAmount(formatBigNumber(sUsnOutputAmount.data || BigInt(0), 18, false))
  }, [props, sUsnOutputAmount])

  useEffect(() => {
    if (isApproved) {
      setBtnLabel('Next')
      return
    }
    setBtnLabel('Approve')
  }, [isApproved])

  return (
    <React.Fragment>
      <Input
        tokens={[]}
        token={'USN'}
        setToken={() => {}}
        label={'STAKE AMOUNT'}
        amount={props.usnAmount}
        setScreen={props.setScreen}
        setIsApproved={setIsApproved}
        setAmount={props.setUsnAmount}
        isAmountGtBalance={isAmountInGtMax}
        balance={usnBalance.data || BigInt(0)}
        setIsAmountGtBalance={setIsAmountGtMax}
        allowance={usnAllowance.data || BigInt(0)}
      />

      {isAmountInGtMax && (
        <BalanceError
          header={'Insufficient wallet balance'}
          body={'Add USN to your wallet or use a wallet that already has USN'}
        />
      )}

      <ReceiveInfoAndStats page="sUSN" tokenOut={'sUSN'} amountOut={formattedsUsnAmount} networkFeeAction="stake">
        <ConnectButton
          label={btnLabel}
          onClick={onClickCb}
          isLoading={isLoading}
          useFullAvailableWidth
          isDisabled={isDisabled}
          actionIcon="arrow-right"
          useResponsiveSizes={false}
          actionType="primary-action"
        />
      </ReceiveInfoAndStats>
    </React.Fragment>
  )
}

export default InputAndApproveStake
