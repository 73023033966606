import { useCallback, useContext } from 'react'

import { Config, useConnectorClient } from 'wagmi'

import { estimateMaxPriorityFeePerGas, waitForTransactionReceipt } from '@wagmi/core'

import { eip712WalletActions, getGeneralPaymasterInput } from 'viem/zksync'
import { createWalletClient, custom, parseUnits, zeroAddress } from 'viem'

import SwapRouter from '@uniswap/v3-periphery/artifacts/contracts/SwapRouter.sol/SwapRouter.json'

import { DEFAULT_GAS_PER_PUBDATA_LIMIT } from 'zksync-ethers/build/utils'

import { mainnet } from 'viem/chains'

import { rainbowKitConfig } from '../../../constants/rainbowKitConfig'
import { encodePath, getAmountPostSlippage } from '../../../utils'
import { BuyContext } from '../../../context/BuyProvider'
import { DAPP_CONFIG, sophon } from '../../../constants'
import { ETxnStatus } from '../../../types'

function useBuy(setTxnUrl: (txnHash: string) => void): () => Promise<void> {
  const buy = useContext(BuyContext)
  const connector = useConnectorClient<Config>({ config: rainbowKitConfig })

  const approve = useCallback(async (): Promise<void> => {
    if (
      !buy ||
      !connector ||
      !buy.tokenIn ||
      !buy.tokenOut ||
      !buy.amountIn ||
      !buy.amountOut ||
      !connector.data ||
      !connector.data.chain ||
      !connector.data.account ||
      !connector.data.transport
    ) {
      buy.setBuyTxnStatus(ETxnStatus.TXN_FAILURE)
      return
    }

    const chainId = connector.data.chain.id as 1 | 50104 | 324
    const config = DAPP_CONFIG[chainId]
    if (!chainId || !config) {
      buy.setBuyTxnStatus(ETxnStatus.TXN_FAILURE)
      return
    }

    const _tokenIn = config.tokens[buy.tokenIn]
    if (!_tokenIn) {
      buy.setBuyTxnStatus(ETxnStatus.TXN_FAILURE)
      return
    }

    const _tokenOut = config.tokens[buy.tokenOut]
    if (!_tokenOut) {
      buy.setBuyTxnStatus(ETxnStatus.TXN_FAILURE)
      return
    }
    const _pool =
      config.uniV3Pools[`${buy.tokenIn}-${buy.tokenOut}`] || config.uniV3Pools[`${buy.tokenOut}-${buy.tokenIn}`]
    if (!_pool || !_pool?.path || !_pool?.path?.length || !_pool?.feeForPath || !_pool?.feeForPath?.length) {
      buy.setBuyTxnStatus(ETxnStatus.TXN_FAILURE)
      return
    }

    if (!config.uniV3SwapRouter) {
      buy.setBuyTxnStatus(ETxnStatus.TXN_FAILURE)
      return
    }

    buy.setBuyTxnStatus(ETxnStatus.PROCESSING)

    const route = _pool.path.map((token) => config.tokens[token]?.contractAddress || zeroAddress)
    const fee = _pool.feeForPath
    const path = encodePath(route, fee)

    try {
      let txnHash: string

      if (config.paymaster) {
        const wallet = createWalletClient({
          transport: custom(connector.data.transport),
          chain: connector.data.chain as typeof sophon,
        }).extend(eip712WalletActions())

        txnHash = await wallet.writeContract({
          abi: SwapRouter.abi,
          paymaster: config.paymaster,
          account: connector.data.account,
          address: config.uniV3SwapRouter,
          functionName: 'exactInput',
          gasPerPubdata: BigInt(DEFAULT_GAS_PER_PUBDATA_LIMIT || 0),
          args: [
            {
              path: path,
              recipient: connector.data.account.address,
              deadline: BigInt(Date.now() + 5 * 60 * 1000),
              amountIn: parseUnits(buy.amountIn, _tokenIn.decimals),
              amountOutMinimum: getAmountPostSlippage(parseUnits(buy.amountOut, _tokenOut.decimals)),
            },
          ],
          maxPriorityFeePerGas: await estimateMaxPriorityFeePerGas(rainbowKitConfig, { chainId }),
          paymasterInput: getGeneralPaymasterInput({
            innerInput: '0x',
          }),
        })
      } else {
        const wallet = createWalletClient({
          transport: custom(connector.data.transport),
          chain: connector.data.chain as typeof mainnet,
        })
        txnHash = await wallet.writeContract({
          abi: SwapRouter.abi,
          functionName: 'exactInput',
          account: connector.data.account,
          address: config.uniV3SwapRouter,
          args: [
            {
              path: path,
              recipient: connector.data.account.address,
              deadline: BigInt(Date.now() + 5 * 60 * 1000),
              amountIn: parseUnits(buy.amountIn, _tokenIn.decimals),
              amountOutMinimum: getAmountPostSlippage(parseUnits(buy.amountOut, _tokenOut.decimals)),
            },
          ],
          maxPriorityFeePerGas: await estimateMaxPriorityFeePerGas(rainbowKitConfig, { chainId }),
        })
      }

      buy.setBuyTxnStatus(ETxnStatus.TXN_SUBMITTED_ON_CHAIN)

      const receipt = await waitForTransactionReceipt(rainbowKitConfig, { hash: txnHash as `0x${string}` }).catch(
        () => ({
          status: 'unknown',
        }),
      )
      if (receipt.status === 'success') {
        const url = connector.data.chain.blockExplorers?.default.url
          ? `${connector.data.chain.blockExplorers?.default.url}/tx/${txnHash as string}`
          : '/dashboard'
        setTxnUrl(url)
        buy.setBuyTxnStatus(ETxnStatus.TXN_SUCCESS)
      } else if (receipt.status === 'reverted') {
        buy.setBuyTxnStatus(ETxnStatus.TXN_FAILURE)
      } else {
        buy.setBuyTxnStatus(ETxnStatus.TXN_SUCCESS)
      }
    } catch {
      buy.setBuyTxnStatus(ETxnStatus.TXN_FAILURE)
    }
  }, [buy, connector])

  return approve
}

export default useBuy
