import React, { useContext, useMemo } from 'react'

import { ReactComponent as ArrowRightUpIcon } from '../../assets/navigation/arrow-right-up.svg'
import { ReactComponent as CheckIcon } from '../../assets/success/check-tick.svg'
import { ThemeContext } from '../../context/ThemeProvider'
import { BuyContext } from '../../context/BuyProvider'
import ActionInAndOutInfo from '../ActionInAndOutInfo'
import { formatStringToNumber } from '../../utils'

interface IProps {
  txnUrl: string
}

const BuySuccessful = (props: IProps) => {
  const buy = useContext(BuyContext)
  const theme = useContext(ThemeContext)

  const isActionDisabled = useMemo(
    () => !buy.amountIn || !Number(buy.amountIn) || !buy.amountOut || !Number(buy.amountOut),
    [props],
  )

  const formattedAmountIn = useMemo(
    () => formatStringToNumber(Number(buy.amountIn) ? buy.amountIn : '', true),
    [props, formatStringToNumber],
  )

  const formattedAmountOut = useMemo(
    () => formatStringToNumber(Number(buy.amountOut) ? buy.amountOut : '', true),
    [props, formatStringToNumber],
  )

  return (
    <React.Fragment>
      <div
        className={`w-[72px] h-[72px] rounded-[64px] ${theme.isLightTheme ? 'bg-green-200 text-green-800' : 'bg-green-1000 text-green-200'} p-4`}
      >
        <CheckIcon className="w-full h-full" />
      </div>

      <p
        className={`font-Louize font-normal text-4xl tracking-tighter ${theme.isLightTheme ? 'text-stone-900' : 'text-stone-50'}`}
      >
        You have successfully bought <br /> {buy.tokenOut}
      </p>

      <ActionInAndOutInfo
        labelIn="Swapping"
        labelOut="To get back"
        tokenIn={buy.tokenIn}
        tokenOut={buy.tokenOut}
        amountIn={formattedAmountIn}
        amountOut={formattedAmountOut}
      />

      <div className="w-full flex flex-col md:flex-row gap-4 items-center justify-center">
        <a
          href={isActionDisabled ? '' : props.txnUrl || ''}
          className={`grow w-full md:w-auto flex rounded-[184px] border-[1px] py-3 px-[18px] gap-[6px] ${theme.isLightTheme ? 'bg-stone-100 border-stone-300 ' : 'bg-stone-900 border-stone-800'} items-center justify-center ${isActionDisabled ? 'cursor-not-allowed' : 'cursor-pointer'}`}
        >
          <p
            className={`font-Suisse-Intl font-medium text-base ${theme.isLightTheme ? 'text-stone-900' : 'text-stone-50'}`}
          >
            View Explorer
          </p>
          <div className={`w-6 h-6 text-stone-400`}>
            <ArrowRightUpIcon className="w-full h-full" />
          </div>
        </a>

        <button
          onClick={() => buy.setScreen('input')}
          className="grow w-full md:w-auto rounded-[184px] py-3 px-[18px] gap-[6px] bg-indigo-600 font-Suisse-Intl font-medium text-base text-stone-50"
        >
          Buy More
        </button>
      </div>
    </React.Fragment>
  )
}

export default BuySuccessful
