import React, { useMemo, useState, useEffect, useCallback, useContext } from 'react'

import { mainnet } from 'viem/chains'

import useTokenAllowance from '../../hooks/read/useTokenAllowance'
import useTokenBalance from '../../hooks/read/useTokenBalance'
import { ThemeContext } from '../../context/ThemeProvider'
import { ETxnStatus, TRedeemScreen } from '../../types'
import useApprove from '../../hooks/write/useApprove'
import { DAPP_CONFIG } from '../../constants'
import ConnectButton from '../ConnectButton'
import BalanceError from '../BalanceError'
import ActionStats from '../ActionStats'
import Input from '../Input'

interface IProps {
  usnAmount: string
  selectedCollateral: string
  setUsnAmount: (value: string) => void
  setScreen: (screen: TRedeemScreen) => void
  selectCollateral: (colateral: string) => void
}

const InputAndApproveRedeem = (props: IProps) => {
  const [btnLabel, setBtnLabel] = useState<string>('Approve')
  const [isApproved, setIsApproved] = useState<boolean>(false)
  const [isAmountInGtMax, setIsAmountGtMax] = useState<boolean>(false)
  const [txnStatus, setTxnStatus] = useState<ETxnStatus>(ETxnStatus.IDLE)

  const theme = useContext(ThemeContext)
  const usnBalance = useTokenBalance('USN')
  const usnAllowance = useTokenAllowance('USN', DAPP_CONFIG[mainnet.id].redeemHandler)

  const isLoading = useMemo(
    () =>
      usnBalance.isFetching ||
      usnAllowance.isFetching ||
      txnStatus === ETxnStatus.PROCESSING ||
      txnStatus === ETxnStatus.TXN_SUBMITTED_ON_CHAIN,
    [usnBalance, usnAllowance, txnStatus],
  )

  const isDisabled = useMemo(
    () => isLoading || isAmountInGtMax || !props.usnAmount || !Number(props.usnAmount),
    [props, isLoading, isAmountInGtMax],
  )

  const onApproveCb = useApprove(
    'USN',
    DAPP_CONFIG[mainnet.id].redeemHandler,
    setBtnLabel,
    setTxnStatus,
    props.setScreen,
  )

  const onClickCb = useCallback(() => {
    if (isApproved) {
      props.setScreen('confirm')
      return
    }
    onApproveCb()
  }, [isApproved, onApproveCb, props.setScreen])

  useEffect(() => {
    if (isApproved) {
      setBtnLabel('Next')
      return
    }
    setBtnLabel('Approve')
  }, [isApproved])

  return (
    <div className="w-full max-w-full flex flex-col items-start justify-start gap-6">
      <div className="w-full max-w-full flex flex-col items-start justify-start gap-2">
        <Input
          tokens={[]}
          token={'USN'}
          setToken={() => {}}
          label={'REDEEM AMOUNT'}
          amount={props.usnAmount}
          setScreen={props.setScreen}
          setIsApproved={setIsApproved}
          setAmount={props.setUsnAmount}
          isAmountGtBalance={isAmountInGtMax}
          balance={usnBalance.data || BigInt(0)}
          setIsAmountGtBalance={setIsAmountGtMax}
          allowance={usnAllowance.data || BigInt(0)}
        />

        <Input
          isDisabled={true}
          hideBalance={true}
          balance={BigInt(0)}
          setAmount={() => {}}
          allowance={BigInt(0)}
          setIsApproved={() => {}}
          amount={props.usnAmount}
          label={'YOU WILL RECEIVE'}
          setScreen={props.setScreen}
          isAmountGtBalance={false}
          setIsAmountGtBalance={() => {}}
          token={props.selectedCollateral}
          setToken={props.selectCollateral}
          classes="bg-transparent border-none"
          tokens={DAPP_CONFIG[mainnet.id].tokensForMint}
        />
      </div>

      {isAmountInGtMax && (
        <BalanceError
          header={'Insufficient wallet balance'}
          body={`Add USN to your wallet or use a wallet that already has USN`}
        />
      )}

      <div className="w-full max-w-full px-4 py-0 m-0 flex flex-col items-start justify-start gap-6">
        <hr className={`w-full block border-[1px] ${theme.isLightTheme ? 'border-stone-200' : 'border-stone-800'}`} />
        <ActionStats page={'USN'} networkFeeAction="mint" quoteToken={props.selectedCollateral} />
        <ConnectButton
          label={btnLabel}
          onClick={onClickCb}
          isLoading={isLoading}
          useFullAvailableWidth
          isDisabled={isDisabled}
          actionIcon="arrow-right"
          useResponsiveSizes={false}
          actionType="primary-action"
        />
      </div>
    </div>
  )
}

export default InputAndApproveRedeem
