/* eslint-disable react/react-in-jsx-scope */
import { useContext, useEffect, useMemo, useState } from 'react'
import { useChainId } from 'wagmi'

import moment from 'moment'

import { ReactComponent as ChevronDownIcon } from '../../assets/navigation/chevron-big-down.svg'
import { ReactComponent as ChevronUpIcon } from '../../assets/navigation/chevron-big-up.svg'
import { formatBigNumberCompact, formatStringToNumber } from '../../utils'
import { rainbowKitConfig } from '../../constants/rainbowKitConfig'
import { ThemeContext } from '../../context/ThemeProvider'
import ProtocolInfoChart from './ProtocolInfoChart'
import { TProtocolMetrics } from '../../types'

interface IProps {
  page: 'USN' | 'sUSN'
}

function ProtocolInfo(props: IProps) {
  const [tvlVisible, setTVLVisible] = useState<boolean>(true)
  const [chartVisible, setChartVisible] = useState<boolean>(true)
  const [protocolInfoTimeFrame, setProtocolInfoTimeFrame] = useState<'24H' | '7D' | '30D' | 'ALL TIME'>('7D')
  const [protocolMetrics, setProtocolMetrics] = useState<TProtocolMetrics>({
    tvl: '0',
    usnAmount: '0',
    susnAmount: '0',
    usnPercentage: '0',
    apyTimeSeries: {},
    susnPercentage: '0',
  })

  const theme = useContext(ThemeContext)
  const chainId = useChainId({ config: rainbowKitConfig })

  const fetchProtocolMetrics = async () => {
    await fetch(`${process.env.REACT_APP_BASE_API_URL ?? ''}/api/v1/protocol-metrics`)
      .then(
        (res) => {
          if (res.ok) {
            return res.json()
          }

          throw new Error('Failed to fetch protocol metrics')
        },
        (err) => {
          throw new Error(err)
        },
      )
      .then((json) => json as TProtocolMetrics)
      .then((metrics) => setProtocolMetrics(metrics))
      .catch((err) => {
        console.error('Error fetching metrics', err)
        return
      })
  }

  useEffect(() => {
    fetchProtocolMetrics()
  }, [chainId])

  const formattedTVL = useMemo(() => {
    return formatBigNumberCompact(BigInt(protocolMetrics.tvl || '0'), 18)
  }, [protocolMetrics])

  const apyDataSorted = useMemo(() => {
    const data = Object.keys(protocolMetrics?.apyTimeSeries ?? {}).map((key: string) => ({
      label: key,
      value: protocolMetrics.apyTimeSeries[key] ? Number(protocolMetrics.apyTimeSeries[key]) : 0,
    }))

    const sortedData = data.sort((a, b) => {
      const aTime = new Date(a.label).getTime()
      const bTime = new Date(b.label).getTime()
      return aTime - bTime
    })

    return sortedData
  }, [protocolMetrics])

  const apyData = useMemo(() => {
    if (protocolInfoTimeFrame === '7D') {
      return apyDataSorted.slice(-7)
    }
    return apyDataSorted
  }, [protocolInfoTimeFrame, apyDataSorted])

  const formattedApyPrevWeek = useMemo(() => {
    const lastValue = apyData.at(-1) || { value: '0' }
    return formatStringToNumber(lastValue?.value?.toString() ?? '0', true)
  }, [apyData])

  const formattedUSNPercentage = useMemo(() => {
    return formatStringToNumber(protocolMetrics.usnPercentage ?? '0', true)
  }, [protocolMetrics.usnPercentage])

  const formattedsUSNPercentage = useMemo(() => {
    return formatStringToNumber(protocolMetrics.susnPercentage ?? '0', true)
  }, [protocolMetrics.susnPercentage])

  const toggleTVLVisibility = () => {
    setTVLVisible((state) => !state)
  }

  const toggleChartVisibility = () => {
    setChartVisible((state) => !state)
  }

  const { minAPR, maxAPR } = useMemo(() => {
    if (apyData.length === 0) {
      return { minAPR: 0, maxAPR: 10 }
    }

    const values = apyData.map((d) => Number(d.value))

    return {
      minAPR: 0,
      maxAPR: Math.ceil(Math.max(...values)),
    }
  }, [apyData])

  const startDate = useMemo(() => {
    const dateString = apyData.at(0)?.label
    if (!dateString) return ''
    return moment(new Date(dateString)).format('DD MMM YYYY')
  }, [apyData])

  const endDate = useMemo(() => {
    const dateString = apyData.at(-1)?.label
    if (!dateString) return ''
    return moment(new Date(dateString)).format('DD MMM YYYY')
  }, [apyData])

  return (
    <div className="w-full md:w-[90%] max-w-full flex flex-col gap-8">
      <p
        className={`w-full font-normal font-Louize text-[56px] leading-[68px] ${theme.isLightTheme ? 'text-stone-600' : 'text-stone-50'} tracking-tighter`}
      >
        {props.page}
      </p>
      <div className="w-full flex flex-col gap-4">
        <div className="w-full flex flex-col gap-3">
          <div className="w-full flex gap-3 items-center justify-between">
            <p className="font-[450] text-stone-500 text-sm leading-5 text-left">TOTAL VALUE LOCKED</p>

            <button className="w-6 h-6" onClick={toggleTVLVisibility}>
              {tvlVisible ? (
                <div className={`w-6 h-6 ${theme.isLightTheme ? 'text-stone-400' : 'text-stone-500'}`}>
                  <ChevronUpIcon className="w-full h-full" />
                </div>
              ) : (
                <div className={`w-6 h-6 ${theme.isLightTheme ? 'text-stone-400' : 'text-stone-500'}`}>
                  <ChevronDownIcon className="w-full h-full" />
                </div>
              )}
            </button>
          </div>
          <p
            className={`font-Suisse-Intl font-semibold text-2xl ${theme.isLightTheme ? 'text-stone-900' : 'text-stone-50'}`}
          >
            ${formattedTVL}
          </p>
        </div>

        <div className={`${tvlVisible ? 'flex' : 'hidden'} w-full h-[14px] gap-2`}>
          <div
            className={`h-full bg-orange-600 rounded-[48px] w-[${(protocolMetrics.usnPercentage.toString() ?? '49') + '%'}]`}
            style={{ width: (protocolMetrics.usnPercentage.toString() ?? '49') + '%' }}
          ></div>
          <div
            className={`h-full bg-stone-400 rounded-[48px] w-[${(protocolMetrics.susnPercentage.toString() ?? '49') + '%'}]`}
            style={{ width: (protocolMetrics.susnPercentage.toString() ?? '49') + '%' }}
          ></div>
        </div>

        <div className={`${tvlVisible ? 'flex' : 'hidden'} h-[14px] gap-2 items-center justify-start`}>
          <div className="h-full w-[5%] bg-orange-600 rounded-[48px]"></div>
          <p className="font-[450] font-Suisse-Intl leading-[19px] text-stone-500 text-lg w-fit">USN</p>
          <p className="font-[450] font-Suisse-Intl leading-[19px] text-stone-500 text-lg w-fit">•</p>
          <p className="font-[450] font-Suisse-Intl leading-[19px] text-stone-500 text-lg w-fit">
            {formattedUSNPercentage}%
          </p>
          <div className="h-full w-[5%] bg-stone-400 rounded-[48px]"></div>
          <p className="font-[450] font-Suisse-Intl leading-[19px] text-stone-500 text-lg w-fit">sUSN</p>
          <p className="font-[450] font-Suisse-Intl leading-[19px] text-stone-500 text-lg w-fit">•</p>
          <p className="font-[450] font-Suisse-Intl leading-[19px] text-stone-500 text-lg w-fit">
            {formattedsUSNPercentage}%
          </p>
        </div>
      </div>

      <div className="w-full flex flex-col gap-6">
        <div className="w-full flex flex-col gap-3">
          <div className="w-full flex gap-3 items-center justify-between">
            <p className="grow font-[450] font-Suisse-Intl leading-5 text-stone-500 text-sm">
              sUSN APY, {protocolInfoTimeFrame} Average
            </p>
            <div className="w-fit flex items-center justify-end rounded-lg gap-[2px] p-[2px] justify-self-end">
              <button
                onClick={() => setProtocolInfoTimeFrame('7D')}
                className={`rounded-lg py-1 px-2 font-Suisse-Intl font-medium text-sm ${
                  theme.isLightTheme
                    ? protocolInfoTimeFrame === '7D'
                      ? 'text-stone-900  bg-stone-200'
                      : 'text-stone-500 bg-transaparent'
                    : protocolInfoTimeFrame === '7D'
                      ? 'text-stone-50  bg-stone-700'
                      : 'text-stone-500 bg-transaparent'
                }`}
              >
                7D
              </button>
              <button
                onClick={() => setProtocolInfoTimeFrame('30D')}
                className={`rounded-lg py-1 px-2 font-Suisse-Intl font-medium text-sm ${
                  theme.isLightTheme
                    ? protocolInfoTimeFrame === '30D'
                      ? 'text-stone-900  bg-stone-200'
                      : 'text-stone-500 bg-transaparent'
                    : protocolInfoTimeFrame === '30D'
                      ? 'text-stone-50  bg-stone-700'
                      : 'text-stone-500 bg-transaparent'
                }`}
              >
                30D
              </button>
            </div>
            <button className="w-6 h-6 justify-self-end" onClick={toggleChartVisibility}>
              {chartVisible ? (
                <div className={`w-6 h-6 ${theme.isLightTheme ? 'text-stone-400' : 'text-stone-500'}`}>
                  <ChevronUpIcon className="w-full h-full" />
                </div>
              ) : (
                <div className={`w-6 h-6 ${theme.isLightTheme ? 'text-stone-400' : 'text-stone-500'}`}>
                  <ChevronDownIcon className="w-full h-full" />
                </div>
              )}
            </button>
          </div>
          <p
            className={`font-Suisse-Intl font-semibold text-2xl ${theme.isLightTheme ? 'text-stone-900' : 'text-stone-50'}`}
          >
            {formattedApyPrevWeek}%
          </p>
        </div>

        <div
          className={`${chartVisible ? 'block' : 'hidden'} w-full flex flex-col items-start justify-center gap-2 p-0 m-0`}
        >
          <div
            className={`${chartVisible ? 'block' : 'hidden'} w-full rounded-lg border-[1px] ${theme.isLightTheme ? 'border-stone-300' : 'border-stone-800'}`}
          >
            <p className="w-fit font-Suisse-Intl font-[450] p-2 text-sm leading-5 text-right text-stone-400">
              {maxAPR}%
            </p>
            <div className="w-full h-32" id="staking-info-chart">
              <ProtocolInfoChart data={apyData} />
            </div>
            <p className="w-fit font-Suisse-Intl font-[450] p-2 text-sm leading-5 text-right text-stone-400">
              {minAPR}%
            </p>
          </div>
          <div className="w-full p-2 gap-2 flex items-center justify-between ">
            <p
              className={`font-Suisse-Intl font-[450] text-sm ${theme.isLightTheme ? 'text-stone-500' : 'text-stone-400'}`}
            >
              {startDate}
            </p>
            <p
              className={`font-Suisse-Intl font-[450] text-sm ${theme.isLightTheme ? 'text-stone-500' : 'text-stone-400'}`}
            >
              {endDate}
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProtocolInfo
