import React, { useContext } from 'react'

import { ReactComponent as ArrowLeftIcon } from '../../assets/navigation/arrow-left.svg'
import { ThemeContext } from '../../context/ThemeProvider'
import { TScreen } from '../../types'

interface IProps {
  setScreen: (screen: TScreen) => void
}

const ActionCardBackButton = (props: IProps) => {
  const theme = useContext(ThemeContext)

  return (
    <button
      className="w-fit flex items-center justify-start rounded-[4px] gap-1"
      onClick={() => props.setScreen('input')}
    >
      <div className={`w-6 h-6 ${theme.isLightTheme ? 'text-stone-400' : 'text-stone-600'}`}>
        <ArrowLeftIcon className="w-full h-full" />
      </div>
      <p className="font-Suisse-Intl font-[450] text-base text-stone-500">BACK</p>
    </button>
  )
}

export default ActionCardBackButton
