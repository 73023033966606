import { useCallback, useMemo } from 'react'

import { useAccount, useChainId, useWriteContract } from 'wagmi'

import { maxUint256 } from 'viem'

import { mainnet } from 'viem/chains'

import { onErrorFeedback, onSuccessFeedback, onSettledFeedback } from '../../utils'
import { rainbowKitConfig } from '../../constants/rainbowKitConfig'
import { ETxnStatus, TAddress, TScreen } from '../../types'
import ERC20Abi from '../../constants/abis/ERC20.json'
import { DAPP_CONFIG } from '../../constants'

function useApprove(
  token: string,
  spender: TAddress | undefined,
  setBtnLabel: (label: string) => void,
  setTxnStatus: (status: ETxnStatus) => void,
  setScreen: (screen: TScreen) => void,
): () => Promise<void> {
  const chainId = useChainId({ config: rainbowKitConfig })
  const account = useAccount({ config: rainbowKitConfig })
  const { writeContract: onWriteWithWagmi } = useWriteContract({ config: rainbowKitConfig })

  const onTxnFail = useMemo(
    () => () => {
      onErrorFeedback('Approve', setBtnLabel, setTxnStatus)
    },
    [setBtnLabel, setTxnStatus],
  )

  const onTxnSubmit = useMemo(
    () => () => onSuccessFeedback('Approving...', setBtnLabel, setTxnStatus),
    [setBtnLabel, setTxnStatus],
  )

  const onTxnSuccessOrRevert = useMemo(
    () => async (txnHash: unknown) =>
      onSettledFeedback(txnHash, 'Approve', 'Approved', setBtnLabel, setTxnStatus, 'confirm', setScreen),
    [setScreen, setBtnLabel, setTxnStatus],
  )

  return useCallback(async () => {
    setBtnLabel('Approving...')
    setTxnStatus(ETxnStatus.PROCESSING)

    if (
      chainId !== mainnet.id ||
      !account.isConnected ||
      !spender ||
      !token ||
      !DAPP_CONFIG[mainnet.id].tokens[token]
    ) {
      onTxnFail()
      return
    }

    onWriteWithWagmi(
      {
        abi: ERC20Abi,
        chain: mainnet,
        chainId: mainnet.id,
        functionName: 'approve',
        args: [spender, maxUint256],
        address: DAPP_CONFIG[mainnet.id].tokens[token].contractAddress,
      },
      {
        onError: onTxnFail,
        onSuccess: onTxnSubmit,
        onSettled: onTxnSuccessOrRevert,
      },
    )
  }, [chainId, token, spender, account, onTxnSubmit, onTxnFail, onTxnSuccessOrRevert])
}

export default useApprove
