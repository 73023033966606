import React, { useContext } from 'react'

import { ThemeContext } from '../../context/ThemeProvider'

const WrongNetworkToolTip = () => {
  const theme = useContext(ThemeContext)

  return (
    <>
      <p
        className={`outline-none font-Suisse-Intl font-[450] text-base ${theme.isLightTheme ? 'text-stone-950' : 'text-stone-50'}`}
      >
        Only available on Ethereum.
      </p>
    </>
  )
}

export default WrongNetworkToolTip
