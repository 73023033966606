import { useQuery } from '@tanstack/react-query'

import { useAccount } from 'wagmi'

import { rainbowKitConfig } from '../constants/rainbowKitConfig'
import { TUserActivity, TUserRecentTxns } from '../types'
import { REFETCH_INTERVAL } from '../utils'

const useRecentTxns = () => {
  const account = useAccount({ config: rainbowKitConfig })

  const fetcher = async () => {
    return await fetch(`${process.env.REACT_APP_BASE_API_URL ?? ''}/api/v1/user/${account.address}`)
      .then(
        (res) => {
          if (res.ok) {
            return res.json()
          }

          throw new Error('Failed to fetch user activity data')
        },
        (err) => {
          throw new Error(err)
        },
      )
      .then((json) => json)
      .then((userActivityData) => (userActivityData ?? {}) as TUserActivity)
      .then((activity) => (activity?.recentTransactions || []) as TUserRecentTxns[])
      .then()
      .catch(() => {
        return [] as TUserRecentTxns[]
      })
  }

  return useQuery({
    enabled: true,
    queryFn: fetcher,
    refetchOnMount: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: true,
    refetchInterval: REFETCH_INTERVAL,
    refetchIntervalInBackground: false,
    placeholderData: [] as TUserRecentTxns[],
    initialData: () => [] as TUserRecentTxns[],
    queryKey: ['recent', 'txn', account?.isConnected, account?.address],
    select: (data) => {
      return data && data?.length ? (data as TUserRecentTxns[]) : ([] as TUserRecentTxns[])
    },
  })
}

export default useRecentTxns
