import React, { useContext, useMemo } from 'react'
import { NavLink } from 'react-router-dom'

import { ReactComponent as ArrowRightIcon } from '../../assets/navigation/arrow-right.svg'
import { ReactComponent as CheckIcon } from '../../assets/success/check-tick.svg'
import { ReactComponent as InfoIcon } from '../../assets/info/info-circle.svg'
import mintSuccessIconGroup from '../../assets/success/success-group.svg'
import { ThemeContext } from '../../context/ThemeProvider'
import { formatStringToNumber } from '../../utils'
import USN from '../../assets/tokens/USN.svg'
import ConnectButton from '../ConnectButton'
import { TMintScreen } from '../../types'

interface IProps {
  collateralAmount: string
  setScreen: (screen: TMintScreen) => void
}

const MintSuccessful = (props: IProps) => {
  const theme = useContext(ThemeContext)

  const formattedUsnAmount = useMemo(
    () => formatStringToNumber(props.collateralAmount || '0', true),
    [props.collateralAmount],
  )

  return (
    <React.Fragment>
      <div
        className={`w-[72px] h-[72px] rounded-[64px] ${theme.isLightTheme ? 'bg-green-200 text-green-800' : 'bg-green-1000 text-green-200'} p-4`}
      >
        <CheckIcon className="w-full h-full" />
      </div>

      <p
        className={`font-Louize font-normal text-4xl tracking-tighter ${theme.isLightTheme ? 'text-stone-900' : 'text-stone-50'}`}
      >
        <span>You have successfully requested to mint</span>&nbsp;
        <img src={USN} className="w-10 h-10 inline" alt="USN" />
        &nbsp;
        <span>{formattedUsnAmount} USN</span>
      </p>

      <div
        className={`w-full flex items-start justify-between rounded-[10px] p-3 gap-2 ${theme.isLightTheme ? 'bg-stone-100' : 'bg-stone-900'}`}
      >
        <div className={`w-6 h-6 shrink-0 ${theme.isLightTheme ? 'text-stone-900' : 'text-stone-200'}`}>
          <InfoIcon className="w-full h-full" />
        </div>
        <div className="flex flex-col items-start justify-center gap-[2px] shrink">
          <p
            className={`font-Suisse-Intl font-[450] text-base ${theme.isLightTheme ? 'text-stone-900' : 'text-stone-200'} text-wrap`}
          >
            Your USN is in the minting queue
          </p>
          <p className="font-Suisse-Intl font-[450] text-sm text-stone-500 text-wrap text-left">
            We&apos;re currently reviewing your minting request, including conducting AML / KYC checks, etc. Once our
            review is completed successfully, your USN will be minted and deposited into your wallet. This process
            should be completed within 24 hours.
          </p>
        </div>
      </div>

      <div
        className={`relative w-full rounded-lg ${theme.isLightTheme ? 'bg-stone-100' : 'bg-stone-900'} h-44 w-ful flex flex-col gap-2 py-[14px] px-4 items-start justify-between overflow-hidden`}
      >
        <div className="absolute -right-5 -bottom-12 md:-right-2 md:-bottom-10 p-0 m-0 max-w-[46%] h-full max-h-full">
          <img src={mintSuccessIconGroup} alt="Mint success" className="w-full h-full p-0 m-0" />
        </div>
        <p className="min-h-fit font-Suisse-Intl font-medium text-2xl text-stone-500 text-wrap">
          Stake your USN to <br /> earn extra rewards
        </p>
        <div className="w-fit flex items-center justify-center p-2">
          <NavLink
            to="/sUsn"
            className={`font-Suisse-Intl font-medium text-base ${theme.isLightTheme ? 'text-stone-900' : 'text-stone-50'}`}
          >
            Stake Now
          </NavLink>
          <div className={`w-6 h-6 ${theme.isLightTheme ? 'text-stone-400' : 'text-stone-300'}`}>
            <ArrowRightIcon className="w-full h-full" />
          </div>
        </div>
      </div>

      <ConnectButton
        showArrow={false}
        useFullAvailableWidth
        label={'Mint More USN'}
        useResponsiveSizes={false}
        actionType="primary-action"
        onClick={() => props.setScreen('input')}
      />
    </React.Fragment>
  )
}

export default MintSuccessful
