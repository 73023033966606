/* eslint-disable react/react-in-jsx-scope */
import { Link } from 'react-router-dom'

import { useContext } from 'react'

import telegramLogo from '../../assets/socials/telegram.svg'
import linkedinLogo from '../../assets/socials/linkedin.svg'
import { ThemeContext } from '../../context/ThemeProvider'
import mirrorLogo from '../../assets/socials/mirror.svg'
import xLogo from '../../assets/socials/x.svg'

const Footer = () => {
  const theme = useContext(ThemeContext)

  return (
    <>
      <div className="hidden md:flex py-4 px-9 gap-12 items-center justify-between">
        <section
          className={`font-normal font-Inter text-sm ${theme.isLightTheme ? 'text-stone-500' : 'text-stone-400'} leading-5 w-[32%] max-w-[32%]`}
        >
          © 2025 Noon HoldCo. All rights reserved.
        </section>
        <section className="flex gap-7 items-center justify-center justify-self-center">
          <Link
            target="_blank"
            rel="noreferrer"
            to={`mailto:support@noon.capital`}
            className={`cursor-pointer outline-none font-normal font-Inter leading-5 text-sm ${theme.isLightTheme ? 'text-stone-500' : 'text-stone-400'}`}
          >
            Contact
          </Link>
          <Link
            target="_blank"
            rel="noreferrer"
            to="https://www.noon.capital/terms"
            className={`cursor-pointer outline-none font-normal font-Inter leading-5 text-sm ${theme.isLightTheme ? 'text-stone-500' : 'text-stone-400'}`}
          >
            Terms and Conditions
          </Link>
          <Link
            target="_blank"
            rel="noreferrer"
            to="https://www.noon.capital/privacy"
            className={`cursor-pointer outline-none font-normal font-Inter leading-5 text-sm ${theme.isLightTheme ? 'text-stone-500' : 'text-stone-400'}`}
          >
            Privacy Policy
          </Link>
        </section>
        <section className="flex w-[32%] max-w-[32%] gap-4 items-center justify-end">
          <Link
            target="_blank"
            rel="noreferrer"
            to="https://t.me/nooncapital"
            className={`cursor-pointer outline-none h-6 w-6 ${theme.isLightTheme ? 'text-stone-500' : 'text-stone-400'}`}
          >
            <img src={telegramLogo} className="h-full w-full" alt="Telegram" />
          </Link>
          <Link
            target="_blank"
            rel="noreferrer"
            to="https://x.com/noon_capital"
            className={`cursor-pointer outline-none h-6 w-6 ${theme.isLightTheme ? 'text-stone-500' : 'text-stone-400'}`}
          >
            <img src={xLogo} className="h-full w-full" alt="X" />
          </Link>
          <Link
            target="_blank"
            rel="noreferrer"
            to="https://www.linkedin.com/company/nooncapital/"
            className={`cursor-pointer outline-none h-6 w-6 ${theme.isLightTheme ? 'text-stone-500' : 'text-stone-400'}`}
          >
            <img src={linkedinLogo} className="h-full w-full" alt="Linkedin" />
          </Link>
          <Link
            target="_blank"
            rel="noreferrer"
            to="https://mirror.xyz/nooncapital.eth"
            className={`cursor-pointer outline-none h-6 w-6 ${theme.isLightTheme ? 'text-stone-500' : 'text-stone-400'}`}
          >
            <img src={mirrorLogo} className="h-full w-full" alt="Mirror" />
          </Link>
        </section>
      </div>

      <div className="flex flex-col md:hidden p-4 gap-6 items-start justify-between">
        <section
          className={`font-normal font-Inter text-sm ${theme.isLightTheme ? 'text-stone-500' : 'text-stone-400'} leading-5 w-full`}
        >
          © 2025 Noon Capital. All rights reserved.
        </section>
        <section className="flex gap-7 items-center justify-between justify-self-center">
          <Link
            target="_blank"
            rel="noreferrer"
            to={`mailto:support@noon.capital`}
            className={`cursor-pointer outline-none font-normal font-Inter leading-5 text-sm ${theme.isLightTheme ? 'text-stone-500' : 'text-stone-400'}`}
          >
            Contact
          </Link>
          <Link
            target="_blank"
            rel="noreferrer"
            to="https://www.noon.capital/terms"
            className={`cursor-pointer outline-none font-normal font-Inter leading-5 text-sm ${theme.isLightTheme ? 'text-stone-500' : 'text-stone-400'}`}
          >
            Terms and Conditions
          </Link>
          <Link
            target="_blank"
            rel="noreferrer"
            to="https://www.noon.capital/privacy"
            className={`cursor-pointer outline-none font-normal font-Inter leading-5 text-sm ${theme.isLightTheme ? 'text-stone-500' : 'text-stone-400'}`}
          >
            Privacy Policy
          </Link>
        </section>
        <section className="flex w-full gap-4 items-center justify-start">
          <Link
            target="_blank"
            rel="noreferrer"
            to="https://t.me/nooncapital"
            className={`cursor-pointer outline-none h-6 w-6 ${theme.isLightTheme ? 'text-stone-500' : 'text-stone-400'}`}
          >
            <img src={telegramLogo} className="h-full w-full" alt="Telegram" />
          </Link>
          <Link
            target="_blank"
            rel="noreferrer"
            to="https://x.com/noon_capital"
            className={`cursor-pointer outline-none h-6 w-6 ${theme.isLightTheme ? 'text-stone-500' : 'text-stone-400'}`}
          >
            <img src={xLogo} className="h-full w-full" alt="X" />
          </Link>
          <Link
            target="_blank"
            rel="noreferrer"
            to="https://www.linkedin.com/company/nooncapital/"
            className={`cursor-pointer outline-none h-6 w-6 ${theme.isLightTheme ? 'text-stone-500' : 'text-stone-400'}`}
          >
            <img src={linkedinLogo} className="h-full w-full" alt="Linkedin" />
          </Link>
          <Link
            target="_blank"
            rel="noreferrer"
            to="https://mirror.xyz/nooncapital.eth"
            className={`cursor-pointer outline-none h-6 w-6 ${theme.isLightTheme ? 'text-stone-500' : 'text-stone-400'}`}
          >
            <img src={mirrorLogo} className="h-full w-full" alt="Mirror" />
          </Link>
        </section>
      </div>
    </>
  )
}

export default Footer
