import React from 'react'

import MintRequestsCarouselContent from './MintRequestsCarouselContent'
import { Carousel } from '../ui/Carousel'
import { TMintReq } from '../../types'

interface IProps {
  pendingMintRequests: TMintReq[]
  setMintRequestInfo: (mintRequestInfo: TMintReq) => void
  openMintRequstInfoModal: (mintRequestInfo: TMintReq) => void
}

export const MintRequestsCarousel = (props: IProps) => {
  return (
    <Carousel className={'w-full max-w-full'}>
      <MintRequestsCarouselContent {...props} />
    </Carousel>
  )
}

export default MintRequestsCarousel
