import React, { useCallback, useContext, useMemo, useState } from 'react'
import { useAccount, useSignTypedData } from 'wagmi'
import { parseUnits } from 'viem'

import { mainnet } from 'viem/chains'

import { useQueryClient } from '@tanstack/react-query'

import { formatStringToNumber, onApiErrorFeedback, onApiSuccessFeedbackAction } from '../../utils'
import { EApiCallStatus, TMintScreen, TMintOrder, TScreen } from '../../types'
import { rainbowKitConfig } from '../../constants/rainbowKitConfig'
import { ThemeContext } from '../../context/ThemeProvider'
import ActionInAndOutInfo from '../ActionInAndOutInfo'
import { DAPP_CONFIG } from '../../constants'
import ConnectButton from '../ConnectButton'
import ActionStats from '../ActionStats'

interface IProps {
  collateralAmount: string
  selectedCollateral: string
  setScreen: (screen: TMintScreen) => void
}

const MintConfirm = (props: IProps) => {
  const [btnLabel, setBtnLabel] = useState<string>('Confirm')
  const [txnStatus, setTxnStatus] = useState<EApiCallStatus>(EApiCallStatus.IDLE)

  const queryClient = useQueryClient()
  const theme = useContext(ThemeContext)
  const account = useAccount({ config: rainbowKitConfig })
  const { signTypedData } = useSignTypedData({ config: rainbowKitConfig })

  const formattedUsnAmount = useMemo(
    () => formatStringToNumber(props.collateralAmount || '0', true),
    [props.collateralAmount],
  )

  const onApiOrSignatureError = useMemo(() => {
    return () => onApiErrorFeedback('Confirm', setBtnLabel, setTxnStatus)
  }, [setBtnLabel, setTxnStatus])

  const onApiSuccess = useMemo(() => {
    return () =>
      onApiSuccessFeedbackAction('Confirmed', setBtnLabel, setTxnStatus, 'success', (screen: TScreen) => {
        queryClient.refetchQueries({
          queryKey: ['api', 'pending', 'requests', account.isConnected, account.address],
        })
        props.setScreen(screen)
      })
  }, [queryClient, props.setScreen, setBtnLabel, setTxnStatus])

  const isButtonDisabled = useMemo(
    () => !account.isConnected || !account.address || !props.collateralAmount || !Number(props.collateralAmount),
    [account.isConnected, account.address, props.collateralAmount],
  )

  const sendMintReq = useCallback(
    async (order: TMintOrder, signature: string) => {
      await fetch(`${process.env.REACT_APP_BASE_API_URL ?? ''}/api/mint-usn`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...order,
          signature,
          usnAmount: parseUnits(props.collateralAmount || '0', 18).toString(),
          collateralAmount: parseUnits(
            props.collateralAmount || '0',
            DAPP_CONFIG[mainnet.id].tokens[props.selectedCollateral].decimals,
          ).toString(),
        }),
      })
        .then(
          (res) => {
            if (res.ok && res.status === 200) {
              onApiSuccess()
              return
            }
            throw new Error('Error')
          },
          (err) => {
            throw new Error('Error' + err?.message)
          },
        )
        .catch((err: Error) => {
          console.error('Error minting from MINT API:', err)
          onApiOrSignatureError()
        })
    },
    [props],
  )

  const signMintData = useCallback(() => {
    setTxnStatus(EApiCallStatus.PROCESSING)
    setBtnLabel('Confirming...')

    const domain = {
      name: 'MinterHandler',
      version: '1',
      chainId: account.chainId,
      verifyingContract: DAPP_CONFIG[mainnet.id].mintHandler as `0x${string}`,
    }

    const types = {
      Order: [
        { name: 'message', type: 'string' },
        { name: 'user', type: 'address' },
        { name: 'collateralAddress', type: 'address' },
        { name: 'collateralAmount', type: 'uint256' },
        { name: 'usnAmount', type: 'uint256' },
        { name: 'expiry', type: 'uint256' },
        { name: 'nonce', type: 'uint256' },
      ],
    }

    const order: TMintOrder = {
      message: `I, the undersigned, hereby agree to mint ${props.collateralAmount} USN against ${props.collateralAmount} ${props.selectedCollateral}.

      I acknowledge that:

1. This transaction is irreversible once confirmed.
2. I am subject to the protocol's terms and conditions.
3. The value of USN and ${props.selectedCollateral} may fluctuate.
4. I assume all risks associated with this transaction.
5. Neither the developers nor the protocol shall be held liable for any losses incurred.
6. I am responsible for ensuring compliance with all applicable laws and regulations.

By proceeding, I confirm that I have read, understood, and agree to the above terms.`,
      user: account.address as `${'0x'}string`,
      collateralAmount: parseUnits(
        props.collateralAmount ?? '0',
        DAPP_CONFIG[mainnet.id].tokens[props.selectedCollateral].decimals,
      ),
      usnAmount: parseUnits(props.collateralAmount ?? '0', 18),
      nonce: Math.floor(Math.random() * 100000000000),
      expiry: Math.floor(Date.now() / 1000) + 24 * 60 * 60, // Current date in sec + 24 hr in sec.
      collateralAddress: DAPP_CONFIG[mainnet.id].tokens[props.selectedCollateral].contractAddress as `${'0x'}string`,
    }

    signTypedData(
      {
        domain,
        types,
        message: order,
        primaryType: 'Order',
      },
      {
        onError: onApiOrSignatureError,
        onSuccess: (data) => {
          setBtnLabel('Confirming...')
          sendMintReq(order, data)
        },
      },
    )
  }, [props, account, sendMintReq])

  return (
    <React.Fragment>
      <p
        className={`font-Louize font-normal text-4xl tracking-tighter ${theme.isLightTheme ? 'text-stone-900' : 'text-stone-50'}`}
      >
        Confirm your USN minting <br /> transaction
      </p>

      <ActionInAndOutInfo
        tokenIn={props.selectedCollateral}
        tokenOut={'USN'}
        labelIn="Minting"
        labelOut="To get back"
        amountIn={formattedUsnAmount}
        amountOut={formattedUsnAmount}
      />
      <ActionStats page="USN" quoteToken={props.selectedCollateral} networkFeeAction={'mint'} />
      <ConnectButton
        label={btnLabel}
        useFullAvailableWidth
        onClick={signMintData}
        actionIcon="success-tick"
        useResponsiveSizes={false}
        actionType="primary-action"
        isLoading={txnStatus === EApiCallStatus.PROCESSING}
        isDisabled={isButtonDisabled || txnStatus === EApiCallStatus.PROCESSING}
      />
    </React.Fragment>
  )
}

export default MintConfirm
