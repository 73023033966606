/* eslint-disable react/react-in-jsx-scope */

import { useContext, useEffect, useMemo, useState } from 'react'
import { useAccount, useChainId, useReadContract } from 'wagmi'
import { zeroAddress } from 'viem'

import { mainnet } from 'viem/chains'

import StakingVaultABI from '../../constants/abis/StakingVault.json'
import { rainbowKitConfig } from '../../constants/rainbowKitConfig'
import { formatBigNumber, formatStringToNumber } from '../../utils'
import { ThemeContext } from '../../context/ThemeProvider'
import sUSN from '../../assets/tokens/sUSN.svg'
import { TProtocolMetrics } from '../../types'
import { DAPP_CONFIG } from '../../constants'

interface IProps {
  refetch: boolean
}

const StakeInfo = (props: IProps) => {
  const theme = useContext(ThemeContext)

  const chainId = useChainId({ config: rainbowKitConfig })
  const account = useAccount({ config: rainbowKitConfig })

  const [lastWeekAPR, setLastWeekAPR] = useState<string>('0')

  const fetchProtocolMetrics = async () => {
    await fetch(`${process.env.REACT_APP_BASE_API_URL ?? ''}/api/v1/protocol-metrics`)
      .then(
        (res) => {
          if (res.ok) {
            return res.json()
          }

          throw new Error('Failed to fetch protocol metrics')
        },
        (err) => {
          throw new Error(err)
        },
      )
      .then((json) => json as TProtocolMetrics)
      .then((metrics) => {
        const data = Object.keys(metrics?.apyTimeSeries ?? {})
          .map((key: string) => ({
            label: key,
            value: metrics.apyTimeSeries[key] ? Number(metrics.apyTimeSeries[key]) : 0,
          }))
          .sort((a, b) => {
            const aTime = new Date(a.label).getTime()
            const bTime = new Date(b.label).getTime()
            return aTime - bTime
          })

        const lastValue = data.at(-1) || { value: '0' }
        setLastWeekAPR(lastValue?.value?.toString() ?? '0')
      })
      .catch((err) => {
        console.error('Error fetching metrics', err)
        return
      })
  }

  const usnsBalance = useReadContract({
    abi: StakingVaultABI,
    address: DAPP_CONFIG[chainId ?? mainnet.id].stakingVault,
    functionName: 'balanceOf',
    args: [account.address ?? zeroAddress],
    config: rainbowKitConfig,
    query: {
      enabled: true,
      refetchOnWindowFocus: true,
      refetchOnReconnect: true,
      refetchOnMount: true,
      refetchIntervalInBackground: true,
      refetchInterval: DAPP_CONFIG[chainId ?? mainnet.id].dataRefershInterval,
    },
  })

  const formattedUSNsBalance = useMemo(
    () => formatBigNumber(((usnsBalance.data || BigInt(0)) as bigint) ?? BigInt(0), 18),
    [usnsBalance, formatBigNumber],
  )

  const formattedLastWeekAPR = useMemo(() => {
    return formatStringToNumber(lastWeekAPR ?? '0', true)
  }, [lastWeekAPR])

  useEffect(() => {
    usnsBalance.refetch()
  }, [props.refetch])

  useEffect(() => {
    fetchProtocolMetrics()
  }, [chainId])

  return (
    <div
      className={`w-full flex flex-col md:flex-row items-start justify-center md:items-center md:justify-between gap-4 p-4 rounded-b-xl border-0 !border-t-[1px] ${theme.isLightTheme ? '!border-t-stone-200 bg-stone-100' : '!border-t-stone-800 bg-stone-900'}`}
    >
      <div className="grow flex flex-col items-start justify-between gap-1">
        <p
          className={`font-Suisse-Intl font-[450] text-base ${theme.isLightTheme ? 'text-stone-500' : 'text-stone-400'}`}
        >
          YOUR STAKED AMOUNT
        </p>
        <div className="w-fit flex rounded gap-1 items-center justify-end grow flex-wrap">
          <div className="shrink-0 w-6 h-6 rounded-[100px]">
            <img src={sUSN} className="w-full h-full" alt="USN" />
          </div>
          <p
            className={`font-Suisse-Intl font-medium text-2xl ${theme.isLightTheme ? 'text-stone-900' : 'text-stone-50'} text-wrap`}
          >
            {formattedUSNsBalance} sUSN
          </p>
        </div>
      </div>
      <div className="grow flex flex-col items-start md:items-end justify-between gap-1">
        <p
          className={`font-Suisse-Intl font-[450] text-base ${theme.isLightTheme ? 'text-stone-500' : 'text-stone-400'}`}
        >
          APY, Last 7D
        </p>
        <p
          className={`font-Suisse-Intl font-medium text-2xl ${theme.isLightTheme ? 'text-green-600' : 'text-green-500'}`}
        >
          {formattedLastWeekAPR}%
        </p>
      </div>
    </div>
  )
}

export default StakeInfo
