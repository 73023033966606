import { gql, GraphQLClient } from 'graphql-request'

import { useAccount } from 'wagmi'

import { formatUnits } from 'viem'

import { useQuery } from '@tanstack/react-query'

import { rainbowKitConfig } from '../constants/rainbowKitConfig'
import { UsnHoldings } from './useUsnHoldings'

export type ChainUsnHoldings = { [chainLabel: string]: string }
export type sUsnHoldings = { susnBalance: string }

const querySubgraph = async (client: GraphQLClient, queryDocument: string) => {
  return await client
    .request(queryDocument)
    .then(
      (data: unknown) => {
        const wallet = data as { wallet: sUsnHoldings } | { wallet: UsnHoldings }
        return wallet?.wallet as sUsnHoldings | UsnHoldings
      },
      () => {
        throw Error('Failed to fetch susn balance')
      },
    )
    .then(
      (data: sUsnHoldings | UsnHoldings) => {
        const balance = (data as sUsnHoldings)?.susnBalance || (data as UsnHoldings)?.usnBalance || '0'
        return formatUnits(BigInt(balance || '0'), 18)
      },
      () => {
        throw Error('Failed to fetch susn balance')
      },
    )
    .catch(() => {
      return '0'
    })
}

const usesUsnHoldings = () => {
  const account = useAccount({ config: rainbowKitConfig })

  const fetcher = async () => {
    if (!account.isConnected || !account?.address) {
      return { Ethereum: '0', Sophon: '0' } as ChainUsnHoldings
    }

    const querysusnDocument = gql`
      {
        wallet(id: "${account.address.toLowerCase()}") {
          susnBalance
        }
      }
    `

    const queryUsnDocument = gql`
      {
        wallet(id: "${account.address.toLowerCase()}") {
          usnBalance
        }
      }
    `

    const zksyncClient = new GraphQLClient(process.env.REACT_APP_GOLDSKY_SUSN_ZKSYNC_URL || '')
    const sophonClient = new GraphQLClient(process.env.REACT_APP_GOLDSKY_SUSN_SOPHON_URL || '')
    const mainnetClient = new GraphQLClient(process.env.REACT_APP_GOLDSKY_SUSN_MAINNET_URL || '')
    const [sophon, mainnet, zksync] = await Promise.all([
      querySubgraph(sophonClient, queryUsnDocument),
      querySubgraph(mainnetClient, querysusnDocument),
      querySubgraph(zksyncClient, querysusnDocument),
    ])

    return {
      Sophon: sophon,
      Ethereum: mainnet,
      Zksync: zksync,
    }
  }

  return useQuery({
    queryFn: fetcher,
    refetchOnMount: true,
    refetchOnReconnect: false,
    refetchOnWindowFocus: true,
    refetchIntervalInBackground: false,
    enabled: account.isConnected && !!account?.address,
    placeholderData: { Ethereum: '0', Sophon: '0', Zksyn: '0' } as ChainUsnHoldings,
    queryKey: ['susn', 'holdings', account?.address, account.isConnected],
    initialData: () => ({ Ethereum: '0', Sophon: '0', Zksyn: '0' }) as ChainUsnHoldings,
    select: (data) => {
      return {
        Ethereum: data?.Ethereum || '0',
        Sophon: data?.Sophon || '0',
        Zksync: data['Zksync'] || '0',
      } as ChainUsnHoldings
    },
  })
}

export default usesUsnHoldings
