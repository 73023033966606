import React, { useContext, useMemo } from 'react'

import { useAccount } from 'wagmi'

import { ReactComponent as ArrowRightUpIcon } from '../../assets/navigation/arrow-right-up.svg'
import elipsis39Rotated from '../../assets/backgrounds/ellipse-39-rotated.png'
import { ReactComponent as EditIcon } from '../../assets/action/edit.svg'
import defaultAvatarLight from '../../assets/dashboard/user-light.svg'
import defaultAvatarDark from '../../assets/dashboard/user-dark.svg'
import { ThemeContext } from '../../context/ThemeProvider'
import usesUsnHoldings from '../../hooks/useSusnHoldings'
import useUsnHoldings from '../../hooks/useUsnHoldings'
import usesUsnPrice from '../../hooks/usesUsnPrice'
import { formatStringToNumber } from '../../utils'
import EmptyContainer from '../EmptyContainer/'
import avatar from '../../assets/avatar.png'

const UserProfile = ({ username, onOpen }: { username: string; onOpen?: () => void }) => {
  const account = useAccount()
  const sUsnPrice = usesUsnPrice()
  const usnHoldings = useUsnHoldings()
  const sUsnHoldings = usesUsnHoldings()
  const theme = useContext(ThemeContext)

  const [totalHoldingsPerChain, totalPortfolio] = useMemo(() => {
    const susnPrice = Number(sUsnPrice?.data?.sUsnPrice || '0')
    const setOfChains = new Set([...Object.keys(usnHoldings?.data || {}), ...Object.keys(sUsnHoldings?.data || {})])
    let portfolio: number = 0
    return [
      Array.from(setOfChains.values()).map((chain: string) => {
        const usnHolding = Number(usnHoldings?.data[chain] || '0')
        const sUsnHolding = Number(sUsnHoldings?.data[chain] || '0')
        const value = Number(usnHolding + sUsnHolding * susnPrice)
        portfolio += value
        return { chain: chain, value: value }
      }),
      portfolio,
    ]
  }, [usnHoldings, sUsnPrice, sUsnHoldings])

  return (
    <div
      className={`relative w-full h-full gap-8 md:gap-[80px] grid grid-rows-1 md:grid-rows-2 grid-cols-1 items-stretch justify-center px-4 py-6 md:p-6 rounded-t-2xl border-b-[1px] ${theme.isLightTheme ? 'border-dashboard-light-mode-hero-section' : 'border-stone-800'}`}
    >
      <div className="absolute left-0 ml-auto mr-auto p-0 h-[329px] w-[735px] max-h-full max-w-full top-0 z-10 rounded-2xl">
        <img src={elipsis39Rotated} className="m-0 p-0 w-full h-full rounded-2xl" />
      </div>

      <div className="w-full flex flex-col md:flex-row gap-4 items-start justify-start md:items-center md:justify-between p-0 h-fit z-[100]">
        <div className="w-24 h-24">
          <img alt="Profile" className="w-full h-full" src={avatar} />
        </div>

        <div className="w-full h-fit md:w-auto md:grow flex flex-row gap-4 items-start justify-between p-0">
          <div className="flex flex-col items-start justify-center gap-2 p-0">
            <div
              className={`font-Louize font-normal text-2xl md:text-3xl tracking-tighter text-center ${theme.isLightTheme ? 'text-stone-900' : 'text-stone-50'}`}
            >
              Hello, {account.isConnected ? username || 'fren' : 'fren'}!
            </div>
            <a
              href={`${account?.chain?.blockExplorers?.default.url}/address/${account?.address}`}
              className={`font-Suisse-Intl font-[450] text-base ${theme.isLightTheme ? 'text-stone-500' : 'text-stone-400'} flex justify-start gap-1 items-center`}
            >
              {account.address &&
                ` ${account?.address?.slice(0, 4)}...${account?.address?.slice(account?.address?.length - 5)}`}
              {account.address && (
                <div className={`w-5 h-5 text-stone-400`}>
                  <ArrowRightUpIcon className="w-full h-full" />
                </div>
              )}
            </a>
            {!account.address && (
              <EmptyContainer classes={`h-12 w-32 ${theme.isLightTheme ? 'bg-stone-200' : 'bg-stone-800'}`} />
            )}
          </div>
          <button
            disabled={!account.address}
            onClick={onOpen}
            className={`!justify-self-end w-fit flex items-center justify-center align-ite rounded-lg gap-1 py-1 px-2 ${theme.isLightTheme ? 'bg-stone-200' : 'bg-stone-800'} cursor-pointer`}
          >
            <div className={`h-4 w-4 ${theme.isLightTheme ? 'text-stone-500' : 'text-stone-400'}`}>
              <EditIcon className="w-full h-full" />
            </div>
            <p
              className={`font-Suisse-Intl font-medium text-sm ${theme.isLightTheme ? 'text-stone-800' : 'text-stone-100'}`}
            >
              Edit
            </p>
          </button>
        </div>
      </div>

      <div className="w-full flex flex-col md:flex-row gap-2 items-stretch justify-between z-[100]">
        <div className="w-full md:w-auto grow flex flex-col items-start justify-start gap-3">
          <p
            className={`font-Louize font-normal text-4xl ${theme.isLightTheme ? 'text-stone-900' : 'text-stone-200'} opacity-60 bg-blend-color-burn`}
          >
            Total Portfolio
          </p>

          <div className="w-full flex items-center justify-start gap-2">
            <p
              className={`font-Suisse-Intl font-[450] text-[32px] ${theme.isLightTheme ? 'text-stone-900' : 'text-stone-50'}`}
            >
              ${formatStringToNumber(totalPortfolio?.toString() || '0', true)}
            </p>
          </div>

          <p
            className={`font-Suisse-Intl font-[450] text-base ${theme.isLightTheme ? 'text-stone-500' : 'text-stone-400'}`}
          >
            sUSN + USN Holdings ($USD value)
          </p>
        </div>

        <div className="w-full md:w-fit flex flex-col items-center justify-start gap-2">
          {(totalHoldingsPerChain?.length ? totalHoldingsPerChain : []).map((holding, i) => (
            <div key={i} className="w-full flex flex-row items-center justify-between gap-[60px]">
              <div className="w-full flex flex-row items-center justify-start gap-1">
                <div className={`h-5 w-5 rounded-[100px]`}>
                  <img
                    className="w-full h-full object-contain"
                    src={`${process.env.PUBLIC_URL}/assets/chains/${holding.chain.toLowerCase() || 'ethereum'}.svg`}
                  />
                </div>
                <p
                  className={`font-Suisse-Intl font-[450] text-base ${theme.isLightTheme ? 'text-stone-500' : 'text-stone-300'} capitalize`}
                >
                  {holding?.chain || 'Ethereum'}
                </p>
              </div>
              <p
                className={`font-Suisse-Intl font-[450] text-base ${theme.isLightTheme ? 'text-stone-900' : 'text-stone-50'}`}
              >
                $
                {formatStringToNumber(holding?.value?.toString() || '0', true)
                  ?.split('.')
                  .at(0) ||
                  formatStringToNumber(holding?.value?.toString() || '0', true) ||
                  '0.00'}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default UserProfile
