import React, { useContext, useState } from 'react'

import BridgeContextProvider, { BridgeContext } from '../../context/BridgeProvider'
import bridgeNoonLogo from '../../assets/backgrounds/bridge-page-noon-logo.png'
import elipse45Dark from '../../assets/backgrounds/ellipse-45-dark.png'
import BridgeSuccessful from '../../components/Bridge/BridgeSuccessful'
import InputAndBridge from '../../components/Bridge/InputAndBridge'
import BridgeConfirm from '../../components/Bridge/BridgeConfirm'
import elipse45 from '../../assets/backgrounds/ellipse-45.png'
import { ThemeContext } from '../../context/ThemeProvider'

const Bridge = () => {
  const [txnUrl, setTxnUrl] = useState<string>('')

  const theme = useContext(ThemeContext)
  const bridge = useContext(BridgeContext)

  return (
    <div className="relative h-full flex flex-col gap-8 py-8 px-4 md:px-20 items-center justify-center mb-[100px]">
      <div className="h-full w-full md:w-[50%] max-w-full flex flex-col gap-8 py-8 items-center justify-center">
        <div
          className={`w-full font-Louize font-normal text-4xl tracking-tighter text-center ${theme.isLightTheme ? 'text-stone-900' : 'text-stone-50'}`}
        >
          Bridge
        </div>

        <div
          className={`w-full h-fit rounded-xl border-[1px] flex flex-col items-center justify-center p-1 gap-1 bridge-main-container-shadow ${theme.isLightTheme ? 'bg-stone-200 border-stone-200' : 'bg-stone-900 border-stone-800'}`}
        >
          {bridge.screen === 'input' ? (
            <InputAndBridge />
          ) : bridge.screen === 'confirm' ? (
            <BridgeConfirm setTxnUrl={setTxnUrl} />
          ) : (
            <BridgeSuccessful txnUrl={txnUrl} />
          )}
        </div>
      </div>

      {theme.isLightTheme ? (
        <div className="absolute left-0 right-0 ml-auto mr-auto p-0 w-[calc(100vw-16)] max-w-[calc(100vw-16)] md:w-[507px] md:max-w-[507px] h-[435px] max-h-[435px] bottom-9 -z-20">
          <img src={elipse45} className="m-0 p-0 w-full h-full" />
        </div>
      ) : (
        <div className="absolute left-0 right-0 ml-auto mr-auto p-0 w-full max-w-full md:w-[70%] md:max-w-full h-[490px] max-h-[490px] -bottom-9 -z-20">
          <img src={elipse45Dark} className="m-0 p-0 w-full h-full" />
        </div>
      )}

      <div className="absolute left-0 right-0 ml-auto mr-auto p-0 w-[calc(100vw-16)] max-w-[calc(100vw-16)] md:w-[504px] md:max-w-full h-[148px] max-h-[148px] -bottom-0 -z-10">
        <img src={bridgeNoonLogo} className="m-0 p-0 w-full h-full" />
      </div>
    </div>
  )
}

const BridgeProviderWrapper = () => {
  return (
    <BridgeContextProvider>
      <Bridge />
    </BridgeContextProvider>
  )
}

export default BridgeProviderWrapper
