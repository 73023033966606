import { Modal, ModalOverlay, ModalContent } from '@chakra-ui/react'

import React, { useContext, useMemo, useState } from 'react'

import { isAddress } from 'viem'

import { ReactComponent as MultipleCrossIcon } from '../../assets/navigation/multiple-cross.svg'
import { ReactComponent as InfoIcon } from '../../assets/info/info-circle.svg'
import { BridgeContext } from '../../context/BridgeProvider'
import { ThemeContext } from '../../context/ThemeProvider'
import './index.css'

interface IProps {
  isModalOpen: boolean
  onCloseModal: () => void
}

const BridgeReceiveAddressModal = (props: IProps) => {
  const [address, setAddress] = useState<string>()

  const theme = useContext(ThemeContext)
  const bridge = useContext(BridgeContext)

  const onChangeReceiverAddress = (event: React.FormEvent<HTMLInputElement>) => {
    const value = (event?.target as HTMLInputElement)?.value
    setAddress(value)
  }

  const updateReceiverAddress = () => {
    if (isAddress(address || '')) {
      bridge.setReceiverAddress(address || '')
      props.onCloseModal()
    }
  }

  const isActionDisabled = useMemo(() => {
    return !address || !address?.length || !isAddress(address || '')
  }, [address, isAddress])

  return (
    <Modal isOpen={props.isModalOpen} onClose={props.onCloseModal} autoFocus={false}>
      <ModalOverlay />
      <ModalContent
        borderRadius={'8px'}
        background={theme.isLightTheme ? 'bg-white' : 'bg-stone-900'}
        className={`relative top-40 w-full rounded-lg p-6 gap-4 ${theme.isLightTheme ? 'bg-white' : 'bg-stone-900'} chain-selection-modal-shadow`}
      >
        <div
          onClick={props.onCloseModal}
          className={`absolute h-5 w-5 cursor-pointer ${theme.isLightTheme ? 'text-stone-400' : 'text-stone-500'} right-5 top-5 outline-none`}
        >
          <MultipleCrossIcon className="w-full h-full" />
        </div>

        <p
          className={`w-full max-w-full font-Suisse-Intl font-medium text-lg ${theme.isLightTheme ? 'text-stone-900' : 'text-stone-50'} text-left`}
        >
          Change address
        </p>

        <div className="w-full max-w-full flex flex-col items-start justify-start gap-4">
          <div
            className={`h-full w-full max-w-full flex items-start justify-start rounded-[10px] p-3 gap-2 ${theme.isLightTheme ? 'bg-stone-100' : 'bg-stone-800'}`}
          >
            <div className={`w-6 h-6 ${theme.isLightTheme ? 'text-stone-900' : 'text-stone-200'} shrink-0`}>
              <InfoIcon className="w-full h-full" />
            </div>
            <div className="grow flex flex-col items-start justify-start gap-[2px]">
              <p
                className={`font-Suisse-Intl font-[450] text-base leading-6 ${theme.isLightTheme ? 'text-stone-900' : 'text-stone-200'} text-wrap`}
              >{`Before proceeding, please ensure that you can access the wallet address on the receiving chain.`}</p>
              <p className="font-Suisse-Intl font-[450] text-sm leading-5 text-stone-500 text-wrap">{`This is especially important if you use wallets like Gnosis Safe. If you cannot access this address, please change the receiving wallet address to avoid any issues.`}</p>
            </div>
          </div>

          <div className="w-full max-w-full flex flex-col items-start justify-start gap-1">
            <p
              className={`w-full max-w-full text-left font-Suisse-Intl font-[450] text-base ${theme.isLightTheme ? 'text-stone-500' : 'text-stone-600'}`}
            >
              Enter the address
            </p>
            <input
              type="text"
              placeholder="0x..."
              value={address || ''}
              onChange={onChangeReceiverAddress}
              className={`rounded-lg border-[1px] py-3 px-4 bg-transaparent ${theme.isLightTheme ? 'border-stone-200' : 'border-stone-700'} w-full font-Suisse-Intl font-[450] text-base ${theme.isLightTheme ? 'text-stone-900 placeholder:text-stone-400' : 'text-stone-50 placeholder:text-stone-500'} outline-none`}
            />
          </div>
        </div>

        <div className="w-full flex flex-col md:flex-row gap-4 items-center justify-center">
          <button
            disabled={isActionDisabled}
            onClick={updateReceiverAddress}
            className={`grow w-full md:w-auto rounded-[184px] py-2 px-3 gap-1 ${isActionDisabled ? (theme.isLightTheme ? 'bg-indigo-400' : 'bg-indigo-900') : 'bg-indigo-600'} flex items-center justify-center`}
          >
            <p
              className={`font-Suisse-Intl font-medium text-base ${isActionDisabled ? (theme.isLightTheme ? 'text-indigo-200' : 'text-indigo-600') : 'text-stone-50'}`}
            >
              Change address
            </p>
          </button>

          <button
            onClick={props.onCloseModal}
            className={`grow w-full md:w-auto flex rounded-[184px] border-[1px] py-2 px-3 gap-1 ${theme.isLightTheme ? 'bg-stone-100 border-stone-300 ' : 'bg-stone-900 border-stone-800'} items-center justify-center`}
          >
            <p
              className={`font-Suisse-Intl font-medium text-base ${theme.isLightTheme ? 'text-stone-900' : 'text-stone-50'}`}
            >
              Cancel
            </p>
          </button>
        </div>
      </ModalContent>
    </Modal>
  )
}

export default BridgeReceiveAddressModal
