import React, { useContext } from 'react'

import { ThemeContext } from '../../context/ThemeProvider'
import NetworkFee from '../NetworkFee/NetworkFee'
import SUSNPrice from '../SUSNPrice'
import USNPrice from '../USNPrice'

interface IProps {
  page: string
  quoteToken: string
  networkFeeAction: string
  networkFeeGasLimit?: bigint | undefined
}

const ActionStats = (props: IProps) => {
  const theme = useContext(ThemeContext)

  return (
    <div className="w-full flex flex-col items-start justify-start gap-2">
      {props.page === 'sUSN' && <SUSNPrice currency={props.quoteToken} />}
      {props.page === 'USN' && props.networkFeeAction !== 'swap' && <USNPrice currency={props.quoteToken} />}
      {props.page === 'USN' && props.networkFeeAction === 'swap' && (
        <div className="w-full flex gap-6 items-center justify-between">
          <p className="font-Suisse-Intl font-[450] text-base text-stone-500">Max Slippage</p>
          <p
            className={`font-Suisse-Intl font-semibold text-base ${theme.isLightTheme ? 'text-stone-900' : 'text-stone-50'}`}
          >
            1.00%
          </p>
        </div>
      )}
      <NetworkFee action={props.networkFeeAction} gasLimit={props.networkFeeGasLimit || undefined} />
    </div>
  )
}

export default ActionStats
