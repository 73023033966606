import React, { useContext } from 'react'

import { ThemeContext } from '../../context/ThemeProvider'

interface IProps {
  labelIn: string
  tokenIn: string
  labelOut: string
  amountIn: string
  tokenOut: string
  amountOut: string
}

const ActionInAndOutInfo = (props: IProps & React.PropsWithChildren) => {
  const theme = useContext(ThemeContext)

  return (
    <React.Fragment>
      <div
        className={`w-full flex flex-col rounded-xl p-2 gap-3 ${theme.isLightTheme ? 'bg-stone-100' : 'bg-stone-900'} items-start justify-center`}
      >
        <div
          className={`w-full max-w-full h-fit rounded-xl p-2 gap-2 flex flex-col md:flex-row items-center justify-between md:items-stretch`}
        >
          <div
            className={`grow w-full md:max-w-[49%] rounded-md gap-2 py-5 px-[51px] flex flex-col items-center justify-center flex-wrap ${theme.isLightTheme ? 'bg-white' : 'bg-stone-800'}`}
          >
            <p className="font-Suisse-Intl font-[450] text-base text-stone-500 text-center text-wrap">
              {props.labelIn}
            </p>
            <div className="w-full flex items-center justify-center rounded gap-1 flex-wrap">
              <div className="w-6 h-6 shrink-0">
                <img
                  alt={props.tokenIn}
                  className="w-full h-full"
                  src={`${process.env.PUBLIC_URL}/assets/tokens/${props.tokenIn}.svg`}
                />
              </div>
              <p
                className={`shrink font-Suisse-Intl font-semibold text-2xl text-wrap text-center max-w-full ${theme.isLightTheme ? 'text-stone-900' : 'text-stone-100'}`}
              >
                {props.amountIn} {props.tokenIn}
              </p>
            </div>
          </div>

          <div
            className={`grow w-full md:max-w-[49%] rounded-md gap-2 py-5 px-[51px] flex flex-col items-center justify-center flex-wrap ${theme.isLightTheme ? 'bg-white' : 'bg-stone-800'}`}
          >
            <p className="font-Suisse-Intl font-[450] text-base text-stone-500 text-center text-wrap">
              {props.labelOut}
            </p>
            <div className="w-full flex items-center justify-center rounded gap-1 flex-wrap">
              <div className="w-6 h-6 shrink-0">
                <img
                  alt={props.tokenOut}
                  className="w-full h-full"
                  src={`${process.env.PUBLIC_URL}/assets/tokens/${props.tokenOut}.svg`}
                />
              </div>
              <p
                className={`shrink font-Suisse-Intl font-semibold text-2xl text-wrap text-center max-w-full ${theme.isLightTheme ? 'text-stone-900' : 'text-stone-100'}`}
              >
                {props.amountOut} {props.tokenOut}
              </p>
            </div>
          </div>
        </div>
        {props.children}
      </div>
    </React.Fragment>
  )
}

export default ActionInAndOutInfo
