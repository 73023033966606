import React, { useContext } from 'react'

import { darkTheme, lightTheme, RainbowKitProvider } from '@rainbow-me/rainbowkit'
import { useLocation } from 'react-router-dom'

import { mainnet } from 'wagmi/chains'

import multipleEllipseFrame from '../../assets/backgrounds/multiple-ellipse-frame.png'
import noiseAndTexture from '../../assets/backgrounds/noise-and-texture.png'
import background329 from '../../assets/backgrounds/background-329.png'
import elipsis38 from '../../assets/backgrounds/ellipse-38.png'
import { ThemeContext } from '../../context/ThemeProvider'
import Navbar from '../Navbar'
import Footer from '../Footer'
import Routes from '../Routes'

export const RootApp = () => {
  const location = useLocation()
  const theme = useContext(ThemeContext)

  return (
    <RainbowKitProvider initialChain={mainnet} theme={theme.isLightTheme ? lightTheme() : darkTheme()}>
      <div
        className={`relative w-full h-full ${theme.isLightTheme ? 'bg-stone-50' : 'bg-stone-950'} z-0 overflow-hidden`}
      >
        {theme.isLightTheme && (
          <div className="absolute top-0 left-0 m-0 p-0 w-full h-[25%] md:w-[81%] md:h-[76%] z-10 background-image-329">
            <img src={background329} className="m-0 p-0 w-full h-full" />
          </div>
        )}

        <div className="absolute left-0 right-0 ml-auto mr-auto p-0 w-[890px] max-w-[890px] top:0 md:-top-52 z-20">
          <img src={elipsis38} className="m-0 p-0 w-full h-full" />
        </div>

        {location.pathname.includes('bridge') && theme.isLightTheme && (
          <img
            src={multipleEllipseFrame}
            className="hidden md:block absolute left-0 right-0 ml-auto mr-auto p-0 w-auto h-full aspect-auto top:0 bottom:0 z-30"
          />
        )}

        <img src={noiseAndTexture} className="absolute m-0 p-0 top-0 left-0 w-full h-full z-30" />

        <div className="relative top-0 left-0 w-full h-full m-0 p-0 z-40">
          <Navbar />
          <Routes />
          <Footer />
        </div>
      </div>
    </RainbowKitProvider>
  )
}

export default RootApp
