import { useCallback, useContext } from 'react'

import { Config, useConnectorClient } from 'wagmi'

import { estimateMaxPriorityFeePerGas, waitForTransactionReceipt } from '@wagmi/core'

import { eip712WalletActions, getGeneralPaymasterInput } from 'viem/zksync'
import { createWalletClient, custom } from 'viem'
import { MaxUint256 } from 'ethers'

import Erc20Abi from '../../../constants/abis/USNUpgradeable.json'

import { DEFAULT_GAS_PER_PUBDATA_LIMIT } from 'zksync-ethers/build/utils'

import { mainnet } from 'viem/chains'

import { rainbowKitConfig } from '../../../constants/rainbowKitConfig'
import { BridgeContext } from '../../../context/BridgeProvider'
import { DAPP_CONFIG, sophon } from '../../../constants'
import { ETxnStatus } from '../../../types'

function useBridgeApprove(token: string): () => Promise<void> {
  const bridge = useContext(BridgeContext)
  const connector = useConnectorClient<Config>({ config: rainbowKitConfig })

  const approve = useCallback(async (): Promise<void> => {
    if (
      !token ||
      !bridge ||
      !connector ||
      !connector.data ||
      !connector.data.chain ||
      !connector.data.account ||
      !connector.data.transport
    ) {
      bridge.setApproveTxnStatus(ETxnStatus.TXN_FAILURE)
      return
    }

    const chainId = connector.data.chain.id as 1 | 50104 | 324
    const config = DAPP_CONFIG[chainId]
    if (!chainId || !config) {
      bridge.setApproveTxnStatus(ETxnStatus.TXN_FAILURE)
      return
    }
    const _token = config.tokens[token]
    if (!_token) {
      bridge.setApproveTxnStatus(ETxnStatus.TXN_FAILURE)
      return
    }

    bridge.setApproveTxnStatus(ETxnStatus.PROCESSING)
    try {
      let txnHash: string
      const spender = config.layerZeroOftHelper || _token.oftAddress

      if (config.paymaster) {
        const wallet = createWalletClient({
          transport: custom(connector.data.transport),
          chain: connector.data.chain as typeof sophon,
        }).extend(eip712WalletActions())

        txnHash = await wallet.writeContract({
          abi: Erc20Abi,
          functionName: 'approve',
          args: [spender, MaxUint256],
          paymaster: config.paymaster,
          address: _token.contractAddress,
          account: connector.data.account,
          gasPerPubdata: BigInt(DEFAULT_GAS_PER_PUBDATA_LIMIT || 0),
          maxPriorityFeePerGas: await estimateMaxPriorityFeePerGas(rainbowKitConfig, { chainId }),
          paymasterInput: getGeneralPaymasterInput({
            innerInput: '0x',
          }),
        })
      } else {
        const wallet = createWalletClient({
          transport: custom(connector.data.transport),
          chain: connector.data.chain as typeof mainnet,
        })
        txnHash = await wallet.writeContract({
          abi: Erc20Abi,
          functionName: 'approve',
          args: [spender, MaxUint256],
          address: _token.contractAddress,
          account: connector.data.account!,
          maxPriorityFeePerGas: await estimateMaxPriorityFeePerGas(rainbowKitConfig, { chainId }),
        })
      }

      bridge.setApproveTxnStatus(ETxnStatus.TXN_SUBMITTED_ON_CHAIN)

      const receipt = await waitForTransactionReceipt(rainbowKitConfig, { hash: txnHash as `0x${string}` }).catch(
        () => ({
          status: 'unknown',
        }),
      )
      if (receipt.status === 'success') {
        bridge.setApproveTxnStatus(ETxnStatus.TXN_SUCCESS)
      } else if (receipt.status === 'reverted') {
        bridge.setApproveTxnStatus(ETxnStatus.TXN_FAILURE)
      } else {
        bridge.setApproveTxnStatus(ETxnStatus.TXN_SUCCESS)
      }
    } catch {
      bridge.setApproveTxnStatus(ETxnStatus.TXN_FAILURE)
    }
  }, [token, connector])

  return approve
}

export default useBridgeApprove
