import React, { useContext } from 'react'

import { ThemeContext } from '../../context/ThemeProvider'

interface IProps {
  tokenOut: string
  amountOut: string
}

const ReceiveTokenInfo = (props: IProps) => {
  const theme = useContext(ThemeContext)

  return (
    <div className="w-full max-w-full flex items-center justify-between">
      <p className="font-Suisse-Intl font-[450] text-base text-stone-500 text-wrap w-auto justify-self-start">
        You will receive
      </p>
      <p
        className={`grow font-Suisse-Intl font-semibold text-lg ${theme.isLightTheme ? 'text-stone-950' : 'text-stone-50'} text-wrap justify-self-end text-right`}
      >
        <img
          alt={props.tokenOut}
          className="w-6 h-6 inline"
          src={`${process.env.PUBLIC_URL}/assets/tokens/${props.tokenOut}.svg`}
        />
        &nbsp;
        {props.amountOut} {props.tokenOut}
      </p>
    </div>
  )
}

export default ReceiveTokenInfo
