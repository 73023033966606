import React, { createContext, useEffect, useState } from 'react'

import { useAccount, useChains } from 'wagmi'

import { mainnet } from 'wagmi/chains'

import { rainbowKitConfig } from '../constants/rainbowKitConfig'
import { EApiCallStatus, ETxnStatus, TScreen } from '../types'
import { DAPP_CONFIG } from '../constants'

export interface IBuyContext {
  tokenIn: string
  screen: TScreen
  tokenOut: string
  amountIn: string
  amountOut: string
  buyTokens: string[]
  sellTokens: string[]
  buyTxnStatus: ETxnStatus
  approveTxnStatus: ETxnStatus
  chainChangeStatus: EApiCallStatus
  setTokenIn: (token: string) => void
  setScreen: (screen: TScreen) => void
  setTokenOut: (token: string) => void
  setAmountIn: (amount: string) => void
  setAmountOut: (amount: string) => void
  setBuyTxnStatus: (status: ETxnStatus) => void
  setApproveTxnStatus: (status: ETxnStatus) => void
  setChainChangeStatus: (status: EApiCallStatus) => void
}

export const BuyContext = createContext<IBuyContext>({
  amountIn: '',
  amountOut: '',
  buyTokens: [],
  sellTokens: [],
  tokenIn: 'USDC',
  screen: 'input',
  tokenOut: 'USDT',
  buyTxnStatus: ETxnStatus.IDLE,
  approveTxnStatus: ETxnStatus.IDLE,
  chainChangeStatus: EApiCallStatus.IDLE,
  setTokenIn: (token: string) => {
    console.log('Token', token)
  },
  setTokenOut: (token: string) => {
    console.log('Token', token)
  },
  setAmountIn: (amount: string) => {
    console.log('Amount', amount)
  },
  setAmountOut: (amount: string) => {
    console.log('Amount', amount)
  },
  setScreen: (screen: TScreen) => {
    console.log('Screen', screen)
  },
  setBuyTxnStatus: (status: ETxnStatus) => {
    console.log('Update status', status)
  },
  setApproveTxnStatus: (status: ETxnStatus) => {
    console.log('Update status', status)
  },
  setChainChangeStatus: (status: EApiCallStatus) => {
    console.log('Status', status)
  },
})

export const BuyContextProvider: React.FC<React.PropsWithChildren> = (props) => {
  const [screen, setScreen] = useState<TScreen>('input')
  const [amountIn, setAmountIn] = useState<string>('')
  const [amountOut, setAmountOut] = useState<string>('')
  const [tokenIn, setTokenIn] = useState<string>('USDC')
  const [tokenOut, setTokenOut] = useState<string>('USDT')
  const [buyTokens, setBuyTokens] = useState<string[]>([])
  const [sellTokens, setSellTokens] = useState<string[]>([])
  const [buyTxnStatus, setBuyTxnStatus] = useState<ETxnStatus>(ETxnStatus.IDLE)
  const [approveTxnStatus, setApproveTxnStatus] = useState<ETxnStatus>(ETxnStatus.IDLE)
  const [chainChangeStatus, setChainChangeStatus] = useState<EApiCallStatus>(EApiCallStatus.IDLE)

  const chains = useChains({ config: rainbowKitConfig })
  const account = useAccount({ config: rainbowKitConfig })

  useEffect(() => {
    if (!account.isConnected || !account.address || !account.chainId) {
      setScreen('input')
    }
  }, [chains, account.chainId])

  useEffect(() => {
    if (!account || !account.chainId) {
      return
    }

    const config = DAPP_CONFIG[account.chainId] || DAPP_CONFIG[mainnet.id]
    setAmountIn('')
    setAmountOut('')
    setScreen('input')
    setBuyTxnStatus(ETxnStatus.IDLE)
    setApproveTxnStatus(ETxnStatus.IDLE)
    setTokenIn(config?.tokensForSell?.at(0) || 'USDC')
    setTokenOut(config?.tokensForBuy?.at(0) || 'USDT')
    setBuyTokens(config?.tokensForBuy?.length ? config?.tokensForBuy || [] : [])
    setSellTokens(config?.tokensForSell?.length ? config?.tokensForSell || [] : [])
  }, [account.chainId])

  const onSetScreen = (screen: TScreen) => {
    setBuyTxnStatus(ETxnStatus.IDLE)
    setApproveTxnStatus(ETxnStatus.IDLE)
    setScreen(screen)
  }

  return (
    <BuyContext.Provider
      value={{
        screen,
        tokenIn,
        tokenOut,
        amountIn,
        amountOut,
        buyTxnStatus,
        approveTxnStatus,
        chainChangeStatus,
        buyTokens,
        sellTokens,
        setTokenIn,
        setTokenOut,
        setAmountIn,
        setAmountOut,
        setBuyTxnStatus,
        setApproveTxnStatus,
        setChainChangeStatus,
        setScreen: onSetScreen,
      }}
    >
      {props.children}
    </BuyContext.Provider>
  )
}

export default BuyContextProvider
