import { mainnet } from '@wagmi/core/chains'

import { useAccount, useChainId, useReadContract } from 'wagmi'

import WithdrawalHandlerABI from '../../constants/abis/WithdrawalHandler.json'
import { formatBigNumber, formatBigNumberCompact } from '../../utils'
import { rainbowKitConfig } from '../../constants/rainbowKitConfig'
import { DAPP_CONFIG } from '../../constants'

export const DEFAULT_WITHDRAW_REQ = {
  formatted: '',
  humanized: '',
  abbrevated: '',
  claimed: false,
  amount: BigInt(0),
  timestamp: Date.now(),
}

const useWithdrawReqInfo = (reqId: number) => {
  const chainId = useChainId({ config: rainbowKitConfig })
  const account = useAccount({ config: rainbowKitConfig })

  return useReadContract({
    chainId: mainnet.id,
    config: rainbowKitConfig,
    abi: WithdrawalHandlerABI,
    args: [account.address!, reqId],
    functionName: 'getWithdrawalRequest',
    address: DAPP_CONFIG[mainnet.id].stakeWithdrawHandler,
    query: {
      refetchOnMount: true,
      refetchOnReconnect: true,
      refetchOnWindowFocus: true,
      refetchIntervalInBackground: true,
      initialData: () => DEFAULT_WITHDRAW_REQ,
      placeholderData: () => DEFAULT_WITHDRAW_REQ,
      refetchInterval: DAPP_CONFIG[mainnet.id].dataRefershInterval,
      select: (data) => {
        if (!data) {
          return DEFAULT_WITHDRAW_REQ
        }

        const req = data as typeof DEFAULT_WITHDRAW_REQ
        return {
          claimed: req.claimed,
          amount: req.amount || BigInt(0),
          timestamp: Number(req.timestamp.toString()) * 1000,
          humanized: formatBigNumber(req.amount, 18, true),
          formatted: formatBigNumber(req.amount, 18, false),
          abbrevated: formatBigNumberCompact(req.amount, 18, true),
        }
      },
      enabled: chainId === mainnet.id && account.isConnected,
    },
  })
}

export default useWithdrawReqInfo
