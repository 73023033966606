import { useAccount } from 'wagmi'

import Quoter from '@uniswap/v3-periphery/artifacts/contracts/lens/QuoterV2.sol/QuoterV2.json'
import SyncswapQuoterV2 from '../../constants/abis/SyncswapQuoterV2.json'

import { Contract } from 'zksync-ethers'

import { JsonRpcProvider } from 'ethers'

import { useQuery } from '@tanstack/react-query'

import { useContext } from 'react'

import { formatUnits, parseUnits, zeroAddress } from 'viem'

import { mainnet } from 'viem/chains'

import { rainbowKitConfig } from '../../constants/rainbowKitConfig'
import { encodePath, REFETCH_INTERVAL } from '../../utils'
import { BuyContext } from '../../context/BuyProvider'
import { DAPP_CONFIG } from '../../constants'

const useBuyQuote = () => {
  const buy = useContext(BuyContext)
  const account = useAccount({ config: rainbowKitConfig })

  const fetch = async () => {
    try {
      if (
        !buy ||
        !account ||
        !buy.tokenIn ||
        !buy.tokenOut ||
        !buy.amountIn ||
        !account.chain ||
        !account.address ||
        !account.chainId ||
        !account.isConnected
      ) {
        buy.setAmountOut('')
        return BigInt(0)
      }
      const config = DAPP_CONFIG[account.chainId]
      if (!config) {
        buy.setAmountOut('')
        return BigInt(0)
      }
      if (!config.uniV3Quoter || !config.uniV3SwapRouter || !config.uniV3Pools) {
        buy.setAmountOut('')
        return BigInt(0)
      }

      const _tokenIn = config.tokens[buy.tokenIn]
      const _tokenOut = config.tokens[buy.tokenOut]
      if (!_tokenIn || !config.tokensForSell?.includes(buy.tokenIn)) {
        buy.setAmountOut('')
        return BigInt(0)
      }
      if (!_tokenOut || !config.tokensForBuy?.includes(buy.tokenOut)) {
        buy.setAmountOut('')
        return BigInt(0)
      }

      const _pool =
        config.uniV3Pools[`${buy.tokenIn}-${buy.tokenOut}`] || config.uniV3Pools[`${buy.tokenOut}-${buy.tokenIn}`]
      if (!_pool) {
        buy.setAmountOut('')
        return BigInt(0)
      }

      const contract = new Contract(
        config.uniV3Quoter,
        account.chainId === mainnet.id ? Quoter.abi : SyncswapQuoterV2,
        new JsonRpcProvider(account.chain.rpcUrls.default.http.at(0)),
      )

      const route = _pool.path.map((token) => config.tokens[token]?.contractAddress || zeroAddress)
      const fee = _pool.feeForPath

      const params =
        account.chainId === mainnet.id
          ? [encodePath(route, fee), parseUnits(buy.amountIn, _tokenIn.decimals)]
          : [config.uniV3Factory, encodePath(route, fee), parseUnits(buy.amountIn, _tokenIn.decimals)]

      const quote = await contract.getFunction('quoteExactInput').staticCall(...params)
      const expectedAmountOut = quote?.toObject() || {}
      buy.setAmountOut(formatUnits(expectedAmountOut?.amountOut || BigInt(0), _tokenOut.decimals))
      return quote
    } catch {
      buy.setAmountOut('')
      return BigInt(0)
    }
  }

  return useQuery({
    queryFn: fetch,
    refetchOnMount: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: true,
    initialData: () => BigInt(0),
    placeholderData: () => BigInt(0),
    refetchInterval: REFETCH_INTERVAL,
    refetchIntervalInBackground: false,
    select: (data) => (data || BigInt(0)) as bigint,
    queryKey: ['buy', 'quote', buy?.tokenIn, buy?.tokenOut, buy?.amountIn, account?.chainId],
    enabled:
      Boolean(buy) && Boolean(buy?.tokenIn) && Boolean(buy.tokenOut) && Boolean(buy.amountIn) && account.isConnected,
  })
}

export default useBuyQuote
