import React, { useContext, useMemo, useState } from 'react'

import moment from 'moment'

import Countdown from 'react-countdown'

import useWithdrawReqInfo, { DEFAULT_WITHDRAW_REQ } from '../../hooks/read/useWithdrawReqInfo'
import { ThemeContext } from '../../context/ThemeProvider'
import useWithdraw from '../../hooks/write/useWithdraw'
import ConnectButton from '../ConnectButton'
import { ETxnStatus } from '../../types'

interface IProps {
  reqId: number
  withdrawPeriod: number
}

const WithdrawReqInfo = (props: IProps) => {
  const [btnLabel, setBtnLabel] = useState<string>('Claim Amount')
  const [txnStatus, setTxnStatus] = useState<ETxnStatus>(ETxnStatus.IDLE)

  const theme = useContext(ThemeContext)
  const withdrawReq = useWithdrawReqInfo(props.reqId)

  const unlockTime = useMemo(() => {
    const info = (withdrawReq.data || DEFAULT_WITHDRAW_REQ) as typeof DEFAULT_WITHDRAW_REQ
    const time = info.timestamp + props.withdrawPeriod
    return Number(time.toString())
  }, [props.withdrawPeriod, withdrawReq])

  const currentTime = useMemo(() => {
    return Date.now()
  }, [withdrawReq])

  const isDisabled = useMemo(() => {
    return !withdrawReq.data?.amount
  }, [withdrawReq])

  const isLoading = useMemo(
    () => txnStatus === ETxnStatus.PROCESSING || txnStatus === ETxnStatus.TXN_SUBMITTED_ON_CHAIN,
    [txnStatus],
  )

  const onWithdrawUsnCb = useWithdraw(props.reqId, setBtnLabel, setTxnStatus)

  return (
    <div className={`w-full max-w-full h-fit mb-2`}>
      {withdrawReq.isLoading && (
        <div className={`w-full max-w-full h-full max-h-full p-4`}>
          <div className="animate-pulse flex space-x-4">
            <div className="flex-1 space-y-6 py-1">
              <div className={`h-2 ${theme.isLightTheme ? 'bg-stone-200' : 'bg-stone-700'} rounded`}></div>
              <div className="space-y-3">
                <div className="grid grid-cols-3 gap-4">
                  <div className={`h-2 ${theme.isLightTheme ? 'bg-stone-200' : 'bg-stone-700'} rounded`}></div>
                  <div className={`h-2 ${theme.isLightTheme ? 'bg-stone-200' : 'bg-stone-700'} rounded`}></div>
                </div>
                <div className={`h-2 ${theme.isLightTheme ? 'bg-stone-200' : 'bg-stone-700'} rounded`}></div>
              </div>
            </div>
          </div>
        </div>
      )}
      {!withdrawReq.isLoading && (
        <div
          className={`h-fit min-h-32 w-full max-w-full rounded-xl border-[1px] p-4 grid grid-cols-2 grid-rows-1 items-center justify-between ${theme.isLightTheme ? 'bg-stone-50 border-stone-200' : 'bg-stone-900 border-stone-800'}`}
        >
          <div className={`flex flex-col items-start justify-start gap-2 grow`}>
            <p
              className={`w-fit max-w-full font-Suisse-Intl font-[450] text-base ${theme.isLightTheme ? 'text-stone-500' : 'text-stone-400'} text-wrap`}
            >
              UNSTAKE #{props.reqId + 1}
            </p>
            <div className="w-fit max-w-full rounded flex gap-1 items-center justify-start text-wrap">
              <img src={`${process.env.PUBLIC_URL}/assets/tokens/USN.svg`} className="w-6 h-6 inline" alt="USN" />
              <p
                className={`font-Suisse-Intl font-[450] text-lg ${theme.isLightTheme ? 'text-stone-900' : 'text-stone-50'} text-wrap`}
              >
                {withdrawReq.data?.humanized || '0.00'} USN
              </p>
            </div>
            <p
              className={`w-fit max-w-full font-Suisse-Intl font-[450] text-sm ${theme.isLightTheme ? 'text-stone-400' : 'text-stone-600'} text-wrap`}
            >
              {moment(new Date(withdrawReq.data?.timestamp || Date.now()))
                .utc()
                .format('Do MMM YYYY • hh:mm A UTC')}
            </p>
          </div>
          <div className={`flex flex-col items-end justify-start gap-2 grow content-end`}>
            {withdrawReq.data?.claimed && (
              <>
                <p
                  className={`w-fit max-w-full rounded-md py-[2px] px-2 font-Suisse-Intl font-medium text-sm ${theme.isLightTheme ? 'text-green-700 bg-green-200' : 'text-green-200 bg-green-1000'} text-right`}
                >
                  SUCCESSFUL
                </p>
              </>
            )}
            {unlockTime > currentTime && !withdrawReq.data?.claimed && (
              <>
                <Countdown
                  date={unlockTime}
                  className={`w-fit max-w-full font-Suisse-Intl font-[450] text-xl ${theme.isLightTheme ? 'text-stone-900' : 'text-stone-50'} text-right`}
                />
                <p
                  className={`w-fit max-w-full rounded-md py-[2px] px-2 font-Suisse-Intl font-medium text-sm ${theme.isLightTheme ? 'bg-warning-200 text-warning-950' : 'bg-warning-950 text-warning-200'} text-right`}
                >
                  IN PROCESS
                </p>
              </>
            )}
            {unlockTime < currentTime && !withdrawReq.data?.claimed && (
              <>
                <ConnectButton
                  isSmall={true}
                  actionIcon={''}
                  label={btnLabel}
                  isLoading={isLoading}
                  isDisabled={isDisabled}
                  onClick={onWithdrawUsnCb}
                  useResponsiveSizes={false}
                  actionType="primary-action"
                />
                <p
                  className={`w-fit max-w-full rounded-md py-[2px] px-2 font-Suisse-Intl font-medium text-sm ${theme.isLightTheme ? 'text-green-700 bg-green-200' : 'text-green-200 bg-green-1000'} text-right`}
                >
                  SUCCESSFUL
                </p>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export default WithdrawReqInfo
