import { formatUnits } from 'viem'

import { EApiCallStatus, ETxnStatus, TAmount, TBridgeQuote, TScreen } from '../types'
import { rainbowKitConfig } from '../constants/rainbowKitConfig'
import { waitForTransactionReceipt } from '@wagmi/core'

export const formatAmount = (num: bigint, decimals: number): TAmount => {
  if (!num || num <= BigInt(0)) {
    return {
      formatted: '',
      humanized: '',
      abbrevated: '',
      amount: BigInt(0),
    }
  }

  const formatted = formatBigNumber(num, decimals)
  const humanized = formatBigNumber(num, decimals, true)
  const abbrevated = formatBigNumberCompact(num, decimals, true)

  return {
    formatted,
    humanized,
    abbrevated,
    amount: num,
  }
}

export const formatBigNumber = (num: bigint, decimals: number, humanize: boolean = true) => {
  const amount = formatUnits(num || BigInt(0), decimals)
  return humanize
    ? new Intl.NumberFormat('en-US', {
        minimumIntegerDigits: 1,
        maximumFractionDigits: 3,
        minimumFractionDigits: 2,
        roundingMode: 'floor',
      }).format(amount as `${number}`)
    : amount.toString()
}

export const formatBigNumberCompact = (num: bigint, decimals: number, humanize: boolean = true) => {
  const amount = formatUnits(num || BigInt(0), decimals)
  return humanize
    ? new Intl.NumberFormat('en-US', {
        notation: 'compact',
        minimumIntegerDigits: 1,
        compactDisplay: 'short',
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
        roundingMode: 'floor',
      }).format(amount as `${number}`)
    : amount.toString()
}

export const formatStringToNumber = (str: string, humanize: boolean = true) => {
  const amount = str ? Number(str || '0') : 0
  return humanize
    ? new Intl.NumberFormat('en-US', {
        minimumIntegerDigits: 1,
        minimumFractionDigits: 3,
        maximumFractionDigits: 3,
        roundingMode: 'floor',
      }).format(amount)
    : amount.toString()
}

export const formatStringToNumberNoDecimal = (str: string, humanize: boolean = true) => {
  const amount = Math.floor(str ? Number(str || '0') : 0)
  return humanize
    ? new Intl.NumberFormat('en-US', {
        minimumIntegerDigits: 1,
        minimumFractionDigits: 3,
        maximumFractionDigits: 3,
        roundingMode: 'floor',
      }).format(amount)
    : amount.toString()
}

export const formatStringToNumber3 = (str: string, humanize: boolean = true) => {
  const amount = str ? Number(str || '0') : 0
  return humanize
    ? new Intl.NumberFormat('en-US', {
        minimumIntegerDigits: 1,
        minimumFractionDigits: 3,
        maximumFractionDigits: 3,
        roundingMode: 'floor',
      }).format(amount)
    : amount.toString()
}

export const onErrorFeedback = (
  defaultBtnLabel: string,
  setBtnLabel: (label: string) => void,
  setTxnStatus: (status: ETxnStatus) => void,
) => {
  setBtnLabel('Try again')
  setTxnStatus(ETxnStatus.TXN_FAILURE)
  setTimeout(() => {
    setBtnLabel(defaultBtnLabel)
    setTxnStatus(ETxnStatus.IDLE)
  }, 5 * 1000)
}

export const onSuccessFeedback = (
  defaultBtnLabel: string,
  setBtnLabel: (label: string) => void,
  setTxnStatus: (status: ETxnStatus) => void,
) => {
  setBtnLabel(defaultBtnLabel)
  setTxnStatus(ETxnStatus.TXN_SUBMITTED_ON_CHAIN)
}

export const onSettledFeedback = async (
  txnHash: unknown,
  defaultBtnLabel: string,
  defaultSuccessTxt: string,
  setBtnLabel: (label: string) => void,
  setTxnStatus: (status: ETxnStatus) => void,
  nextScreen?: TScreen,
  setNextScreen?: (screen: TScreen) => void,
) => {
  if (!txnHash) {
    setBtnLabel(defaultBtnLabel)
    setTxnStatus(ETxnStatus.IDLE)
    return
  }

  const receipt = await waitForTransactionReceipt(rainbowKitConfig, { hash: txnHash as `0x${string}` }).catch(() => ({
    status: 'unknown',
  }))
  if (receipt.status === 'success') {
    setBtnLabel(defaultSuccessTxt)
    setTxnStatus(ETxnStatus.TXN_SUCCESS)
    if (setNextScreen) {
      setNextScreen(nextScreen || 'confirm')
    }
  } else if (receipt.status === 'unknown') {
    setBtnLabel('Taking a while, check after some time...')
    setTxnStatus(ETxnStatus.TXN_SUBMITTED_ON_CHAIN)
    if (setNextScreen) {
      setNextScreen(nextScreen || 'confirm')
    }
  } else {
    setBtnLabel('Try again')
    setTxnStatus(ETxnStatus.TXN_FAILURE)
    setTimeout(() => {
      setBtnLabel(defaultBtnLabel)
      setTxnStatus(ETxnStatus.IDLE)
    }, 5 * 1000)
  }
}

export const onApiErrorFeedback = (
  defaultBtnLabel: string,
  setBtnLabel: (label: string) => void,
  setTxnStatus: (status: EApiCallStatus) => void,
) => {
  setBtnLabel('Try again')
  setTxnStatus(EApiCallStatus.API_CALL_FAILURE)
  setTimeout(() => {
    setBtnLabel(defaultBtnLabel)
    setTxnStatus(EApiCallStatus.IDLE)
  }, 5 * 1000)
}

export const onApiSuccessFeedbackAction = (
  defaultBtnLabel: string,
  setBtnLabel: (label: string) => void,
  setTxnStatus: (status: EApiCallStatus) => void,
  nextScreen?: TScreen,
  setNextScreen?: (screen: TScreen) => void,
) => {
  setBtnLabel(defaultBtnLabel)
  setTxnStatus(EApiCallStatus.API_CALL_SUCCESS)
  if (setNextScreen) {
    setNextScreen(nextScreen || 'confirm')
  }
}

export const DEFAULT_BRIDGE_QUOTE: TBridgeQuote = { nativeFee: BigInt(0), lzTokenFee: BigInt(0) }

export const REFETCH_INTERVAL = 2 * 60 * 1000
export const HUNDRED_PERCENT_IN_BN = BigInt(1e6)
export const SLIPPAGE_PERCEINT_IN_BN = BigInt(1e4)

export const getAmountPostSlippage = (amount: bigint | undefined) => {
  if (!amount || amount < BigInt(0)) {
    return BigInt(0)
  }

  return (amount * (HUNDRED_PERCENT_IN_BN - SLIPPAGE_PERCEINT_IN_BN)) / HUNDRED_PERCENT_IN_BN
}

const UNI_FEE_SIZE = 3
export function encodePath(path: string[], fees: bigint[]): string {
  if (path.length != fees.length + 1) {
    throw new Error('path/fee lengths do not match')
  }

  let encoded = '0x'
  for (let i = 0; i < fees.length; i++) {
    // 20 byte encoding of the address
    encoded += path[i].slice(2)
    // 3 byte encoding of the fee
    encoded += fees[i].toString(16).padStart(2 * UNI_FEE_SIZE, '0')
  }
  // encode the final token
  encoded += path[path.length - 1].slice(2)

  return encoded.toLowerCase()
}
