import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'

import { useDisclosure } from '@chakra-ui/react'

import { useChains, useSwitchChain } from 'wagmi'
import { mainnet } from 'viem/chains'

import { isAddress } from 'viem'

import { ReactComponent as DoubleChevroRightIcon } from '../../assets/navigation/double-right-chevron.svg'
import { ReactComponent as ArrowSwapHorizontal } from '../../assets/action/arrow-swap-horizontal.svg'
import { ReactComponent as ChevronBigDownIcon } from '../../assets/navigation/chevron-big-down.svg'
import { ReactComponent as InfoIcon } from '../../assets/info/info-circle.svg'
import useBridgeApprove from '../../hooks/multichain/txns/useBridgeApprove'
import useBridgeAllowance from '../../hooks/multichain/useBridgeAllowance'
import useTokenBalance from '../../hooks/multichain/useTokenBalance'
import { rainbowKitConfig } from '../../constants/rainbowKitConfig'
import BridgeReceiveAddressModal from './BridgeReceiveAddressModal'
import BridgeChainSelectorModal from './BridgeChainSelectorModal'
import useBridgeFee from '../../hooks/multichain/useBridgeFee'
import useBridgeGas from '../../hooks/multichain/useBridgeGas'
import { BridgeContext } from '../../context/BridgeProvider'
import BridgeReceiveInput from '../Input/BridgeReceiveInput'
import { ThemeContext } from '../../context/ThemeProvider'
import { EApiCallStatus, ETxnStatus } from '../../types'
import usesUsnPrice from '../../hooks/usesUsnPrice'
import NetworkFee from '../NetworkFee/NetworkFee'
import ConnectButton from '../ConnectButton'
import { sophon } from '../../constants'
import Input from '../Input'

const InputAndBridge = () => {
  const [btnLabel, setBtnLabel] = useState<string>('Approve')
  const [isApproved, setIsApproved] = useState<boolean>(false)
  const [isSourceModal, setIsSourceModal] = useState<boolean>(false)

  const gas = useBridgeGas()
  const quote = useBridgeFee()
  const sUsnPrice = usesUsnPrice()
  const allowance = useBridgeAllowance()
  const theme = useContext(ThemeContext)
  const modalDisclosure = useDisclosure()
  const bridge = useContext(BridgeContext)
  const balance = useTokenBalance(bridge.token)
  const addressModalDisclosure = useDisclosure()
  const onApproveCb = useBridgeApprove(bridge.token)
  const chains = useChains({ config: rainbowKitConfig })
  const switchChain = useSwitchChain({ config: rainbowKitConfig })

  const swapNetworks = useCallback(() => {
    const sourceNetwork = bridge.destination
    const destinationNetwork = bridge.source
    bridge.setChainChangeStatus(EApiCallStatus.PROCESSING)

    switchChain.switchChain(
      { chainId: sourceNetwork as 1 | 50104 | 324 },
      {
        onError: () => {
          bridge.setChainChangeStatus(EApiCallStatus.API_CALL_FAILURE)
          setTimeout(() => {
            bridge.setChainChangeStatus(EApiCallStatus.IDLE)
          }, 30 * 1000)
        },
        onSuccess: () => {
          bridge.setChainChangeStatus(EApiCallStatus.PROCESSING)
        },
        onSettled: async (data) => {
          if (!data || data.id !== sourceNetwork) {
            bridge.setChainChangeStatus(EApiCallStatus.API_CALL_FAILURE)
            setTimeout(() => {
              bridge.setChainChangeStatus(EApiCallStatus.IDLE)
            }, 30 * 1000)
          } else {
            bridge.setDestination(destinationNetwork)
            bridge.setChainChangeStatus(EApiCallStatus.API_CALL_SUCCESS)
          }
        },
      },
    )
  }, [switchChain])

  const tokenPrice = useMemo(() => {
    if (bridge.token === 'USN') {
      return 1
    }

    return Number(sUsnPrice.data.sUsnPrice)
  }, [sUsnPrice, bridge.token])

  const sourceName = useMemo(() => {
    return (chains.find((c) => c.id === bridge.source) || mainnet).name
  }, [bridge.source])

  const destinationName = useMemo(() => {
    return (chains.find((c) => c.id === bridge.destination) || sophon).name
  }, [bridge.destination])

  const isLoading = useMemo(() => {
    return (
      quote.isFetching ||
      balance.isFetching ||
      allowance.isFetching ||
      bridge.approveTxnStatus === ETxnStatus.PROCESSING ||
      bridge.chainChangeStatus === EApiCallStatus.PROCESSING ||
      bridge.approveTxnStatus === ETxnStatus.TXN_SUBMITTED_ON_CHAIN
    )
  }, [quote.isFetching, balance.isFetching, allowance.isFetching, bridge.approveTxnStatus, bridge.chainChangeStatus])

  const isDisabled = useMemo(
    () =>
      isLoading ||
      !bridge.amount ||
      !Number(bridge.amount) ||
      !bridge.receiverAddress ||
      !isAddress(bridge.receiverAddress),
    [isLoading, bridge.amount, bridge.receiverAddress],
  )

  useEffect(() => {
    bridge.setQuote(quote.data)
  }, [quote])

  useEffect(() => {
    if (isApproved) {
      setBtnLabel('Next')
      return
    }

    if (bridge.approveTxnStatus === ETxnStatus.IDLE) {
      setBtnLabel('Approve')
      return
    }

    if (
      bridge.approveTxnStatus === ETxnStatus.PROCESSING ||
      bridge.approveTxnStatus === ETxnStatus.TXN_SUBMITTED_ON_CHAIN
    ) {
      setBtnLabel('Approving...')
      return
    }

    if (bridge.approveTxnStatus === ETxnStatus.TXN_FAILURE) {
      setBtnLabel('Try again')
      return
    }

    if (bridge.approveTxnStatus === ETxnStatus.TXN_SUCCESS) {
      setBtnLabel('Next')
      bridge.setScreen('confirm')
      return
    }
  }, [isApproved, bridge.approveTxnStatus])

  const onOpenChainSelectorModal = (_isSourceModal: boolean) => {
    setIsSourceModal(_isSourceModal)
    modalDisclosure.onOpen()
  }

  const onApproveOrNextCb = useCallback(() => {
    if (isApproved) {
      bridge.setScreen('confirm')
      return
    }
    onApproveCb()
  }, [isApproved, onApproveCb])

  return (
    <React.Fragment>
      <div
        className={`relative w-full max-w-full h-fit gap-1 grid grid-rows-2 md:grid-cols-2 md:grid-rows-1 md:items-center md:justify-stretch grow`}
      >
        <BridgeChainSelectorModal
          isSourceModal={isSourceModal}
          isModalOpen={modalDisclosure.isOpen}
          onCloseModal={modalDisclosure.onClose}
        />
        <BridgeReceiveAddressModal
          isModalOpen={addressModalDisclosure.isOpen}
          onCloseModal={addressModalDisclosure.onClose}
        />

        <div
          onClick={() => onOpenChainSelectorModal(true)}
          className={`rounded-lg gap-2 p-4 flex items-center justify-between cursor-pointer ${theme.isLightTheme ? 'bg-white hover:bg-stone-100' : 'bg-stone-800 hover:bg-stone-700'}`}
        >
          <div className={`w-auto flex items-center justify-start grow gap-2`}>
            <div className={`relative h-6 w-6`}>
              <img className="w-full h-full" src={`${process.env.PUBLIC_URL}/assets/chains/${bridge.source}.svg`} />
            </div>
            <p
              className={`font-Suisse-Intl font-[450] text-base ${theme.isLightTheme ? 'text-stone-900' : 'text-stone-50'}`}
            >
              {sourceName}
            </p>
          </div>
          <div className={`h-6 w-6 ${theme.isLightTheme ? 'text-stone-700' : 'text-stone-500'}`}>
            <ChevronBigDownIcon className="w-full h-full" />
          </div>
        </div>
        <div
          onClick={() => onOpenChainSelectorModal(false)}
          className={`rounded-lg gap-2 p-4 flex items-center justify-between cursor-pointer ${theme.isLightTheme ? 'bg-white hover:bg-stone-100' : 'bg-stone-800 hover:bg-stone-700'}`}
        >
          <div className={`w-auto flex items-center justify-start grow gap-2 `}>
            <div className={`h-6 w-6`}>
              <img
                className="w-full h-full"
                src={`${process.env.PUBLIC_URL}/assets/chains/${bridge.destination}.svg`}
              />
            </div>
            <p
              className={`font-Suisse-Intl font-[450] text-base ${theme.isLightTheme ? 'text-stone-900' : 'text-stone-50'}`}
            >
              {destinationName}
            </p>
          </div>
          <div className={`h-6 w-6 ${theme.isLightTheme ? 'text-stone-700' : 'text-stone-500'}`}>
            <ChevronBigDownIcon className="w-full h-full" />
          </div>
        </div>

        <button
          onClick={swapNetworks}
          className={`h-6 w-6 rounded-[72px] absolute top-[50%] left-[50%] z-50 -translate-x-[50%] -translate-y-[50%] p-[3px] rotate-90 md:rotate-0 bridge-main-network-double-arrow-icon ${theme.isLightTheme ? 'bg-stone-200 text-stone-700' : 'bg-stone-900 text-stone-50'}`}
        >
          <DoubleChevroRightIcon className="w-full h-full" />
        </button>
      </div>

      <div className="relative w-full flex flex-col bg-transaparent gap-1 items-center justify-center">
        <Input
          tokenPrice={tokenPrice}
          showAccount={true}
          canEditAccount={true}
          amount={bridge.amount}
          balance={balance.data}
          label={"YOU'RE SENDING"}
          tokens={['USN', 'sUSN']}
          isAmountGtBalance={false}
          chainIcon={bridge.source}
          setToken={bridge.setToken}
          allowance={allowance.data}
          setScreen={bridge.setScreen}
          setAmount={bridge.setAmount}
          setIsApproved={setIsApproved}
          token={bridge.token as string}
          setIsAmountGtBalance={() => {}}
        />

        <button
          onClick={swapNetworks}
          className={`h-10 w-10 md:h-14 md:w-14 rounded-[12px] absolute top-[calc(62%)] left-[50%] z-50 -translate-x-[50%] -translate-y-[50%] p-[12px] bridge-swap-icon ${!theme.isLightTheme ? 'bg-stone-800 text-stone-50' : 'bg-white border-stone-300 text-indigo-600  border-[1.4px]'}`}
        >
          <ArrowSwapHorizontal className="w-full h-full" />
        </button>

        <BridgeReceiveInput
          tokens={[]}
          tokenPrice={0}
          showAccount={true}
          hideBalance={true}
          hideDollars={true}
          balance={BigInt(0)}
          setToken={() => {}}
          allowance={BigInt(0)}
          amount={bridge.amount}
          isAmountGtBalance={false}
          label={"YOU'RE RECEIVING"}
          setScreen={bridge.setScreen}
          setAmount={bridge.setAmount}
          token={bridge.token as string}
          chainIcon={bridge.destination}
          receiveAddress={bridge?.receiverAddress || ''}
          setAddressModalOpen={() => addressModalDisclosure.onOpen()}
        />
      </div>

      <div
        className={`h-full w-full max-w-full flex items-start justify-start rounded-[10px] p-3 gap-2 ${theme.isLightTheme ? 'bg-stone-100' : 'bg-stone-800'}`}
      >
        <div className={`w-6 h-6 ${theme.isLightTheme ? 'text-stone-900' : 'text-stone-200'} shrink-0`}>
          <InfoIcon className="w-full h-full" />
        </div>
        <div className="grow flex flex-col items-start justify-start gap-[2px]">
          <p
            className={`font-Suisse-Intl font-[450] text-base leading-6 ${theme.isLightTheme ? 'text-stone-900' : 'text-stone-200'} text-wrap`}
          >{`Before proceeding, please ensure that you can access the wallet address on the receiving chain.`}</p>
          <p className="font-Suisse-Intl font-[450] text-sm leading-5 text-stone-500 text-wrap">{`This is especially important if you use wallets like Gnosis Safe. If you cannot access this address, please change the receiving wallet address to avoid any issues.`}</p>
        </div>
      </div>

      <div
        className={`w-full h-fit flex flex-col items-center justify-center grow rounded-lg gap-4 p-4 ${theme.isLightTheme ? 'bg-white' : 'bg-transparent'}`}
      >
        <div className="w-full grid grid-rows-1 grid-cols-2 gap-2">
          <NetworkFee type={'data-field'} action={'bridge'} chainId={bridge.source} gasLimit={gas.data} />

          <div className="w-fit max-w-full flex gap-2 items-center justify-end justify-self-end">
            <p
              className={`font-Suisse-Intl font-[450] text-base text-right ${theme.isLightTheme ? 'text-stone-500' : 'text-stone-400'}`}
            >
              Max Slippage
            </p>
            <p
              className={`font-Suisse-Intl rounded-[4px] py-[2px] px-[6px] font-[450] text-base text-center ${theme.isLightTheme ? 'text-stone-500 bg-stone-200' : 'text-stone-400 bg-stone-900'}`}
            >
              1%
            </p>
          </div>
        </div>

        <ConnectButton
          label={btnLabel}
          isLoading={isLoading}
          isDisabled={isDisabled}
          useResponsiveSizes={false}
          actionType="primary-action"
          onClick={onApproveOrNextCb}
          useFullAvailableWidth={true}
        />
      </div>
    </React.Fragment>
  )
}

export default InputAndBridge
