import { useCallback, useContext } from 'react'

import { Config, useConnectorClient } from 'wagmi'

import { estimateMaxPriorityFeePerGas, waitForTransactionReceipt } from '@wagmi/core'

import { createWalletClient, custom, fromBytes, isAddress, parseUnits } from 'viem'
import { eip712WalletActions, getGeneralPaymasterInput } from 'viem/zksync'

import OftHelperAbi from '../../../constants/abis/LayerZeroSophonHelperABI.json'
import OftAbi from '../../../constants/abis/OFT.json'

import { DEFAULT_GAS_PER_PUBDATA_LIMIT } from 'zksync-ethers/build/utils'

import { mainnet } from 'viem/chains'

import { addressToBytes32, Options } from '@layerzerolabs/lz-v2-utilities'

import { rainbowKitConfig } from '../../../constants/rainbowKitConfig'
import { BridgeContext } from '../../../context/BridgeProvider'
import { DAPP_CONFIG, sophon } from '../../../constants'
import { getAmountPostSlippage } from '../../../utils'
import { ETxnStatus } from '../../../types'

function useBridge(setTxnUrl: (txnHash: string) => void): () => Promise<void> {
  const bridge = useContext(BridgeContext)
  const connector = useConnectorClient<Config>({ config: rainbowKitConfig })

  const write = useCallback(async (): Promise<void> => {
    if (
      !bridge ||
      !connector ||
      !bridge.token ||
      !connector.data ||
      !connector.data.chain ||
      !connector.data.account ||
      !bridge.receiverAddress ||
      !connector.data.transport ||
      !isAddress(bridge.receiverAddress)
    ) {
      bridge.setBridgeTxnStatus(ETxnStatus.TXN_FAILURE)
      return
    }

    const chainId = connector.data.chain.id as 1 | 50104 | 324
    const config = DAPP_CONFIG[chainId]
    if (!chainId || !config) {
      bridge.setBridgeTxnStatus(ETxnStatus.TXN_FAILURE)
      return
    }
    const _token = config.tokens[bridge.token]
    const amountBn = parseUnits(bridge.amount, _token.decimals)
    if (!_token || !amountBn || !_token.oftAddress) {
      bridge.setBridgeTxnStatus(ETxnStatus.TXN_FAILURE)
      return
    }
    const _destination = DAPP_CONFIG[bridge.destination]
    if (!_destination) {
      bridge.setBridgeTxnStatus(ETxnStatus.TXN_FAILURE)
      return
    }

    bridge.setBridgeTxnStatus(ETxnStatus.PROCESSING)

    try {
      let txnHash: string
      const contractAddress = config.layerZeroOftHelper || _token.oftAddress

      if (config.paymaster) {
        const wallet = createWalletClient({
          transport: custom(connector.data.transport),
          chain: connector.data.chain as typeof sophon,
        }).extend(eip712WalletActions())
        const options = Options.newOptions().addExecutorLzReceiveOption(BigInt(65000), 0).toHex()
        const params = [
          _destination.oftEid,
          fromBytes(addressToBytes32(bridge.receiverAddress), 'hex'),
          amountBn,
          getAmountPostSlippage(amountBn),
          options,
          '0x',
          '0x',
        ]
        txnHash = await wallet.writeContract({
          abi: OftHelperAbi,
          functionName: 'send',
          address: contractAddress,
          paymaster: config.paymaster,
          account: connector.data.account,
          args: [_token.contractAddress, params],
          gasPerPubdata: BigInt(DEFAULT_GAS_PER_PUBDATA_LIMIT || 0),
          maxPriorityFeePerGas: await estimateMaxPriorityFeePerGas(rainbowKitConfig, { chainId }),
          paymasterInput: getGeneralPaymasterInput({
            innerInput: '0x',
          }),
        })
      } else {
        const wallet = createWalletClient({
          transport: custom(connector.data.transport),
          chain: connector.data.chain as typeof mainnet,
        })
        const contractAddress = config.layerZeroOftHelper || _token.oftAddress
        // Hardcoding extra-options
        const params = [
          _destination.oftEid,
          fromBytes(addressToBytes32(bridge.receiverAddress), 'hex'),
          amountBn,
          amountBn,
          '0x0003010011010000000000000000000000000000fde8',
          '0x',
          '0x',
        ]

        txnHash = await wallet.writeContract({
          abi: OftAbi,
          functionName: 'send',
          address: contractAddress,
          value: bridge.quote.nativeFee,
          account: connector.data.account!,
          maxPriorityFeePerGas: await estimateMaxPriorityFeePerGas(rainbowKitConfig, { chainId }),
          args: [params, [bridge.quote.nativeFee, bridge.quote.lzTokenFee], connector.data.account.address],
        })
      }

      bridge.setBridgeTxnStatus(ETxnStatus.TXN_SUBMITTED_ON_CHAIN)

      const receipt = await waitForTransactionReceipt(rainbowKitConfig, { hash: txnHash as `0x${string}` }).catch(
        () => ({
          status: 'unknown',
        }),
      )
      console.log('Receipt', receipt)
      if (receipt.status === 'success') {
        const url = connector.data.chain.blockExplorers?.default.url
          ? `${connector.data.chain.blockExplorers?.default.url}/tx/${txnHash as string}`
          : '/dashboard'
        setTxnUrl(url)
        bridge.setBridgeTxnStatus(ETxnStatus.TXN_SUCCESS)
      } else if (receipt.status === 'reverted') {
        bridge.setBridgeTxnStatus(ETxnStatus.TXN_FAILURE)
      } else {
        bridge.setBridgeTxnStatus(ETxnStatus.TXN_SUCCESS)
      }
    } catch {
      bridge.setBridgeTxnStatus(ETxnStatus.TXN_FAILURE)
    }
  }, [connector])

  return write
}

export default useBridge
