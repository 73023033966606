import { mainnet } from '@wagmi/core/chains'

import { useAccount, useChainId, useReadContract } from 'wagmi'

import ERC20Abi from '../../constants/abis/ERC20.json'

import { rainbowKitConfig } from '../../constants/rainbowKitConfig'
import { DAPP_CONFIG } from '../../constants'
import { TAddress } from '../../types'

const useStakingAllowance = (token: string, spender: TAddress) => {
  const chainId = useChainId({ config: rainbowKitConfig })
  const account = useAccount({ config: rainbowKitConfig })

  const query = useReadContract({
    abi: ERC20Abi,
    chainId: mainnet.id,
    config: rainbowKitConfig,
    functionName: 'allowance',
    args: [account.address!, spender],
    address: DAPP_CONFIG[mainnet.id].tokens[token].contractAddress,
    query: {
      refetchOnMount: true,
      refetchOnReconnect: true,
      refetchOnWindowFocus: true,
      initialData: () => BigInt(0),
      placeholderData: () => BigInt(0),
      refetchIntervalInBackground: true,
      refetchInterval: DAPP_CONFIG[mainnet.id].dataRefershInterval,
      select: (data) => {
        return (data || BigInt(0)) as bigint
      },
      enabled: chainId === mainnet.id && account.isConnected && !!DAPP_CONFIG[mainnet.id].tokens[token] && !!spender,
    },
  })

  return query
}

export default useStakingAllowance
