import React, { useState } from 'react'

import { mainnet } from 'viem/chains'

import { useDisclosure } from '@chakra-ui/react'

import { InputAndApproveMint, MintConfirm, MintRequestInfoModal, MintSuccessful } from '../../components/Mint'
import MintRequestsCarousel from '../../components/Mint/MintRequestsCarousel'
import { TMintReq, TUsnScreen, TUsnTab } from '../../types'
import ActionCard from '../../components/ActionCard'
import { DAPP_CONFIG } from '../../constants'

interface IProps {
  screen: TUsnScreen
  setTab: (tab: TUsnTab) => void
  pendingMintRequests: TMintReq[]
  setScreen: (screen: TUsnScreen) => void
}

const Mint = (props: IProps) => {
  const { screen, setScreen, setTab } = props

  const [mintRequestInfo, setMintRequestInfo] = useState<TMintReq>()
  const [collateralAmount, setCollateralAmount] = useState<string>('')
  const [selectedCollateral, setSelectedCollateral] = useState<string>(DAPP_CONFIG[mainnet.id].tokensForMint[0])

  const { isOpen, onOpen, onClose } = useDisclosure()

  const openModal = (_mintRequestInfo: TMintReq) => {
    setMintRequestInfo(_mintRequestInfo)
    onOpen()
  }

  return (
    <div className="w-full max-w-full flex flex-col items-start justify-start gap-4">
      <MintRequestInfoModal
        isOpen={isOpen}
        setTab={setTab}
        onClose={onClose}
        setScreen={setScreen}
        mintRequestInfo={mintRequestInfo || ({} as TMintReq)}
      />

      <ActionCard screen={screen} setScreen={props.setScreen}>
        {screen === 'input' ? (
          <InputAndApproveMint
            setScreen={setScreen}
            collateralAmount={collateralAmount}
            selectedCollateral={selectedCollateral}
            selectCollateral={setSelectedCollateral}
            setCollateralAmount={setCollateralAmount}
          />
        ) : screen === 'confirm' ? (
          <MintConfirm
            setScreen={setScreen}
            collateralAmount={collateralAmount}
            selectedCollateral={selectedCollateral}
          />
        ) : (
          <MintSuccessful setScreen={setScreen} collateralAmount={collateralAmount} />
        )}
      </ActionCard>

      <MintRequestsCarousel
        openMintRequstInfoModal={openModal}
        setMintRequestInfo={setMintRequestInfo}
        pendingMintRequests={props.pendingMintRequests?.length ? props.pendingMintRequests : []}
      />
    </div>
  )
}

export default Mint
