import React, { useContext, useState } from 'react'

import InputAndApproveUnStake from './InputAndApproveUnStake'
import { ThemeContext } from '../../context/ThemeProvider'
import UnStakeSuccessful from './UnStakeSuccessful'
import { TsUsnScreen, TsUsnTab } from '../../types'
import UnStakeConfirm from './UnStakeConfirm'
import StakeInfo from '../StakeInfo'

interface IProps {
  screen: TsUsnScreen
  refetchStakedBalance: boolean
  setTab: (tab: TsUsnTab) => void
  setScreen: (screen: TsUsnScreen) => void
}

const Stake = (props: IProps) => {
  const theme = useContext(ThemeContext)

  const [usnAmount, setUsnAmount] = useState<string>('')
  const [sUsnAmount, setsUsnAmount] = useState<string>('')
  const [blockExplorerTxnUrl, setBlockExplorerTxnUrl] = useState<string>('')

  return (
    <div
      className={`w-full max-w-full flex flex-col items-start justify-start gap-0 rounded-xl border-[1px] ${theme.isLightTheme ? 'border-stone-300' : 'border-stone-800'} overflow-hidden action-card-shadow`}
    >
      {props.screen === 'input' ? (
        <div
          className={`w-full max-w-full h-fit flex flex-col items-start justify-start rounded-xl !rounded-b-none border-0 p-2 pb-6 gap-6 ${theme.isLightTheme ? 'bg-white border-stone-300' : 'bg-stone-900 border-stone-800'}`}
        >
          <InputAndApproveUnStake
            usnAmount={usnAmount}
            sUsnAmount={sUsnAmount}
            setUsnAmount={setUsnAmount}
            setsUsnAmount={setsUsnAmount}
            setScreen={props.setScreen}
          />
        </div>
      ) : props.screen === 'confirm' ? (
        <div
          className={`w-full max-w-full h-fit flex flex-col items-start justify-start rounded-none !rounded-t-xl border-0 !border-t-[1px] py-3 px-4 md:p-6 gap-6 ${theme.isLightTheme ? 'bg-white border-stone-300' : 'bg-stone-950 border-stone-800'}`}
        >
          <UnStakeConfirm
            usnAmount={usnAmount}
            sUsnAmount={sUsnAmount}
            setScreen={props.setScreen}
            setBlockExplorerTxnUrl={setBlockExplorerTxnUrl}
          />
        </div>
      ) : (
        <div
          className={`w-full max-w-full h-fit flex flex-col items-start justify-start rounded-none !rounded-t-xl border-0 !border-t-[1px] py-3 px-4 md:p-6 gap-6 ${theme.isLightTheme ? 'bg-white border-stone-300' : 'bg-stone-950 border-stone-800'}`}
        >
          <UnStakeSuccessful
            setTab={props.setTab}
            usnAmount={usnAmount}
            sUsnAmount={sUsnAmount}
            setScreen={props.setScreen}
            blockExplorerTxnUrl={blockExplorerTxnUrl}
          />
        </div>
      )}

      <StakeInfo refetch={props.refetchStakedBalance} />
    </div>
  )
}

export default Stake
