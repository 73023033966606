import { useQuery } from '@tanstack/react-query'

import { REFETCH_INTERVAL } from '../utils'

const usesUsnPrice = () => {
  const fetcher = async () => {
    return await fetch(`${process.env.REACT_APP_BASE_API_URL || ''}/api/susn-price`)
      .then(
        (res) => {
          if (res.ok) {
            return res.json()
          }
          throw new Error('Failed to fetch sUsn price.')
        },
        (err) => {
          throw new Error(err)
        },
      )
      .then((json) => json as { susnPrice: string })
      .then((data) => ({ sUsnPrice: data?.susnPrice || '0' }))
      .catch(() => {
        return { sUsnPrice: '0' }
      })
  }

  return useQuery({
    enabled: true,
    queryFn: fetcher,
    refetchOnMount: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: true,
    queryKey: ['sUsn', 'price'],
    refetchInterval: REFETCH_INTERVAL,
    refetchIntervalInBackground: false,
    placeholderData: { sUsnPrice: '0' },
    initialData: () => ({ sUsnPrice: '0' }),
    select: (data: unknown) => {
      const price = data as { sUsnPrice: string }
      return { sUsnPrice: price?.sUsnPrice || '0' }
    },
  })
}

export default usesUsnPrice
