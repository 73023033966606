import { mainnet } from '@wagmi/core/chains'

import { useAccount, useChainId, useReadContract } from 'wagmi'

import { parseUnits } from 'viem'

import StakingVaultABI from '../../constants/abis/StakingVault.json'
import { rainbowKitConfig } from '../../constants/rainbowKitConfig'
import { DAPP_CONFIG } from '../../constants'

const usePreviewsUsnRedeem = (sUsnAmount: string) => {
  const chainId = useChainId({ config: rainbowKitConfig })
  const account = useAccount({ config: rainbowKitConfig })

  return useReadContract({
    chainId: mainnet.id,
    abi: StakingVaultABI,
    config: rainbowKitConfig,
    functionName: 'previewRedeem',
    args: [parseUnits(sUsnAmount, 18)],
    address: DAPP_CONFIG[mainnet.id].stakingVault,
    query: {
      refetchOnMount: true,
      initialData: BigInt(0),
      refetchOnReconnect: true,
      refetchOnWindowFocus: true,
      placeholderData: BigInt(0),
      refetchIntervalInBackground: true,
      select: (data) => {
        return (data as bigint) || BigInt(0)
      },
      enabled: chainId === mainnet.id && account.isConnected,
      refetchInterval: DAPP_CONFIG[mainnet.id].dataRefershInterval,
    },
  })
}

export default usePreviewsUsnRedeem
