import { getDefaultConfig } from '@rainbow-me/rainbowkit'
import { QueryClient } from '@tanstack/react-query'
import { mainnet, zksync } from 'wagmi/chains'
import { http } from 'wagmi'

import { sophon } from './index'

export const rainbowKitConfig = getDefaultConfig({
  appName: 'dclf-labs/NOON-Front',
  projectId: process.env.REACT_APP_WALLET_CONNECT_PROJECT_ID ?? '',
  chains: [
    mainnet,
    {
      ...sophon,
      iconUrl: `${process.env.PUBLIC_URL}/assets/chains/sophon.svg`,
    },
    zksync,
  ],
  transports: {
    [mainnet.id]: http('https://lb.drpc.org/ogrpc?network=ethereum&dkey=ArVNF0aajUdyvnFBL6XFHkSyh62QfxUR75OHhlDYfw4q'),
    [50104]: http('https://rpc.sophon.xyz'),
    [zksync.id]: http('https://mainnet.era.zksync.io'),
  },
})

export const tanstackQueryClient = new QueryClient()
