import { Modal, ModalOverlay, ModalContent } from '@chakra-ui/react'

import React, { useCallback, useContext, useMemo, useState } from 'react'

import { useChains, useSwitchChain } from 'wagmi'

import { Chain } from 'viem'

import { ReactComponent as MultipleCrossIcon } from '../../assets/navigation/multiple-cross.svg'
import { rainbowKitConfig } from '../../constants/rainbowKitConfig'
import { BridgeContext } from '../../context/BridgeProvider'
import { ThemeContext } from '../../context/ThemeProvider'
import { EApiCallStatus } from '../../types'
import { bridge } from '../../constants'
import './index.css'

interface IProps {
  isModalOpen: boolean
  isSourceModal: boolean
  onCloseModal: () => void
}

const BridgeChainSelectorModal = (props: IProps) => {
  const [searchedChain, setSearchedNetworkName] = useState<string>('')

  const theme = useContext(ThemeContext)
  const {
    source: currentSource,
    destination: currentDestination,
    setSource,
    setDestination,
    setChainChangeStatus,
  } = useContext(BridgeContext)
  const chains = useChains({ config: rainbowKitConfig })
  const switchChain = useSwitchChain({ config: rainbowKitConfig })

  const bridgeChains = useMemo(() => chains.filter((c) => bridge.includes(c.id)), [bridge, chains])

  const chainsForMatchingSearch = useMemo(() => {
    if (!searchedChain || searchedChain === '') {
      return bridgeChains
    }
    return bridgeChains.filter((chain) => chain.name.toLowerCase().includes(searchedChain.toLowerCase()))
  }, [bridgeChains, searchedChain])

  const onSearchChainByName = (event: React.FormEvent<HTMLInputElement>) => {
    setSearchedNetworkName((event?.target as HTMLInputElement)?.value)
  }

  const switchNetwork = useCallback(
    (sourceNetwork: Chain['id'], destinationNetwork: Chain['id']) => {
      setChainChangeStatus(EApiCallStatus.PROCESSING)

      switchChain.switchChain(
        { chainId: sourceNetwork as 1 | 50104 | 324 },
        {
          onError: () => {
            setChainChangeStatus(EApiCallStatus.API_CALL_FAILURE)
            setTimeout(() => {
              setChainChangeStatus(EApiCallStatus.IDLE)
            }, 30 * 1000)
          },
          onSuccess: () => {
            setChainChangeStatus(EApiCallStatus.PROCESSING)
          },
          onSettled: async (data) => {
            if (!data || data.id !== sourceNetwork) {
              setChainChangeStatus(EApiCallStatus.API_CALL_FAILURE)
              setTimeout(() => {
                setChainChangeStatus(EApiCallStatus.IDLE)
              }, 30 * 1000)
            } else {
              setChainChangeStatus(EApiCallStatus.API_CALL_SUCCESS)
              // setTimeout(() => {
              //   // setSource(sourceNetwork)
              //   // setDestination(destinationNetwork)
              //   setChainChangeStatus(EApiCallStatus.IDLE)
              // }, 30 * 1000)
            }
          },
        },
      )
    },
    [switchChain, setChainChangeStatus],
  )

  const onChangeNetwork = useCallback(
    (chain: Chain['id']) => {
      const otherNetwork = bridgeChains.filter((c) => c.id !== chain).at(0)!
      if (props.isSourceModal) {
        switchNetwork(chain, currentDestination === chain ? otherNetwork.id : currentDestination)
      } else {
        if (currentSource === chain) {
          switchNetwork(currentSource === chain ? otherNetwork.id : currentSource, chain)
        } else {
          setDestination(chain)
        }
      }

      props.onCloseModal()
    },
    [props.isSourceModal, switchNetwork, currentSource, currentDestination],
  )

  return (
    <Modal isOpen={props.isModalOpen} onClose={props.onCloseModal} autoFocus={false}>
      <ModalOverlay />
      <ModalContent
        borderRadius={'8px'}
        background={theme.isLightTheme ? 'bg-white' : 'bg-stone-800'}
        className={`relative top-44 w-full rounded-lg p-6 gap-4 ${theme.isLightTheme ? 'bg-white' : 'bg-stone-800'} chain-selection-modal-shadow`}
      >
        <div
          onClick={props.onCloseModal}
          className={`absolute h-5 w-5 cursor-pointer ${theme.isLightTheme ? 'text-stone-400' : 'text-stone-500'} right-5 top-5 outline-none`}
        >
          <MultipleCrossIcon className="w-full h-full" />
        </div>

        <div className="w-full gap-1 flex flex-col items-start justify-center">
          <p
            className={`w-full font-Suisse-Intl font-[450] text-base ${theme.isLightTheme ? 'text-stone-700' : 'text-stone-100'} text-left`}
          >
            Search Networks
          </p>
          <input
            type="text"
            value={searchedChain}
            onChange={onSearchChainByName}
            placeholder="Enter the chain name"
            className={`rounded-lg border-[1px] py-3 px-4 bg-transaparent ${theme.isLightTheme ? 'border-stone-200' : 'border-stone-700'} w-full font-Suisse-Intl font-[450] text-base ${theme.isLightTheme ? 'text-stone-900 placeholder:text-stone-400' : 'text-stone-50 placeholder:text-stone-500'} outline-none`}
          />
        </div>

        <div className="flex flex-col gap-2">
          {chainsForMatchingSearch.map((chain) => {
            return (
              <div
                key={chain.id}
                onClick={() => onChangeNetwork(chain.id)}
                className={`w-full flex items-center justify-start rounded-lg gap-2 py-3 px-4 ${(props.isSourceModal && currentSource === chain.id) || (!props.isSourceModal && currentDestination === chain.id) ? (theme.isLightTheme ? 'bg-stone-100 border-[1px] border-stone-500' : 'bg-stone-800 border-[1px] border-stone-500') : ''} ${theme.isLightTheme ? 'hover:bg-stone-100' : 'hover:bg-stone-700'} hover:cursor-pointer`}
              >
                <div className="h-6 w-6 rounded-[100px] overflow-hidden">
                  <img
                    className="w-full h-full object-contain"
                    src={`${process.env.PUBLIC_URL}/assets/chains/${chain.id}.svg`}
                  />
                </div>
                <p className={`${theme.isLightTheme ? '' : 'text-stone-50'}`}>{chain.name}</p>
              </div>
            )
          })}
        </div>
      </ModalContent>
    </Modal>
  )
}

export default BridgeChainSelectorModal
