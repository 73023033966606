import { Routes, Route, Navigate } from 'react-router-dom'
import React from 'react'

import Dashboard from '../../pages/Dashboard'
import Bridge from '../../pages/Bridge'
import Stake from '../../pages/sUsn'
import Usn from '../../pages/Usn'
import Page from '../Page/Page'

const App = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <Page isDashboardPage>
            <Dashboard />
          </Page>
        }
      />
      <Route
        path="/bridge"
        element={
          <Page>
            <Bridge />
          </Page>
        }
      />
      <Route
        path="/get"
        element={
          <Page>
            <Usn />
          </Page>
        }
      />
      <Route
        path="/stake"
        element={
          <Page>
            <Stake />
          </Page>
        }
      />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  )
}

export default App
