import React, { useEffect, useMemo, useState } from 'react'

import usePreviewsUsnRedeem from '../../hooks/read/usePreviewsUsnRedeem'
import { formatBigNumber, formatStringToNumber } from '../../utils'
import useTokenBalance from '../../hooks/read/useTokenBalance'
import ReceiveInfoAndStats from '../ReceiveInfoAndStats'
import ConnectButton from '../ConnectButton'
import BalanceError from '../BalanceError'
import { TsUsnScreen } from '../../types'
import InfoCard from '../InfoCard'
import Input from '../Input'

interface IProps {
  usnAmount: string
  sUsnAmount: string
  setUsnAmount: (amount: string) => void
  setsUsnAmount: (amount: string) => void
  setScreen: (screen: TsUsnScreen) => void
}

const InputAndApproveUnStake = (props: IProps) => {
  const [isAmountInGtMax, setIsAmountGtMax] = useState<boolean>(false)

  const sUsnBalance = useTokenBalance('sUSN')
  const usnOutputAmount = usePreviewsUsnRedeem(props.sUsnAmount)

  const isLoading = useMemo(() => sUsnBalance.isFetching || usnOutputAmount.isFetching, [sUsnBalance, sUsnBalance])
  const isDisabled = useMemo(
    () => isLoading || isAmountInGtMax || !props.sUsnAmount || !Number(props.sUsnAmount),
    [isAmountInGtMax, props.sUsnAmount, isLoading],
  )
  const formattedUsnAmount = useMemo(() => formatStringToNumber(props.usnAmount, true), [props.usnAmount])

  useEffect(() => {
    props.setUsnAmount(formatBigNumber(usnOutputAmount.data || BigInt(0), 18, false))
  }, [props, usnOutputAmount])

  return (
    <React.Fragment>
      <Input
        tokens={[]}
        token={'sUSN'}
        setToken={() => {}}
        allowance={undefined}
        label={'UNSTAKE AMOUNT'}
        amount={props.sUsnAmount}
        setScreen={props.setScreen}
        setAmount={props.setsUsnAmount}
        isAmountGtBalance={isAmountInGtMax}
        balance={sUsnBalance.data || BigInt(0)}
        setIsAmountGtBalance={setIsAmountGtMax}
      />

      <InfoCard
        title={'Unstake Request to be withdrawn in 7 days'}
        body={
          'There is a 7-day cooling off period for unstaking sUSN. Your USN will be available to withdraw 7 days after you unstake your sUSN.'
        }
      />

      {isAmountInGtMax && (
        <BalanceError
          header={'Insufficient wallet balance'}
          body={'Add sUSN to your wallet or use a wallet that already has sUSN'}
        />
      )}

      <ReceiveInfoAndStats page="sUSN" tokenOut={'USN'} amountOut={formattedUsnAmount} networkFeeAction="unstake">
        <ConnectButton
          label={'Next'}
          isLoading={isLoading}
          useFullAvailableWidth
          isDisabled={isDisabled}
          actionIcon="arrow-right"
          useResponsiveSizes={false}
          actionType="primary-action"
          onClick={() => props.setScreen('confirm')}
        />
      </ReceiveInfoAndStats>
    </React.Fragment>
  )
}

export default InputAndApproveUnStake
