import React, { useContext } from 'react'

import { ThemeContext } from '../../context/ThemeProvider'
import ReceiveTokenInfo from '../ReceiveTokenInfo'
import ActionStats from '../ActionStats'

interface IProps {
  page: string
  tokenOut: string
  amountOut: string
  quoteToken?: string
  networkFeeAction?: string
}

const ReceiveInfoAndStats = (props: IProps & React.PropsWithChildren) => {
  const theme = useContext(ThemeContext)

  return (
    <div className="w-full max-w-full px-4 py-0 m-0 flex flex-col items-start justify-start gap-6">
      <ReceiveTokenInfo tokenOut={props.tokenOut} amountOut={props.amountOut} />
      <hr className={`w-full block border-[1px] ${theme.isLightTheme ? 'border-stone-200' : 'border-stone-800'}`} />
      <ActionStats
        page={props.page}
        quoteToken={props.quoteToken || 'USDC'}
        networkFeeAction={props.networkFeeAction || 'approve'}
      />
      {props.children}
    </div>
  )
}

export default ReceiveInfoAndStats
