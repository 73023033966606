import React from 'react'

import type { ReactNode } from 'react'

interface IProps {
  children: ReactNode
  isDashboardPage?: boolean
}

const Page = (props: IProps) => {
  const { children, isDashboardPage = false } = props

  return (
    <div
      className={`min-h-[calc(100vh-72px)] w-full ${isDashboardPage ? 'max-w-full md:max-w-[93vw]' : 'max-w-full md:max-w-[1400px]'} ml-auto mr-auto`}
    >
      {children}
    </div>
  )
}

export default Page
