/* eslint-disable react/react-in-jsx-scope */
import { useContext, useMemo } from 'react'

import { ThemeContext } from '../../context/ThemeProvider'
import networkFeeIcon from '../../assets/network-fee.svg'
import useNetworkFee from '../../hooks/useNetworkFee'
import { formatStringToNumber } from '../../utils'

interface IProps {
  type?: string
  action?: string
  chainId?: number
  gasLimit?: bigint
}

const NetworkFee = (props: IProps) => {
  const { type = 'row', action = 'mint', chainId = undefined, gasLimit = undefined } = props

  const theme = useContext(ThemeContext)
  const networkFee = useNetworkFee(action, chainId, gasLimit)

  const networkFeeFormatted = useMemo(() => {
    const fee = networkFee || 0
    return formatStringToNumber(fee.toString(), true)
  }, [networkFee, formatStringToNumber])

  if (type === 'row') {
    return (
      <div className="w-full flex gap-6 items-center justify-between">
        <div className="w-fit flex rounded gap-1 items-center justify-between">
          <div className="w-5 h-5 rounded-[100px]">
            <img src={networkFeeIcon} className="w-full h-full" alt="Network fee" />
          </div>
          <p className="font-Suisse-Intl font-[450] text-base text-stone-500">Network Fee</p>
        </div>
        <p
          className={`font-Suisse-Intl font-semibold text-base ${theme.isLightTheme ? 'text-stone-500' : 'text-stone-400'}`}
        >
          ~ ${networkFeeFormatted}
        </p>
      </div>
    )
  }

  return (
    <div className="w-fit max-w-full flex rounded gap-1 items-center justify-center">
      <div className="w-5 h-5">
        <img src={networkFeeIcon} className="w-full h-full" alt="Network fee" />
      </div>
      <p
        className={`font-Suisse-Intl font-semibold text-base ${theme.isLightTheme ? 'text-stone-500' : 'text-stone-400'}`}
      >
        ${networkFeeFormatted}
      </p>
    </div>
  )
}

export default NetworkFee
