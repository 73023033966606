import React, { createContext, useState } from 'react'

export interface IThemeContext {
  isLightTheme: boolean
  setIsLightTheme: React.Dispatch<React.SetStateAction<boolean>>
}

export const ThemeContext = createContext<IThemeContext>({
  isLightTheme: localStorage.getItem('noon-dapp-theme') === 'light',
  setIsLightTheme: () => {},
})

export interface IThemeContextProviderProps {
  children: React.ReactNode
}
export const ThemeContextProvider: React.FC<IThemeContextProviderProps> = (props: IThemeContextProviderProps) => {
  const [isLightTheme, setIsLightTheme] = useState<boolean>(localStorage.getItem('noon-dapp-theme') === 'light')

  return <ThemeContext.Provider value={{ isLightTheme, setIsLightTheme }}>{props.children}</ThemeContext.Provider>
}

export default ThemeContextProvider
