import { mainnet, zksync } from 'wagmi/chains'

import { Chain, defineChain } from 'viem'

import { chainConfig } from 'viem/zksync'

import { TBridgeConfig, TConfig } from '../types'

export const sophon = defineChain({
  ...chainConfig,
  id: 50104,
  name: 'Sophon',
  network: 'sophon',
  nativeCurrency: {
    decimals: 18,
    name: 'Sophon',
    symbol: 'SOPH',
  },
  rpcUrls: {
    public: { http: ['https://rpc.sophon.xyz'] },
    default: { http: ['https://rpc.sophon.xyz'] },
  },
  blockExplorers: {
    default: { name: 'Sophon Explorer', url: 'https://explorer.sophon.xyz' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 11_907_934,
    },
  },
}) satisfies Chain

export const DAPP_CONFIG: TConfig = {
  [mainnet.id]: {
    oftEid: 30101,
    icon: 'ethereum',
    tokensForSell: ['USDT'],
    tokensForBuy: ['USN', 'sUSN'],
    tokensForMint: ['USDT', 'USDC'],
    tokensForBridge: ['sUSN', 'USN'],
    dataRefershInterval: 10 * 60 * 1000,
    protocolNativeTokens: ['USN', 'sUSN'],
    usn: '0xdA67B4284609d2d48e5d10cfAc411572727dc1eD',
    sUsn: '0xE24a3DC889621612422A64E6388927901608B91D',
    uniV3Quoter: '0x61fFE014bA17989E743c5F6cB21bF9697530B21e',
    mintHandler: '0x34a2798D47b238A7CbA9D87D49618DEE6C4D999F',
    uniV3Factory: '0x1F98431c8aD98523631AE4a59f267346ea31F984',
    stakingVault: '0xE24a3DC889621612422A64E6388927901608B91D',
    redeemHandler: '0xF5DEAfcdFbC21Cb1E558906d00A1Fdb4b56173e8',
    uniV3SwapRouter: '0xE592427A0AEce92De3Edee1F18E0157C05861564',
    stakeWithdrawHandler: '0x0DaBc0D9B270c9B0C4C77AaCeAa712b56D0F9178',
    gasLimit: {
      swap: 210682,
      mint: 177251,
      bridge: 90246,
      stake: 125148,
      approve: 85066,
      unstake: 70197,
      withdraw: 76942,
    },
    uniV3Pools: {
      'USDT-USN': {
        poolFee: BigInt(100),
        path: ['USDT', 'USN'],
        feeForPath: [BigInt(100)],
        name: 'Uni v3 USDT/USN pool',
        pool: '0x526CD4f72F2CC54d6A02A7feFC84753A826A5737',
      },
      'USDT-sUSN': {
        poolFee: BigInt(100),
        path: ['USDT', 'USN', 'sUSN'],
        name: 'Uni v3 USDT/sUSN pool',
        feeForPath: [BigInt(100), BigInt(100)],
        pool: '0x3C7Ce5eba93981215FBE2e783DbBCbf821945f51',
      },
    },
    tokens: {
      USDT: {
        decimals: 6,
        name: 'USDT',
        symbol: 'USDT',
        assetsIconName: 'USDT',
        oftAddress: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
        contractAddress: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
      },
      USDC: {
        decimals: 6,
        name: 'USDC',
        symbol: 'USDC',
        assetsIconName: 'USDC',
        oftAddress: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
        contractAddress: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
      },
      DAI: {
        decimals: 18,
        name: 'DAI',
        symbol: 'DAI',
        assetsIconName: 'DAI',
        oftAddress: '0x6B175474E89094C44Da98b954EedeAC495271d0F',
        contractAddress: '0x6B175474E89094C44Da98b954EedeAC495271d0F',
      },
      USN: {
        name: 'USN',
        decimals: 18,
        symbol: 'USN',
        assetsIconName: 'USN',
        oftAddress: '0xdA67B4284609d2d48e5d10cfAc411572727dc1eD',
        contractAddress: '0xdA67B4284609d2d48e5d10cfAc411572727dc1eD',
      },
      sUSN: {
        name: 'sUSN',
        decimals: 18,
        symbol: 'sUSN',
        assetsIconName: 'sUSN',
        oftAddress: '0xE24a3DC889621612422A64E6388927901608B91D',
        contractAddress: '0xE24a3DC889621612422A64E6388927901608B91D',
      },
      USDCe: {
        name: 'USDCe',
        decimals: 6,
        symbol: 'USDCe',
        assetsIconName: 'USDC',
        oftAddress: '0x3355df6D4c9C3035724Fd0e3914dE96A5a83aaf4',
        contractAddress: '0x3355df6D4c9C3035724Fd0e3914dE96A5a83aaf4',
      },
    },
  },
  [sophon.id]: {
    oftEid: 30334,
    icon: 'sophon',
    tokensForBuy: ['USN'],
    tokensForSell: ['sUSN'],
    tokensForMint: ['USDT', 'USDC'],
    tokensForBridge: ['USN', 'sUSN'],
    dataRefershInterval: 10 * 60 * 1000,
    protocolNativeTokens: ['USN', 'sUSN'],
    usn: '0xC1AA99c3881B26901aF70738A7C217dc32536d36',
    sUsn: '0xb87dbe27db932bacaaa96478443b6519d52c5004',
    paymaster: '0x98546B226dbbA8230cf620635a1e4ab01F6A99B2',
    mintHandler: '0x173806021b18c4E60170e412DC88F991eB00D699',
    uniV3Quoter: '0xb27308f9F90D607463bb33eA1BeBb41C27CE5AB6',
    uniV3Factory: '0x1F98431c8aD98523631AE4a59f267346ea31F984',
    stakingVault: '0xb87DbE27Db932baCAAA96478443b6519D52C5004',
    redeemHandler: '0xF5DEAfcdFbC21Cb1E558906d00A1Fdb4b56173e8',
    uniV3SwapRouter: '0xE592427A0AEce92De3Edee1F18E0157C05861564',
    layerZeroOftHelper: '0x88172F3041Bd0787520dbc9Bd33D3d48e1fb46dc',
    stakeWithdrawHandler: '0x173806021b18c4E60170e412DC88F991eB00D699',
    gasLimit: {
      bridge: 0,
      swap: 1001143,
      mint: 177251,
      stake: 125148,
      unstake: 70197,
      approve: 46382,
      withdraw: 76942,
    },
    uniV3Pools: {
      'USN-sUSN': {
        poolFee: BigInt(100),
        path: ['USN', 'sUSN'],
        feeForPath: [BigInt(100)],
        name: 'Uni v3 USN/USDT pool',
        pool: '0x526CD4f72F2CC54d6A02A7feFC84753A826A5737',
      },
    },
    tokens: {
      USDC: {
        decimals: 6,
        name: 'USDC',
        symbol: 'USDC',
        assetsIconName: 'USDC',
        oftAddress: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
        contractAddress: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
      },
      USDT: {
        decimals: 6,
        name: 'USDT',
        symbol: 'USDT',
        assetsIconName: 'USDT',
        oftAddress: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
        contractAddress: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
      },
      DAI: {
        decimals: 18,
        name: 'DAI',
        symbol: 'DAI',
        assetsIconName: 'DAI',
        oftAddress: '0x6B175474E89094C44Da98b954EedeAC495271d0F',
        contractAddress: '0x6B175474E89094C44Da98b954EedeAC495271d0F',
      },
      USN: {
        name: 'USN',
        decimals: 18,
        symbol: 'USN',
        assetsIconName: 'USN',
        oftAddress: '0xC1AA99c3881B26901aF70738A7C217dc32536d36',
        contractAddress: '0xC1AA99c3881B26901aF70738A7C217dc32536d36',
      },
      sUSN: {
        name: 'sUSN',
        decimals: 18,
        symbol: 'sUSN',
        assetsIconName: 'sUSN',
        oftAddress: '0xb87dbe27db932bacaaa96478443b6519d52c5004',
        contractAddress: '0xb87dbe27db932bacaaa96478443b6519d52c5004',
      },
      USDCe: {
        name: 'USDCe',
        decimals: 6,
        symbol: 'USDCe',
        assetsIconName: 'USDC',
        oftAddress: '0x3355df6D4c9C3035724Fd0e3914dE96A5a83aaf4',
        contractAddress: '0x3355df6D4c9C3035724Fd0e3914dE96A5a83aaf4',
      },
    },
  },
  [zksync.id]: {
    oftEid: 30165,
    icon: 'zksync',
    tokensForBuy: ['USN', 'sUSN'],
    tokensForSell: ['USDCe'],
    tokensForMint: ['USDT', 'USDC'],
    tokensForBridge: ['USN', 'sUSN'],
    dataRefershInterval: 10 * 60 * 1000,
    protocolNativeTokens: ['USN', 'sUSN'],
    usn: '0x0469d9d1dE0ee58fA1153ef00836B9BbCb84c0B6',
    sUsn: '0xB6a09d426861c63722Aa0b333a9cE5d5a9B04c4f',
    mintHandler: '0x173806021b18c4E60170e412DC88F991eB00D699',
    uniV3Quoter: '0x877B04aA6ee60d39dccC675633157E293f6991E9',
    stakingVault: '0xb87DbE27Db932baCAAA96478443b6519D52C5004',
    uniV3Factory: '0x9D63d318143cF14FF05f8AAA7491904A494e6f13',
    redeemHandler: '0xF5DEAfcdFbC21Cb1E558906d00A1Fdb4b56173e8',
    uniV3SwapRouter: '0xC8A3d1d834617796cdd8c68709e9719f41cb472E',
    stakeWithdrawHandler: '0x173806021b18c4E60170e412DC88F991eB00D699',
    gasLimit: {
      bridge: 0,
      swap: 630176,
      mint: 177251,
      stake: 125148,
      unstake: 70197,
      approve: 46382,
      withdraw: 76942,
    },
    uniV3Pools: {
      'USDCe-USN': {
        poolFee: BigInt(1),
        path: ['USDCe', 'USN'],
        feeForPath: [BigInt(1)],
        name: 'Syncswap v3 USDCe/USN pool',
        pool: '0xE6ed575D9627942893f12BF9c2Cc3c47cD11d002',
      },
      'USN-sUSN': {
        poolFee: BigInt(1),
        path: ['USDCe', 'USN', 'sUSN'],
        name: 'Syncswap v3 USN/sUSN pool',
        feeForPath: [BigInt(1), BigInt(1)],
        pool: '0x12BF23c2Fe929C23Ab375199EFaD425e70c0ECE1',
      },
    },
    tokens: {
      USDC: {
        decimals: 6,
        name: 'USDC',
        symbol: 'USDC',
        assetsIconName: 'USDC',
        oftAddress: '0x1d17CBcF0D6D143135aE902365D2E5e2A16538D4',
        contractAddress: '0x1d17CBcF0D6D143135aE902365D2E5e2A16538D4',
      },
      USDT: {
        decimals: 18,
        name: 'USDT',
        symbol: 'USDT',
        assetsIconName: 'USDT',
        oftAddress: '0x5AEa5775959fBC2557Cc8789bC1bf90A239D9a91',
        contractAddress: '0x5AEa5775959fBC2557Cc8789bC1bf90A239D9a91',
      },
      DAI: {
        decimals: 18,
        name: 'DAI',
        symbol: 'DAI',
        assetsIconName: 'DAI',
        oftAddress: '0x6B175474E89094C44Da98b954EedeAC495271d0F',
        contractAddress: '0x6B175474E89094C44Da98b954EedeAC495271d0F',
      },
      USDCe: {
        name: 'USDCe',
        decimals: 6,
        symbol: 'USDCe',
        assetsIconName: 'USDC',
        oftAddress: '0x3355df6D4c9C3035724Fd0e3914dE96A5a83aaf4',
        contractAddress: '0x3355df6D4c9C3035724Fd0e3914dE96A5a83aaf4',
      },
      USN: {
        name: 'USN',
        decimals: 18,
        symbol: 'USN',
        assetsIconName: 'USN',
        oftAddress: '0x0469d9d1dE0ee58fA1153ef00836B9BbCb84c0B6',
        contractAddress: '0x0469d9d1dE0ee58fA1153ef00836B9BbCb84c0B6',
      },
      sUSN: {
        name: 'sUSN',
        decimals: 18,
        symbol: 'sUSN',
        assetsIconName: 'sUSN',
        oftAddress: '0xB6a09d426861c63722Aa0b333a9cE5d5a9B04c4f',
        contractAddress: '0xB6a09d426861c63722Aa0b333a9cE5d5a9B04c4f',
      },
    },
  },
}

export const BRIDGE_NETWORKS: Array<TBridgeConfig> = [
  {
    ...mainnet,
    config: {
      ...DAPP_CONFIG[mainnet.id],
    },
  },
  {
    ...sophon,
    config: {
      ...DAPP_CONFIG[sophon.id],
    },
  },
  {
    ...zksync,
    config: {
      ...DAPP_CONFIG[zksync.id],
    },
  },
]

export const mint: Chain['id'][] = [mainnet.id, sophon.id]
export const bridge: Chain['id'][] = [mainnet.id, sophon.id, zksync.id]
