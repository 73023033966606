import { mainnet } from '@wagmi/core/chains'

import useTokenAllowance from './useTokenAllowance'
import { DAPP_CONFIG } from '../../constants'

const useStakingAllowance = () => {
  return useTokenAllowance('USN', DAPP_CONFIG[mainnet.id].stakingVault)
}

export default useStakingAllowance
