import { useCallback, useMemo } from 'react'

import { useAccount, useChainId, useWriteContract } from 'wagmi'

import { mainnet } from 'viem/chains'

import { onErrorFeedback, onSuccessFeedback, onSettledFeedback } from '../../utils'
import WithdrawHandlerABI from '../../constants/abis/WithdrawalHandler.json'
import { rainbowKitConfig } from '../../constants/rainbowKitConfig'
import { DAPP_CONFIG } from '../../constants'
import { ETxnStatus } from '../../types'

function useWithdraw(
  withdrawReqId: number,
  setBtnLabel: (label: string) => void,
  setTxnStatus: (status: ETxnStatus) => void,
): () => Promise<void> {
  const chainId = useChainId({ config: rainbowKitConfig })
  const account = useAccount({ config: rainbowKitConfig })
  const { writeContract: onWriteWithWagmi } = useWriteContract({ config: rainbowKitConfig })

  const onTxnFail = useMemo(
    () => () => {
      onErrorFeedback('Confirm', setBtnLabel, setTxnStatus)
    },
    [setBtnLabel, setTxnStatus],
  )

  const onTxnSubmit = useMemo(
    () => () => onSuccessFeedback('Confirming...', setBtnLabel, setTxnStatus),
    [setBtnLabel, setTxnStatus],
  )

  const onTxnSuccessOrRevert = useMemo(
    () => async (txnHash: unknown) =>
      onSettledFeedback(txnHash, 'Claim Amount', 'Claimed', setBtnLabel, setTxnStatus, 'success'),
    [setBtnLabel, setTxnStatus],
  )

  return useCallback(async () => {
    setBtnLabel('Confirming...')
    setTxnStatus(ETxnStatus.PROCESSING)

    if (chainId !== mainnet.id || !account.isConnected || !withdrawReqId) {
      onTxnFail()
      return
    }

    onWriteWithWagmi(
      {
        chain: mainnet,
        chainId: mainnet.id,
        args: [withdrawReqId],
        abi: WithdrawHandlerABI,
        functionName: 'claimWithdrawal',
        address: DAPP_CONFIG[mainnet.id].stakeWithdrawHandler,
      },
      {
        onError: onTxnFail,
        onSuccess: onTxnSubmit,
        onSettled: onTxnSuccessOrRevert,
      },
    )
  }, [chainId, withdrawReqId, account, onTxnSubmit, onTxnFail, onTxnSuccessOrRevert])
}

export default useWithdraw
