import React, { useContext, useState } from 'react'

import useLastWithdrawReqId from '../../hooks/read/useLastWithdrawReqId'
import ProtocolInfo from '../../components/ProtocolInfo/ProtocolInfo'
import { ThemeContext } from '../../context/ThemeProvider'
import { TsUsnScreen, TsUsnTab } from '../../types'
import Withdraw from '../../components/Withdraw'
import UnStake from '../../components/Unstake'
import Stake from '../../components/Stake'

const StakingPage = () => {
  const theme = useContext(ThemeContext)

  const [tab, setTab] = useState<TsUsnTab>('stake')
  const [screen, setScreen] = useState<TsUsnScreen>('input')
  const [refetchStakedBalance, setRefetchStakedBalance] = useState<boolean>(false)

  const lastWithdrawReqId = useLastWithdrawReqId()

  return (
    <div className="h-fit w-full max-w-full flex flex-col md:grid md:grid-cols-2 gap-6 py-10 px-4 md:px-20 md:py-32 items-center justify-start md:items-start md:justify-between md:justify-items-center">
      <ProtocolInfo page="sUSN" />

      <div
        className={`w-full md:w-[90%] max-w-full flex flex-col ${theme.isLightTheme ? 'border-stone-300' : 'border-stone-800'} overflow-hidden flex flex-col items-start justify-center gap-2`}
      >
        {screen === 'input' && (
          <div
            className={`w-fit flex gap-[2px] p-[2px] rounded-xl ${theme.isLightTheme ? 'bg-stone-200' : 'bg-stone-900'} items-center justify-between`}
          >
            <button
              onClick={() => setTab('stake')}
              className={`w-fit rounded-[10px] py-2 px-4 ${theme.isLightTheme ? (tab === 'stake' ? 'bg-white' : 'bg-stone-200') : tab === 'stake' ? 'bg-stone-800' : 'bg-stone-900'} ${tab === 'stake' ? 'usn-page-selected-tab-shadow' : ''} font-Suisse-Intl font-medium text-base ${theme.isLightTheme ? (tab === 'stake' ? 'text-stone-900' : 'text-unselected-tab-text') : tab === 'stake' ? 'text-stone-50' : 'text-stone-500'}`}
            >
              Stake
            </button>
            <button
              onClick={() => setTab('unstake')}
              className={`w-fit rounded-[10px] py-2 px-4 ${theme.isLightTheme ? (tab === 'unstake' ? 'bg-white' : 'bg-stone-200') : tab === 'unstake' ? 'bg-stone-800' : 'bg-stone-900'} ${tab === 'unstake' ? 'usn-page-selected-tab-shadow' : ''} font-Suisse-Intl font-medium text-base ${theme.isLightTheme ? (tab === 'unstake' ? 'text-stone-900' : 'text-unselected-tab-text') : tab === 'unstake' ? 'text-stone-50' : 'text-stone-500'}`}
            >
              Unstake
            </button>
            <button
              onClick={() => setTab('withdraw')}
              disabled={!lastWithdrawReqId.data || lastWithdrawReqId.data <= 0}
              className={`w-fit rounded-[10px] py-2 px-4 ${theme.isLightTheme ? (tab === 'withdraw' ? 'bg-white' : 'bg-stone-200') : tab === 'withdraw' ? 'bg-stone-800' : 'bg-stone-900'} ${tab === 'withdraw' ? 'usn-page-selected-tab-shadow' : ''} font-Suisse-Intl font-medium text-base ${theme.isLightTheme ? (tab === 'withdraw' ? 'text-stone-900' : 'text-unselected-tab-text') : tab === 'withdraw' ? 'text-stone-50' : 'text-stone-500'}`}
            >
              Withdraw
            </button>
          </div>
        )}

        {tab === 'stake' ? (
          <Stake
            screen={screen}
            setScreen={setScreen}
            refetchStakedBalance={refetchStakedBalance}
            setRefetchStakedBalance={setRefetchStakedBalance}
          />
        ) : tab === 'unstake' ? (
          <UnStake screen={screen} setTab={setTab} setScreen={setScreen} refetchStakedBalance={refetchStakedBalance} />
        ) : (
          <Withdraw screen={screen} setScreen={setScreen} refetchStakedBalance={refetchStakedBalance} />
        )}
      </div>
    </div>
  )
}

export default StakingPage
