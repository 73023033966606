import React, { useContext } from 'react'

import { ReactComponent as WarningIcon } from '../../assets/info/warning.svg'
import { ThemeContext } from '../../context/ThemeProvider'

interface IProps {
  body: string
  header: string
}

const BalanceError = (props: IProps) => {
  const theme = useContext(ThemeContext)

  return (
    <div
      className={`w-full flex items-start justify-start rounded-[10px] p-3 gap-2 ${theme.isLightTheme ? 'bg-stone-100' : 'bg-stone-900'}`}
    >
      <div className={`w-6 h-6 text-red-700 shrink-0`}>
        <WarningIcon className="w-full h-full" />
      </div>
      <div className="grow flex flex-col items-start justify-start gap-[2px]">
        <p className="font-Suisse-Intl font-[450] text-base text-red-700 text-wrap">{props.header}</p>
        <p className="font-Suisse-Intl font-[450] text-sm text-stone-500 text-wrap">{props.body}</p>
      </div>
    </div>
  )
}

export default BalanceError
