/* eslint-disable react/react-in-jsx-scope */
import { useContext, useMemo } from 'react'
import { useReadContract } from 'wagmi'
import { parseUnits } from 'viem'

import { mainnet } from 'viem/chains'

import StakingVaultABI from '../../constants/abis/StakingVault.json'
import { rainbowKitConfig } from '../../constants/rainbowKitConfig'
import { ThemeContext } from '../../context/ThemeProvider'
import sUSN from '../../assets/tokens/sUSN.svg'
import USN from '../../assets/tokens/USN.svg'
import { DAPP_CONFIG } from '../../constants'
import { formatBigNumber } from '../../utils'

const SUSNPrice = (props: { currency: string }) => {
  const theme = useContext(ThemeContext)

  const usnOutputAmount = useReadContract({
    abi: StakingVaultABI,
    address: DAPP_CONFIG[mainnet.id].stakingVault,
    functionName: 'previewRedeem',
    args: [parseUnits('1', 18)],
    config: rainbowKitConfig,
    chainId: mainnet.id,
    query: {
      enabled: true,
      refetchOnMount: true,
      refetchOnReconnect: true,
      refetchOnWindowFocus: true,
      initialData: () => BigInt(0),
      placeholderData: () => BigInt(0),
      refetchIntervalInBackground: false,
      select: (data) => (data as bigint) || BigInt(0),
      refetchInterval: DAPP_CONFIG[mainnet.id].dataRefershInterval,
    },
  })

  const formattedUSNOutputAmount = useMemo(
    () => formatBigNumber(usnOutputAmount.data || BigInt(0), 18),
    [formatBigNumber, usnOutputAmount.data],
  )

  return (
    <div className="w-full flex gap-6 items-center justify-between">
      <div className="w-fit flex rounded gap-1 items-start justify-start justify-self-start">
        <div className="w-[18px] h-[18px]">
          <img src={sUSN} className="w-full h-full" alt="sUSN" />
        </div>
        <p className="font-Suisse-Intl font-[450] text-base text-stone-500 text-wrap leading-[1.2]">1 sUSN equal to</p>
      </div>

      <div className="w-fit flex gap-2 items-center justify-end flex-wrap grow">
        <div className="w-fit flex rounded gap-1 items-center justify-around">
          <div className="w-[18px] h-[18px]">
            <img src={USN} className="w-full h-full" alt="USN" />
          </div>
          <p
            className={`font-Suisse-Intl font-semibold text-base ${theme.isLightTheme ? 'text-stone-900' : 'text-stone-50'} text-right`}
          >
            {formattedUSNOutputAmount} USN
          </p>
        </div>
        {/* <div
          className={`hidden md:block w-fit font-Suisse-Intl font-semibold text-base ${theme.isLightTheme ? 'text-stone-500' : 'text-stone-400'} justify-self-end text-right`}
        >
          <p
            className={`w-fit font-Suisse-Intl font-semibold text-base ${theme.isLightTheme ? 'text-stone-500' : 'text-stone-600'} inline`}
          >
            •
          </p>
          &nbsp;&nbsp;
          {formattedUSNOutputAmount} {props.currency}
        </div> */}
      </div>
    </div>
  )
}

export default SUSNPrice
