import React, { useContext } from 'react'

import moment from 'moment'

import { formatUnits } from 'viem'

import { ReactComponent as ArrowRightUpIcon } from '../../assets/navigation/arrow-right-up.svg'
import { ReactComponent as ArrowDownIcon } from '../../assets/navigation/chevron-big-down.svg'
import { CarouselContent, CarouselItem, useCarousel } from '../ui/Carousel'
import { ThemeContext } from '../../context/ThemeProvider'
import { formatStringToNumber } from '../../utils'
import { TMintReq } from '../../types'

interface IProps {
  pendingMintRequests: TMintReq[]
  setMintRequestInfo: (mintRequestInfo: TMintReq) => void
  openMintRequstInfoModal: (mintRequestInfo: TMintReq) => void
}

const MintRequestsCarouselContent = (props: IProps) => {
  const carousel = useCarousel()
  const theme = useContext(ThemeContext)

  return (
    <div className="relative w-full max-w-full gap-4 p-0 m-0 overflow-visible">
      {carousel.canScrollPrev && (
        <>
          <div
            className={`absolute w-[14%] max-w-[15%] left-0 -top-[5%] h-[105%] z-10 ${theme.isLightTheme ? 'mint-redeem-requests-carousel-previous-overlay' : 'mint-redeem-requests-carousel-previous-overlay-dark'}`}
          ></div>
          <button
            disabled={!carousel.canScrollPrev}
            onClick={carousel.scrollPrev}
            className={`absolute -left-4 mt-auto mb-auto h-8 w-8 z-20 border-[1px] p-[6px] flex items-center justify-center ${theme.isLightTheme ? 'bg-white border-stone-300' : 'bg-stone-950 border-stone-800'} rounded-full top-1/2 -translate-y-1/2`}
          >
            <div className={`w-5 h-5 ${theme.isLightTheme ? 'text-stone-500' : 'text-stone-400'}`}>
              <ArrowDownIcon className="w-full h-full rotate-90" />
            </div>
          </button>
        </>
      )}
      {carousel.canScrollNext && (
        <>
          <div
            className={`absolute w-[14%] max-w-[15%] right-0 -top-[5%] h-[105%] z-10 ${theme.isLightTheme ? 'mint-redeem-requests-carousel-next-overlay' : 'mint-redeem-requests-carousel-next-overlay-dark'}`}
          ></div>
          <button
            disabled={!carousel.canScrollNext}
            onClick={carousel.scrollNext}
            className={`absolute -right-4 mt-auto mb-auto h-8 w-8 z-20 border-[1px]  p-[6px] flex items-center justify-center ${theme.isLightTheme ? 'bg-white border-stone-300' : 'bg-stone-950 border-stone-800'} rounded-full top-1/2 -translate-y-1/2`}
          >
            <div className={`w-5 h-5 ${theme.isLightTheme ? 'text-stone-500' : 'text-stone-400'}`}>
              <ArrowDownIcon className="w-full h-full -rotate-90" />
            </div>
          </button>
        </>
      )}
      <CarouselContent>
        {(props.pendingMintRequests?.length ? props.pendingMintRequests : []).map((mintReq, i: number) => (
          <CarouselItem key={i} className="basis-1/2">
            <div
              className={`min-h-32 flex flex-col w-full max-w-full rounded-xl border-[1px] p-4 gap-2 ${theme.isLightTheme ? 'bg-stone-50 border-stone-200' : 'bg-stone-900 border-stone-800'}`}
            >
              <div className="w-full max-w-full flex items-center justify-between gap-1">
                <p
                  className={`font-Suisse-Intl font-[450] text-base ${theme.isLightTheme ? 'text-stone-500' : 'text-stone-400'}`}
                >
                  MINT #{mintReq.id || i}
                </p>
                <p
                  className={`rounded-md py-[2px] px-2 font-Suisse-Intl font-medium text-sm ${theme.isLightTheme ? 'bg-warning-200 text-warning-950' : 'bg-warning-950 text-warning-200'}`}
                >
                  IN PROCESS
                </p>
              </div>
              <div className="w-fit flex rounded gap-1 items-center justify-start">
                <div className="w-6 h-6">
                  <img src={`${process.env.PUBLIC_URL}/assets/tokens/USN.svg`} className="w-full h-full" alt="USN" />
                </div>
                <p
                  className={`font-Suisse-Intl font-[450] text-lg ${theme.isLightTheme ? 'text-stone-900' : 'text-stone-50'}`}
                >
                  {formatStringToNumber(formatUnits(BigInt(mintReq.order.usnAmount || '0'), 18), true)} USN
                </p>
              </div>
              <p
                className={`font-Suisse-Intl font-[450] text-sm ${theme.isLightTheme ? 'text-stone-400' : 'text-stone-600'} text-wrap`}
              >
                {moment(new Date(mintReq.createdDate).toUTCString()).utc().format('Do MMM YYYY • hh:mm A UTC')}
              </p>
              <div className="w-fit flex rounded gap-1 items-center justify-start">
                <div
                  onClick={() => props.openMintRequstInfoModal(mintReq)}
                  className={`font-Suisse-Intl font-[450] text-lg ${theme.isLightTheme ? 'text-stone-400' : 'text-stone-500'} cursor-pointer`}
                >
                  View Details
                </div>
                <div className="w-6 h-6">
                  <ArrowRightUpIcon
                    className={`w-full h-full  ${theme.isLightTheme ? 'text-stone-400' : 'text-stone-500'}`}
                  />
                </div>
              </div>
            </div>
          </CarouselItem>
        ))}
      </CarouselContent>
    </div>
  )
}

export default MintRequestsCarouselContent
