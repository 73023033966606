/* eslint-disable react/react-in-jsx-scope */
import { useContext } from 'react'

import { ReactComponent as MoonIcon } from '../../assets/theme/moon.svg'
import { ReactComponent as SunIcon } from '../../assets/theme/sun.svg'
import { ThemeContext } from '../../context/ThemeProvider'

interface IProps {
  classes: string
}

const ThemeMode = (props: IProps) => {
  const theme = useContext(ThemeContext)

  const setTheme = () => {
    theme.setIsLightTheme((_isLightTheme: boolean) => {
      if (_isLightTheme) {
        localStorage.setItem('noon-dapp-theme', 'dark')
      } else {
        localStorage.setItem('noon-dapp-theme', 'light')
      }

      return !_isLightTheme
    })
  }

  return (
    <button
      onClick={setTheme}
      className={`${props.classes} items-center justify-center gap-0.5 rounded-[48px] p-0.5 ${theme.isLightTheme ? 'bg-stone-200' : 'bg-stone-900'} cursor-pointer text-stone-900`}
    >
      <div className={`w-7 h-7 rounded-[100px] p-1 ${!theme.isLightTheme ? 'bg-white' : 'bg-transaparent'}`}>
        <div className={`h-5 w-5 ${theme.isLightTheme ? 'text-stone-400' : 'text-stone-900'}`}>
          <MoonIcon className="w-full h-full" />
        </div>
      </div>
      <div className={`w-7 h-7 rounded-[100px] p-1 ${theme.isLightTheme ? 'bg-white' : 'bg-transaparent'}`}>
        <div className={`h-5 w-5 ${!theme.isLightTheme ? 'text-stone-400' : 'text-stone-900'}`}>
          <SunIcon className="w-full h-full" />
        </div>
      </div>
    </button>
  )
}

export default ThemeMode
