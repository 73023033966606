import React, { useContext, useState } from 'react'
import { NavLink } from 'react-router-dom'

import { mainnet } from 'viem/chains'

import { useAccount } from 'wagmi'

import { Tooltip } from '@chakra-ui/react'

import { ReactComponent as MobileMenuOpen } from '../../assets/menu/burger.svg'
import { ReactComponent as MobileMenuClose } from '../../assets/menu/close.svg'
import WrongNetworkToolTip from '../WrongNetworkToolTip/WrongNetworkToolTip'
import { rainbowKitConfig } from '../../constants/rainbowKitConfig'
import { ReactComponent as Logo } from '../../assets/logo.svg'
import { ThemeContext } from '../../context/ThemeProvider'
import NetworkSelector from '../NetworkSelector'
import ConnectButton from '../ConnectButton'
import ThemeMode from '../ThemeMode'

const Navbar = () => {
  const [isMobileNavOpen, setIsMobileNavOpen] = useState<boolean>(false)

  const theme = useContext(ThemeContext)
  const account = useAccount({ config: rainbowKitConfig })

  const onToggleMenuMenuOpened = () => {
    setIsMobileNavOpen((open) => !open)
  }

  return (
    <>
      <nav className="flex flex-wrap items-center justify-between py-2 px-4 md:py-3 md:px-8">
        <section className="flex items-center justify-center gap-2 md:block md:w-[32%] md:max-w-[32%]">
          <div className="flex md:hidden">
            <button
              id="hamburger"
              onClick={onToggleMenuMenuOpened}
              className={`h-6 w-6 ${theme.isLightTheme ? 'text-stone-500' : 'text-stone-400'}`}
            >
              {!isMobileNavOpen ? (
                <MobileMenuOpen className="w-full h-full text-stone-500" />
              ) : (
                <MobileMenuClose className="w-full h-full text-stone-500" />
              )}
            </button>
          </div>

          <NavLink
            to="/"
            className={`grow w-fit h-6 ${theme.isLightTheme ? 'text-stone-900' : 'text-stone-50'} flex items-center justify-start gap-2`}
          >
            <div className="h-[18px] w-[64px] md:h-full max-h-full md:w-[81.45px] p-0 m-0">
              <Logo className="h-full w-full max-h-full max-w-full" />
            </div>
            <p
              className={`w-fit h-[18px] md:h-full max-h-full rounded-[4px] py-[2px] px-2 ${theme.isLightTheme ? 'bg-orange-600 text-white' : 'bg-orange-600 text-white'} font-Suisse-Intl font-medium text-xs md:text-sm text-center`}
            >
              PUBLIC BETA
            </p>
          </NavLink>
        </section>

        <div className="hidden md:w-auto md:flex md:border-none md:gap-6 font-Suisse-Intl font-medium text-base md:justify-around md:items-center">
          <NavLink
            to="/"
            className={({ isActive }) =>
              !isActive
                ? theme.isLightTheme
                  ? 'text-stone-500 hover:text-stone-900'
                  : 'text-stone-500 hover:text-stone-50'
                : theme.isLightTheme
                  ? 'text-stone-900'
                  : 'text-stone-50'
            }
          >
            Dashboard
          </NavLink>
          <NavLink
            to="/get"
            className={({ isActive }) =>
              !isActive
                ? theme.isLightTheme
                  ? 'text-stone-500 hover:text-stone-900'
                  : 'text-stone-500 hover:text-stone-50'
                : theme.isLightTheme
                  ? 'text-stone-900'
                  : 'text-stone-50'
            }
          >
            Get
          </NavLink>
          <Tooltip
            padding={'12px'}
            color={'transparent'}
            borderRadius={'12px'}
            placement="top-start"
            bg={theme.isLightTheme ? '#FFFFFF' : '#000000'}
            borderColor={theme.isLightTheme ? '#E7E5E4' : '#312D2C'}
            isDisabled={
              !account ||
              !account?.address ||
              !account?.isConnected ||
              (account.isConnected && account?.chainId === mainnet.id)
            }
            label={<WrongNetworkToolTip />}
            className={`rounded-xl border-[1px] p-3 gap-2 ${account?.chainId !== mainnet.id ? 'cursor-not-allowed' : 'cursor-pointer'} ${theme.isLightTheme ? 'bg-white border-stone-200' : 'bg-black border-stone-800'} usn-page-whitelist-status-tooltip-shadow`}
          >
            {(account.isConnected && account?.chainId === mainnet.id) || !account.isConnected ? (
              <NavLink
                to="/stake"
                className={({ isActive }) => {
                  return !isActive
                    ? theme.isLightTheme
                      ? 'text-stone-500 hover:text-stone-900'
                      : 'text-stone-500 hover:text-stone-50'
                    : theme.isLightTheme
                      ? 'text-stone-900'
                      : 'text-stone-50'
                }}
              >
                Stake
              </NavLink>
            ) : (
              <div
                className={`${
                  theme.isLightTheme ? 'text-stone-500 hover:text-stone-900' : 'text-stone-500 hover:text-stone-50'
                }`}
              >
                Stake
              </div>
            )}
          </Tooltip>
          <NavLink
            to="/bridge"
            className={({ isActive }) =>
              !isActive
                ? theme.isLightTheme
                  ? 'text-stone-500 hover:text-stone-900'
                  : 'text-stone-500 hover:text-stone-50'
                : theme.isLightTheme
                  ? 'text-stone-900'
                  : 'text-stone-50'
            }
          >
            Bridge
          </NavLink>
          <NavLink
            target="_blank"
            to="https://docs.noon.capital"
            className={({ isActive }) =>
              !isActive
                ? theme.isLightTheme
                  ? 'text-stone-500 hover:text-stone-900'
                  : 'text-stone-500 hover:text-stone-50'
                : theme.isLightTheme
                  ? 'text-stone-900'
                  : 'text-stone-50'
            }
          >
            Docs
          </NavLink>
          <NavLink
            target="_blank"
            to="https://noon.accountable.capital"
            className={({ isActive }) =>
              !isActive
                ? theme.isLightTheme
                  ? 'text-stone-500 hover:text-stone-900'
                  : 'text-stone-500 hover:text-stone-50'
                : theme.isLightTheme
                  ? 'text-stone-900'
                  : 'text-stone-50'
            }
          >
            Solvency
          </NavLink>
        </div>

        <section className="flex md:gap-4 md:items-center md:justify-end md:w-[32%] md:max-w-[32%]">
          <ThemeMode classes={'hidden md:flex'} />
          <NetworkSelector classes={'hidden md:flex'} />
          <ConnectButton showArrow={false} showWalletLogo={true} />
        </section>
      </nav>

      {isMobileNavOpen && (
        <nav className="md:hidden p-2 mt-1">
          <div
            className={`flex flex-col items-start justify-between border-[1px] rounded-xl p-2 gap-4 ${theme.isLightTheme ? 'bg-stone-100' : 'bg-stone-900'} ${theme.isLightTheme ? 'border-stone-300' : 'border-stone-800'}`}
          >
            <div className="h-fit w-full max-w-full flex items-center justify-between gap-2">
              <ThemeMode classes={'flex md:hidden'} />
              <NetworkSelector classes={'flex md:hidden'} />
            </div>
            <div className="w-full flex flex-col items-start justify-between gap-4 md:hidden font-Suisse-Intl font-medium text-base">
              <NavLink
                to="/"
                className={({ isActive }) => {
                  return `w-full rounded-lg p-2 gap-2 ${
                    !isActive
                      ? theme.isLightTheme
                        ? 'text-stone-500 hover:text-stone-900'
                        : 'text-stone-500 hover:text-stone-50'
                      : theme.isLightTheme
                        ? 'text-stone-900 bg-white'
                        : 'text-stone-50 bg-stone-800'
                  }`
                }}
              >
                Dashboard
              </NavLink>
              <NavLink
                to="/get"
                className={({ isActive }) => {
                  return `w-full rounded-lg p-2 gap-2 ${
                    !isActive
                      ? theme.isLightTheme
                        ? 'text-stone-500 hover:text-stone-900'
                        : 'text-stone-500 hover:text-stone-50'
                      : theme.isLightTheme
                        ? 'text-stone-900 bg-white'
                        : 'text-stone-50 bg-stone-800'
                  }`
                }}
              >
                Get
              </NavLink>
              <Tooltip
                padding={'12px'}
                color={'transparent'}
                borderRadius={'12px'}
                placement="top-start"
                bg={theme.isLightTheme ? '#FFFFFF' : '#000000'}
                borderColor={theme.isLightTheme ? '#E7E5E4' : '#312D2C'}
                isDisabled={
                  !account ||
                  !account?.address ||
                  !account?.isConnected ||
                  (account.isConnected && account?.chainId === mainnet.id)
                }
                label={<WrongNetworkToolTip />}
                className={`rounded-xl border-[1px] p-3 gap-2 ${account?.chainId !== mainnet.id ? 'cursor-not-allowed' : 'cursor-pointer'} ${theme.isLightTheme ? 'bg-white border-stone-200' : 'bg-black border-stone-800'} usn-page-whitelist-status-tooltip-shadow`}
              >
                {(account.isConnected && account?.chainId === mainnet.id) || !account.isConnected ? (
                  <NavLink
                    to="stake"
                    className={({ isActive }) => {
                      return `w-full rounded-lg p-2 gap-2 ${
                        !isActive
                          ? theme.isLightTheme
                            ? 'text-stone-500 hover:text-stone-900'
                            : 'text-stone-500 hover:text-stone-50'
                          : theme.isLightTheme
                            ? 'text-stone-900 bg-white'
                            : 'text-stone-50 bg-stone-800'
                      }`
                    }}
                  >
                    Stake
                  </NavLink>
                ) : (
                  <div
                    className={`w-full rounded-lg p-2 gap-2 ${
                      theme.isLightTheme ? 'text-stone-500 hover:text-stone-900' : 'text-stone-500 hover:text-stone-50'
                    }`}
                  >
                    Stake
                  </div>
                )}
              </Tooltip>
              <NavLink
                to="/bridge"
                className={({ isActive }) => {
                  return `w-full rounded-lg p-2 gap-2 ${
                    !isActive
                      ? theme.isLightTheme
                        ? 'text-stone-500 hover:text-stone-900'
                        : 'text-stone-500 hover:text-stone-50'
                      : theme.isLightTheme
                        ? 'text-stone-900 bg-white'
                        : 'text-stone-50 bg-stone-800'
                  }`
                }}
              >
                Bridge
              </NavLink>
              <NavLink
                target="_blank"
                to="https://docs.noon.capital"
                className={({ isActive }) => {
                  return `w-full rounded-lg p-2 gap-2 ${
                    !isActive
                      ? theme.isLightTheme
                        ? 'text-stone-500 hover:text-stone-900'
                        : 'text-stone-500 hover:text-stone-50'
                      : theme.isLightTheme
                        ? 'text-stone-900 bg-white'
                        : 'text-stone-50 bg-stone-800'
                  }`
                }}
              >
                Docs
              </NavLink>
              <NavLink
                target="_blank"
                to="https://noon.accountable.capital"
                className={({ isActive }) => {
                  return `w-full rounded-lg p-2 gap-2 ${
                    !isActive
                      ? theme.isLightTheme
                        ? 'text-stone-500 hover:text-stone-900'
                        : 'text-stone-500 hover:text-stone-50'
                      : theme.isLightTheme
                        ? 'text-stone-900 bg-white'
                        : 'text-stone-50 bg-stone-800'
                  }`
                }}
              >
                Solvency
              </NavLink>
            </div>
          </div>
        </nav>
      )}
    </>
  )
}

export default Navbar
