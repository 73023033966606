import React, { useContext, useState } from 'react'

import { ReactComponent as ChevronDownIcon } from '../../assets/navigation/chevron-big-down.svg'
import { ReactComponent as ChevronUpIcon } from '../../assets/navigation/chevron-big-up.svg'
import { ReactComponent as InfoIcon } from '../../assets/info/info-circle.svg'
import { ThemeContext } from '../../context/ThemeProvider'

interface IProps {
  body: string
  title: string
}

const InfoCard = (props: IProps) => {
  const [infoVisible, setToggleInfoVisibility] = useState<boolean>(true)

  const theme = useContext(ThemeContext)

  return (
    <div
      className={`w-full max-w-full flex items-start justify-center rounded-[10px] p-3 gap-2 ${theme.isLightTheme ? 'bg-stone-100' : 'bg-stone-800'}`}
    >
      <div className={`w-6 h-6 shrink-0 ${theme.isLightTheme ? 'text-stone-900' : 'text-stone-200'}`}>
        <InfoIcon className="w-full h-full" />
      </div>
      <div className="flex flex-col items-start justify-start gap-[2px] grow">
        <p
          className={`font-Suisse-Intl font-[450] text-base ${theme.isLightTheme ? 'text-stone-900' : 'text-stone-200'} text-wrap text-left`}
        >
          {props.title}
        </p>
        {infoVisible && (
          <p className="font-Suisse-Intl font-[450] text-sm text-stone-500 text-wrap text-left shrink-0">
            {props.body}
          </p>
        )}
      </div>
      {infoVisible ? (
        <div className={`w-6 h-6 ${theme.isLightTheme ? 'text-stone-400' : 'text-stone-600'} shrink-0 cursor-pointer`}>
          <ChevronUpIcon className="h-full w-full shrink-0" onClick={() => setToggleInfoVisibility(false)} />
        </div>
      ) : (
        <div className={`w-6 h-6 ${theme.isLightTheme ? 'text-stone-400' : 'text-stone-600'} shrink-0 cursor-pointer`}>
          <ChevronDownIcon className="h-full w-full shrink-0" onClick={() => setToggleInfoVisibility(true)} />
        </div>
      )}
    </div>
  )
}

export default InfoCard
