import React, { createContext, useEffect, useState } from 'react'
import { mainnet } from 'viem/chains'
import { Chain } from 'viem'

import { useAccount, useChains } from 'wagmi'

import { EApiCallStatus, ETxnStatus, TBridgeQuote, TScreen } from '../types'
import { rainbowKitConfig } from '../constants/rainbowKitConfig'
import { bridge, DAPP_CONFIG, sophon } from '../constants'
import { DEFAULT_BRIDGE_QUOTE } from '../utils'

export interface IBridgeContext {
  token: string
  amount: string
  screen: TScreen
  source: Chain['id']
  quote: TBridgeQuote
  destination: Chain['id']
  bridgeTxnStatus: ETxnStatus
  approveTxnStatus: ETxnStatus
  chainChangeStatus: EApiCallStatus
  receiverAddress: string
  setReceiverAddress: (addr: string) => void
  setToken: (token: string) => void
  setAmount: (amount: string) => void
  setScreen: (screen: TScreen) => void
  setQuote: (quote: TBridgeQuote) => void
  setSource: (source: Chain['id']) => void
  setBridgeTxnStatus: (status: ETxnStatus) => void
  setApproveTxnStatus: (status: ETxnStatus) => void
  setDestination: (destination: Chain['id']) => void
  setChainChangeStatus: (status: EApiCallStatus) => void
}

export const BridgeContext = createContext<IBridgeContext>({
  amount: '',
  token: 'USN',
  screen: 'input',
  source: mainnet.id,
  destination: sophon.id,
  quote: DEFAULT_BRIDGE_QUOTE,
  bridgeTxnStatus: ETxnStatus.IDLE,
  approveTxnStatus: ETxnStatus.IDLE,
  chainChangeStatus: EApiCallStatus.IDLE,
  receiverAddress: '',
  setToken: (token: string) => {
    console.log('Token', token)
  },
  setAmount: (amount: string) => {
    console.log('Amount', amount)
  },
  setScreen: (screen: TScreen) => {
    console.log('Screen', screen)
  },
  setQuote: (quote: TBridgeQuote) => {
    console.log('Quote', quote)
  },
  setSource: (source: Chain['id']) => {
    console.log('Source', source)
  },
  setBridgeTxnStatus: (status: ETxnStatus) => {
    console.log('Update status', status)
  },
  setApproveTxnStatus: (status: ETxnStatus) => {
    console.log('Update status', status)
  },
  setDestination: (destination: Chain['id']) => {
    console.log('Destination', destination)
  },
  setChainChangeStatus: (status: EApiCallStatus) => {
    console.log('Status', status)
  },
  setReceiverAddress: (addr: string) => {
    console.log('Addr', addr)
  },
})

export const BridgeContextProvider: React.FC<React.PropsWithChildren> = (props) => {
  const [amount, setAmount] = useState<string>('')
  const [token, setToken] = useState<string>('USN')
  const [screen, setScreen] = useState<TScreen>('input')
  const [source, setSource] = useState<Chain['id']>(mainnet.id)
  const [destination, setDestination] = useState<Chain['id']>(sophon.id)
  const [quote, setQuote] = useState<TBridgeQuote>(DEFAULT_BRIDGE_QUOTE)
  const [bridgeTxnStatus, setBridgeTxnStatus] = useState<ETxnStatus>(ETxnStatus.IDLE)
  const [approveTxnStatus, setApproveTxnStatus] = useState<ETxnStatus>(ETxnStatus.IDLE)
  const [chainChangeStatus, setChainChangeStatus] = useState<EApiCallStatus>(EApiCallStatus.IDLE)
  const [receiverAddress, setReceiverAddress] = useState<string>('')

  const chains = useChains({ config: rainbowKitConfig })
  const account = useAccount({ config: rainbowKitConfig })

  useEffect(() => {
    if (!account.isConnected || !account.address || !account.chainId) {
      setScreen('input')
    }

    const _source = bridge.find((b) => b === account.chainId) || bridge.at(0) || mainnet.id
    setSource(_source)
    setDestination((d) => (d === _source ? bridge.find((c) => c !== _source) || bridge.at(1) || sophon.id : d))
  }, [chains, account.chainId])

  useEffect(() => {
    const config = DAPP_CONFIG[source] || DAPP_CONFIG[mainnet.id]
    setAmount('')
    setScreen('input')
    setBridgeTxnStatus(ETxnStatus.IDLE)
    setApproveTxnStatus(ETxnStatus.IDLE)
    setToken(config?.tokensForBridge?.at(0) || 'sUSN')
  }, [source])

  const onSetScreen = (screen: TScreen) => {
    setBridgeTxnStatus(ETxnStatus.IDLE)
    setApproveTxnStatus(ETxnStatus.IDLE)
    setScreen(screen)
  }

  return (
    <BridgeContext.Provider
      value={{
        token,
        quote,
        amount,
        source,
        screen,
        destination,
        bridgeTxnStatus,
        approveTxnStatus,
        chainChangeStatus,
        receiverAddress,
        setToken,
        setQuote,
        setAmount,
        setSource,
        setDestination,
        setBridgeTxnStatus,
        setApproveTxnStatus,
        setChainChangeStatus,
        setScreen: onSetScreen,
        setReceiverAddress,
      }}
    >
      {props.children}
    </BridgeContext.Provider>
  )
}

export default BridgeContextProvider
