import React, { useCallback, useContext, useMemo, useState } from 'react'
import { useAccount, useSignTypedData } from 'wagmi'
import { parseUnits } from 'viem'

import { mainnet } from 'viem/chains'

import { useQueryClient } from '@tanstack/react-query'

import { formatStringToNumber, onApiErrorFeedback, onApiSuccessFeedbackAction } from '../../utils'
import { EApiCallStatus, TRedeemScreen, TMintOrder, TScreen } from '../../types'
import { rainbowKitConfig } from '../../constants/rainbowKitConfig'
import { ThemeContext } from '../../context/ThemeProvider'
import ActionInAndOutInfo from '../ActionInAndOutInfo'
import { DAPP_CONFIG } from '../../constants'
import ConnectButton from '../ConnectButton'
import ActionStats from '../ActionStats'

interface IProps {
  usnAmount: string
  selectedCollateral: string
  setScreen: (screen: TRedeemScreen) => void
}

const RedeemConfirm = (props: IProps) => {
  const [btnLabel, setBtnLabel] = useState<string>('Confirm')
  const [txnStatus, setTxnStatus] = useState<EApiCallStatus>(EApiCallStatus.IDLE)

  const queryClient = useQueryClient()
  const theme = useContext(ThemeContext)
  const account = useAccount({ config: rainbowKitConfig })
  const { signTypedData } = useSignTypedData({ config: rainbowKitConfig })

  const formattedUsnAmount = useMemo(() => formatStringToNumber(props.usnAmount || '0', true), [props.usnAmount])

  const onApiOrSignatureError = useMemo(() => {
    return () => onApiErrorFeedback('Confirm', setBtnLabel, setTxnStatus)
  }, [setBtnLabel, setTxnStatus])

  const onApiSuccess = useMemo(() => {
    return () =>
      onApiSuccessFeedbackAction('Confirmed', setBtnLabel, setTxnStatus, 'success', (screen: TScreen) => {
        queryClient.refetchQueries({
          queryKey: ['api', 'pending', 'requests', account.isConnected, account.address],
        })
        props.setScreen(screen)
      })
  }, [queryClient, props.setScreen, setBtnLabel, setTxnStatus])

  const isButtonDisabled = useMemo(
    () => !account.isConnected || !account.address || !props.usnAmount || !Number(props.usnAmount),
    [account.isConnected, account.address, props.usnAmount],
  )

  const sendRedeemReq = useCallback(
    async (order: TMintOrder, signature: string) => {
      await fetch(`${process.env.REACT_APP_BASE_API_URL ?? ''}/api/redeem-usn`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...order,
          signature,
          usnAmount: parseUnits(props.usnAmount || '0', 18).toString(),
          collateralAmount: parseUnits(
            props.usnAmount || '0',
            DAPP_CONFIG[mainnet.id].tokens[props.selectedCollateral].decimals,
          ).toString(),
        }),
      })
        .then(
          (res) => {
            if (res.ok && res.status === 200) {
              onApiSuccess()
              return
            }
            throw new Error('Error')
          },
          (err) => {
            throw new Error('Error' + err?.message)
          },
        )
        .catch((err: Error) => {
          console.error('Error redeeming from REDEEM API:', err)
          onApiOrSignatureError()
        })
    },
    [props],
  )
  const signRedeemData = useCallback(() => {
    setTxnStatus(EApiCallStatus.PROCESSING)
    setBtnLabel('Confirming...')

    const domain = {
      name: 'RedeemHandler',
      version: '1',
      chainId: account.chainId,
      verifyingContract: DAPP_CONFIG[mainnet.id].redeemHandler as `0x${string}`,
    }

    const types = {
      RedeemOrder: [
        { name: 'message', type: 'string' },
        { name: 'user', type: 'address' },
        { name: 'collateralAddress', type: 'address' },
        { name: 'collateralAmount', type: 'uint256' },
        { name: 'usnAmount', type: 'uint256' },
        { name: 'expiry', type: 'uint256' },
        { name: 'nonce', type: 'uint256' },
      ],
    }

    const order: TMintOrder = {
      message: `You are signing a request to redeem ${props.usnAmount} ${props.selectedCollateral} for ${props.usnAmount} USN.`,
      user: account.address as `0x${string}`,
      collateralAddress: DAPP_CONFIG[mainnet.id].tokens[props.selectedCollateral].contractAddress as `0x${string}`,
      collateralAmount: parseUnits(
        props.usnAmount ?? '0',
        DAPP_CONFIG[mainnet.id].tokens[props.selectedCollateral].decimals,
      ),
      usnAmount: parseUnits(props.usnAmount ?? '0', 18),
      expiry: Math.floor(Date.now() / 1000) + 24 * 60 * 60, // Current date in sec + 24 hr in sec.
      nonce: Math.floor(Math.random() * 100000000000),
    }

    signTypedData(
      {
        domain,
        types,
        message: order,
        primaryType: 'RedeemOrder',
      },
      {
        onError: onApiOrSignatureError,
        onSuccess: (data) => {
          setBtnLabel('Confirming...')
          sendRedeemReq(order, data)
        },
      },
    )
  }, [props, account, sendRedeemReq])

  return (
    <React.Fragment>
      <p
        className={`font-Louize font-normal text-4xl tracking-tighter ${theme.isLightTheme ? 'text-stone-900' : 'text-stone-50'}`}
      >
        Confirm your USN redeeming <br /> transaction
      </p>

      <ActionInAndOutInfo
        tokenIn={'USN'}
        tokenOut={props.selectedCollateral}
        labelIn="Redeeming"
        labelOut="To get back"
        amountIn={formattedUsnAmount}
        amountOut={formattedUsnAmount}
      />
      <ActionStats page="USN" quoteToken={props.selectedCollateral} networkFeeAction={'mint'} />
      <ConnectButton
        label={btnLabel}
        useFullAvailableWidth
        onClick={signRedeemData}
        actionIcon="success-tick"
        useResponsiveSizes={false}
        actionType="primary-action"
        isLoading={txnStatus === EApiCallStatus.PROCESSING}
        isDisabled={isButtonDisabled || txnStatus === EApiCallStatus.PROCESSING}
      />
    </React.Fragment>
  )
}

export default RedeemConfirm
